import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, forkJoin, from, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.scss'],
  standalone: false
})


export class BookingHistoryComponent implements OnInit {
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  teachersList: Observable<any> = this.obsArray.asObservable();
  currentPage: number = 1;
  booking_id: any
  minDate: Date;
  bsRangeValue: Date[]
  bsValue = new Date();
  data: any;
  rotate = true;
  maxSize = 5;
  showBoundaryLinks = true;
  public bookingHistoryFilterForm: any;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  page: number = 1;
  apiService: any;
  totalCount: any;
  totalItems: any;
  filterCheck: any;
  filtersArray: any;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private mixpanelService: MixpanelService) {


    this.minDate = new Date();
    // this.maxDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 1);
    
    // this.maxDate.setDate(this.maxDate.getDate() + 7);

    this.bsRangeValue = [];
    // this.maxDate.setDate(this.maxDate.getFullYear());
    // this.bsValue = new Date(
    //   new Date().setFullYear(new Date().getFullYear())
    // ); 
  }
  formInit() {
    this.bookingHistoryFilterForm = this.fb.group({
      created_by: [''],
      page: [''],
      status: ['All'],
      booking_role_type:['All'],
      booking_type: ['booking_request'],
      from_date: [''],
      to_date: ['']
    });
  }



  ngOnInit(): void {
    this.ngxService.start();
    this.mixpanelService.requestHistory();
    this.formInit();
    this.getFiltersData();
    this.bookingService.getRequestHistory(this.currentPage).subscribe({
      next: (res: any) => {
        this.data = res.data.data
        this.totalCount = parseInt(res.data.page_count) * 10;
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    })
  }
  getFiltersData() {
    this.commonService.schoolChoices('booking_history').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;

      },
      error: (err: any) => {
        this.ngxService.stop()
        console.log(err);
      }
    });
  }
  pageChanged(event: PageChangedEvent): void {
    if (this.filterCheck != 1) {
      this.currentPage = event.page
      this.ngxService.start();
      this.bookingService.getRequestFilterHistory(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          this.data = res.data.data
          this.totalCount = parseInt(res.data.page_count) * 10;
          window.scroll(0, 0)
          this.ngxService.stop();
        },
        error: (err: any) => {
          this.ngxService.stop()
          console.log(err);
        }
      })
    }

  }

  cloneBooking(id: any) {
    this._router.navigate(['/school/booking-request/clone/' + id]);
  }
  reOpenBooking(id: any) {
    this._router.navigate(['/school/booking-request/reopen/' + id]);
  }

  sendBookingId(id: any) {
    this._router.navigate(['/school/bookings-in-flight/' + id]);
  }
  clearDate(){
   this.bsRangeValue = []
   this.getFilterInputs('filter', '')
  }
  getFilterInputs(flag: any, event: any) {
    if(event != ''){
      let rangeOne = event[0];
      let rangeTwo = event[1];
  
      let startDate = new DatePipe('en-US').transform(rangeOne, 'dd-MM-yyyy')
      let endDate = new DatePipe('en-US').transform(rangeTwo, 'dd-MM-yyyy')
      // alert(startDate)
      this.bookingHistoryFilterForm.patchValue({
        from_date: startDate,
        to_date: endDate,
      });
    }else{
      this.bookingHistoryFilterForm.patchValue({
        from_date: '',
        to_date: '',
      }); 
    }
   
    this.ngxService.start();
    this.filterCheck = 0
    if (flag = 'filter') {
      this.filterCheck = 1
      this.bookingService.getRequestFilterHistory(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          this.data = res.data.data
          this.totalCount = parseInt(res.data.page_count) * 10;
          window.scroll(0, 0)
          this.filterCheck = 0
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }

  }
  getFormControlValues() {
    if (this.filterCheck == 1) {
      this.bookingHistoryFilterForm.patchValue({
        page: this.currentPage = 1
      });
    } else {
      this.bookingHistoryFilterForm.patchValue({
        page: this.currentPage,
      });
    }

    return this.bookingHistoryFilterForm.value;
  }
}
