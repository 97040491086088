import { ChangeDetectorRef, Component, OnInit, ViewChildren, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { map, take } from "rxjs/operators";
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Libs
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

// import { PreBookingModalsComponent } from '../modals/pre-booking-modals/pre-booking-modals.component';
import timepicker from 'src/app/pages/producer/timepicker';
import { DatePipe } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.scss'],
  standalone: false
})
export class PayrollReportComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  sendDateForm: FormGroup = this.fb.group({
    from: 'angular',
    toDate: [''],
    fromDate: [''],
  });
  variable: any;
  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private toastr: ToastrService) {
      this.fromDate = new Date();
      this.fromDate.setDate(this.fromDate.getDate() - 7);
    this.toDate = new Date();

     }

  ngOnInit(): void {
  }

  getFormControlValue() {
  
   
    this.sendDateForm.patchValue({
      toDate: new DatePipe('en-US').transform(this.toDate, 'yyyy-MM-dd'),
      fromDate: new DatePipe('en-US').transform(this.fromDate, 'yyyy-MM-dd')
    })
    return this.sendDateForm.value
  }

  getInsightsData(from: any) {
    if(this.toDate == null || this.fromDate == null || this.toDate == undefined || this.fromDate == undefined){
      this.toastr.error("Value should not be empty")
      return
    }
    this.downloadLink().subscribe(
      (resp: any) => {
        console.log(resp)
        var blob = new Blob([resp.body], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        // var anchor.download = "myList.csv";
        const contentDisposition = resp.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            anchor.download = matches[1].replace(/['"]/g, '');
          }
        }
        anchor.href = url;
        anchor.click();
        this.ngxService.stop()
      },(error) => {
        console.log(error)
      }
    );
  }

  downloadLink(): Observable<HttpResponse<Blob>> {
    this.ngxService.start()
    return this.httpClient.get<Blob>(environment.apiUrl+'api/v1/school-user/export-payroll/', {
      params: this.getFormControlValue(),
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

}
