<div class="pb-30 pt-15"
    [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'post-job'">
    <div class="row">
        <div class="col-lg-12 col-12 col-md-12 back-jobs  mb-20 d-flex">
            <p class="text-regular-bold clr-cc-vilot c-p" routerLink="/school/jobs" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"><i class="bi bi-arrow-left-short"></i> Back to jobs</p>
        </div>
        <div class="col-lg-8 col-12 mb-20">
            <div class="row p_bar">
                <ul id="progress-bar" class="px-0 mt-10"
                    [ngClass]="showBasicInfo ? 'progressbar': '' || showJobSummary ? 'progressbar_one':'' || showReview ? 'progressbar_two':'' ">
                    <li class="" [ngClass]="showBasicInfo ? 'active_one' : 'active_com'">Basic information</li>
                    <li [ngClass]="showJobSummary ? 'active_two' : 'active_com'">Job summary</li>
                    <li [ngClass]="showReview ? 'active_three' : ''">Review</li>
                </ul>
            </div>
            <div *ngIf="showBasicInfo">
                <form [formGroup]="JobBasicForm">
                    <div class="row">
                        <div *ngIf="postCallOut" class="col-md-12 col-lg-12 col-12 mb-10">
                            <p class="txt-regular">Posting a call out will alert teachers near you that you are actively
                                looking for casual relief teachers to add to your list. You can quickly post casual
                                callouts using this prefilled job post template that we created just for you. Please
                                remember that you must ensure that the teachers you add to your ClassCover list are
                                approved to teach by your governing body / education department.</p>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12">
                            <label for="title">Job title <span class="clr-red">*</span></label>
                            <input id="title" class="form-control" [attr.disabled]="disable ? '' : null"
                                formControlName="job_title" name="title" placeholder="Type here">
                            <div *ngIf="submitted">
                                <span class="show-errors"
                                    *ngIf="f['job_title'].errors?.['required'] || f['job_title'].errors?.['whitespace']">*
                                    Job title
                                    is required</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12">
                            <label for="job_type">Job type <span class="clr-red">*</span></label>
                            <ng-select [searchable]="false" [readonly]="disable" [clearable]="false"
                                [dropdownPosition]="'bottom'" class="mb-0" formControlName="job_type"
                                aria-label="job_type">
                                <ng-option *ngFor="let type of filtersArray.job_type" value="{{type.value}}"
                                    data-title="{{type.title}}">{{type.title}}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submitted">
                                <span class="show-errors" *ngIf="f['job_type'].errors?.['required']">* Job type
                                    is required</span>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-6 col-12">
                            <label for="level">Role type <span class="clr-red">*</span></label>
                            <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                                class="mb-0" formControlName="job_role_type" aria-label="job_role_type"
                                placeholder="Select Role type">
                                <ng-option value="">Select Role type</ng-option>
                                <ng-option *ngFor="let level of filtersArray.role_type" [value]="level['id']">
                                    {{level.title}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted">
                                <span class="show-errors" *ngIf="f['job_role_type'].errors?.['required']">* Role type
                                    is required</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-12">
                            <label for="job_close_date">Job close date <span class="clr-red">*</span></label>
                            <input autocomplete="off" (ngModelChange)="checkVal($event)" type="text"
                                placeholder="Select date" id="job_close_date" class="form-control" bsDatepicker
                                [minDate]="minDate" [maxDate]="maxDate"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false}"
                                [(ngModel)]="bsValue" [ngModelOptions]="{standalone: true}">

                            <div *ngIf="submitted">
                                <span class="show-errors" *ngIf="f['job_close_date'].errors?.['required']">* Date
                                    is required</span>
                            </div>
                        </div>
                      
                        <div class="col-md-12 col-lg-12 col-12">
                            <label for="level">Level</label>
                            <ng-select class="mb-10" [readonly]="disable" [clearable]="false"
                                [items]="filtersArray.school_level" formControlName="school_level" bindLabel="title"
                                bindValue="value" [closeOnSelect]="true" [dropdownPosition]="'bottom'"
                                [multiple]="true" placeholder="Select level" aria-label="school_level">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <label class="mt-0">Select level</label>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items;  index as i">
                                        <span class="ng-value-label">{{items[i].title}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>

                        <div class="col-md-12 col-lg-12 col-12">
                            <label for="subject">Subject</label>
                            <ng-select class="mb-10" [readonly]="disable" [clearable]="false" [items]="subjectsAndTags"
                                formControlName="subject" bindLabel="title" bindValue="title" groupBy="tags"
                                [closeOnSelect]="true" [dropdownPosition]="'bottom'" [multiple]="true"
                                placeholder="Search by subjects and tags">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <label class="mt-0">System tags</label>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items;  index as i">
                                        <span class="ng-value-label">{{items[i].title}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                            </ng-select>

                        </div>


                        <div class="col-md-12 col-lg-12 col-12">
                            <div class="form-check float-right">
                                <input class="form-check-input c-p mt-4" [attr.disabled]="disable ? '' : null"
                                    type="checkbox" id="is-validated" formControlName="is_remote_job">
                                <label class="form-check-label c-p mt-4" for="is-validated">
                                    Can this job be done remotely?
                                </label>
                            </div>
                            <label for="title">Job location</label>
                            <input id="title" class="form-control" [attr.disabled]="true" formControlName="job_location"
                                name="job_location" placeholder="Job location ">
                        </div>
                        <div class="col-md-12 col-lg-12 col-12 mt-10">
                            <div class="d-flex">
                                <p *ngIf="!hideBtnOnReopen && !schoolProps.properties.booking_school"
                                    (click)="showLocationChange()" class="user-title fit-content clr-cc-vilot c-p">
                                    Change
                                    job location
                                </p>
                                <!-- <p *ngIf="!this.filtersArray.is_premium_subscription" (click)="showUpgradePopUp()" class="user-title fit-content clr-cc-vilot c-p">Change
                                    job location
                                </p> -->
                                <!-- <div *ngIf="this.schoolProps.properties.job_only_school == true "
                                    class="loc_p user-sub-title"><i class="bi bi-gem mr-3"></i> Premium</div> -->
                            </div>
                        </div>
                        <div *ngIf="showLocation">
                            <label for="title">Job location</label>
                            <input id="title" #addresstext (click)="getPlaceAutocomplete()" class="form-control"
                                formControlName="auto_job_location" name="job_location" placeholder="Job location ">
                            <!-- <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <label for="title">Suburb</label>
                                    <input (input)="checkLengthSubrub()" [readonly]="disable" [attr.disabled]="true"
                                        id="title" class="form-control" formControlName="school_suburb"
                                        name="school_suburb" placeholder="Type here">
                                    <div *ngIf="submitted">
                                        <span class="show-errors" *ngIf="f['school_suburb'].errors?.['required']">* This
                                            field
                                            is required</span>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-6 col-12">
                                    <label for="title">Postcode</label>
                                    <input (input)="checkLengthPostCode()" [readonly]="disable" [attr.disabled]="true"
                                        id="title" class="form-control" formControlName="school_postcode"
                                        name="school_postcode" placeholder="Type here">
                                    <div *ngIf="submitted">
                                        <span class="show-errors" *ngIf="f['school_postcode'].errors?.['required']">*
                                            This field
                                            is required</span>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-6 col-12">
                                    <label for="title">State</label>
                                    <input (input)="checkLengthState()" [readonly]="disable" [attr.disabled]="true"
                                        id="title" class="form-control" formControlName="school_state"
                                        name="school_state" placeholder="Type here">
                                    <div *ngIf="submitted">
                                        <span class="show-errors" *ngIf="f['school_state'].errors?.['required']">*
                                            This field
                                            is required</span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="showJobSummary">
                <form [formGroup]="jobSummaryForm">
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-12">
                            <label for="job_summary">Job summary <span class="clr-red">*</span></label>
                            <angular-editor formControlName="job_summary" [config]="editorConfig"></angular-editor>

                            <!-- <ckeditor [data]="content"  formControlName="job_summary" ></ckeditor> -->

                            <!-- app.component.html -->
                            <!-- <ckeditor [data]="content  " formControlName="job_summary" [config]="config"></ckeditor> -->
                            <!-- <ckeditor [editor]="Editor" formControlName="job_summary" data="<p>Hello</p>"></ckeditor> -->
                            <!-- <div class="editor">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                                </ngx-editor-menu>
                                <ngx-editor [editor]="editor" formControlName="job_summary">
                                </ngx-editor>
                            </div> -->
                        </div>
                        <div *ngIf="sSubmitted">
                            <span class="show-errors"
                                *ngIf="s['job_summary'].errors?.['required']|| s['job_summary'].errors?.['whitespace']">*
                                Job summary is
                                required</span>
                        </div>

                        <div *ngIf="!postCallOut" class="col-md-12 col-lg-12 col-12">
                            <label for="apply_through">Receive applicants by <span class="clr-red">*</span></label>
                            <ng-select (change)="checkLengthWebsite()" [readonly]="disable" [searchable]="false"
                                [clearable]="false" [dropdownPosition]="'bottom'" (change)="showExternalLink()"
                                formControlName="apply_through" aria-label="Receive">
                                <ng-option value="">Select Website</ng-option>
                                <ng-option value="CCW">ClassCover Jobs</ng-option>
                                <ng-option value="EW">External Website</ng-option>
                            </ng-select>
                            <div *ngIf="sSubmitted">
                                <span class="show-errors" *ngIf="s['apply_through'].errors?.['required']">* This field
                                    is required</span>
                            </div>
                        </div>
                        <div *ngIf="externalLink" class="col-md-12 col-lg-12 col-12">
                            <label for="apply_through_site">Enter external address <span
                                    class="clr-red">*</span></label>
                            <input [attr.disabled]="disable ? '' : null" id="apply_through_site" class="form-control"
                                formControlName="apply_through_site" placeholder="Type web address">

                            <div *ngIf="sSubmitted">
                                <span class="show-errors" *ngIf="s['apply_through_site'].errors?.['required']">* This
                                    field
                                    is required</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="showReview">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-12">
                        <p class="job_heading">{{jobTitleReview}}</p>
                    </div>
                    <div class="col-md-12 col-lg-12 col-12">
                        <p class="job_heading mt-15">Job summary</p>
                    </div>
                    <div class="col-md-12 col-lg-12 col-12">
                        <p class="txt-regular mt-10" [innerHTML]="jobSummaryReview"></p>
                    </div>
                </div>
            </div>
            <div class="" *ngIf="this.schoolProps.properties.job_only_school == true">
                <div class="" *ngIf="this.editJobDetails?.job.job_status.job_status != 'L'">
                    <div class="row mt-20">
                        <div class="col-lg-12">
                            <div class="card boost_jobs_cards px-0" *ngIf="filtersArray.free_boost">
                                <div class="d-flex align-items-center">
                                    <!-- <div>
                                        <img class="boost_img" src="assets/images/jobs/job_only_user/Boost_y.png">
                                    </div> -->
                                    <div class="pl-15 pr-15 px-15">
                                        <div>
                                            <p class="text-regular-medium int_off">🎉 Introductory offer </p>
                                            <h4 class="boost_heading mt-10">Enjoy 2 <span
                                                    class="clr-cc-vilot">FREE</span> job boosts every month</h4>
                                            <p class="txt-regular mt-5">Boosted job posts sit at the top of search
                                                results and are sent
                                                directly to the inbox of educators in your area.</p>
                                            <div class="form-check mt-5">
                                                <input class="form-check-input c-p mt-4" type="checkbox" id="boost_job"
                                                    [ngModel]="is_boost" (change)="setBoostValue($event)">
                                                <label class=" c-p mt-3" for="boost_job">
                                                    <span class="user-title clr-cc-vilot "> Boost job for $0</span><span
                                                        class="strike_rate clr-grey-3  fs-16">$20</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="free_boostNotify text-center">
                                        <p class="text-regular-bold fs-24">{{is_free_boostCount}}</p>
                                        <p class="txt-regular">Left</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card boost_jobs_card px-0" *ngIf="!filtersArray.free_boost">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="boost_img" src="assets/images/jobs/job_only_user/Boost_y.png">
                                    </div>
                                    <div class="px-15">
                                        <h4><b>Boost your job to reach more applicants</b></h4>
                                        <p class="txt-regular mt-5">Boosted job posts sit at the top of
                                            search results and are sent directly to the inbox of educators
                                            in your area.</p>
                                        <div class="form-check mt-5" *ngIf="!filtersArray.free_boost">
                                            <input class="form-check-input c-p mt-4" type="checkbox" id="boost_job"
                                                [ngModel]="is_boost" (change)="setBoostValue($event)">
                                            <label class="form-check-label c-p" for="boost_job">
                                                Boost job for $20
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-20">
                <div *ngIf="showBasicInfo">
                    <div class="col-md-12 col-lg-12 col-12">
                        <div class="save_btn_action align-items-center">
                            <div class=""
                                *ngIf="this.editJobDetails?.job.job_status.job_status != 'L' && this.editJobDetails?.job.job_status.job_status != 'C'  && this.editJobDetails?.job.job_status.job_status != 'E'">
                                <button *ngIf="this.edit_job_id == undefined" (click)="postJobs('draft')"
                                    class="btn_jobs_sec text-regular-bold c-p">Save
                                    as
                                    draft</button>
                                <button *ngIf="this.edit_job_id != undefined" (click)="UpdateJobs('draft')"
                                    class="btn_jobs_sec text-regular-bold c-p">Save
                                    as
                                    draft</button>
                            </div>
                            <div class="">
                                <button (click)="jobSummary()" class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button>
                                <!-- <button (click)="postJobs('post')" class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showJobSummary">
                    <div class="row align-items-center">
                        <div class="col-md-2 col-lg-2 col-12">
                            <p (click)="hideJobInfo()" class="text-regular-medium clr-cc-vilot c-p"
                                *ngIf="this.editJobDetails?.job.job_status.job_status != 'L'"><i
                                    class="bi bi-chevron-left"></i> Back</p>
                        </div>

                        <div class="col-md-10 col-lg-10 col-12">
                            <div class="save_btn_action align-items-center">
                                <div class=""
                                    *ngIf="this.editJobDetails?.job.job_status.job_status != 'L' && this.editJobDetails?.job.job_status.job_status != 'C'">
                                    <button *ngIf="this.edit_job_id == undefined" (click)="postJobs('draft')"
                                        class="btn_jobs_sec text-regular-bold c-p">Save
                                        as
                                        draft</button>
                                    <button *ngIf="this.edit_job_id != undefined" (click)="UpdateJobs('draft')"
                                        class="btn_jobs_sec text-regular-bold c-p">Save
                                        as
                                        draft</button>
                                </div>
                                <div class="">
                                    <button (click)="review()" class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button>
                                    <!-- <button (click)="postJobs('post')" class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showReview">
                    <div class="row align-items-center">

                        <div class="col-md-2 col-lg-2 col-12">
                            <p (click)="hideJobAndBasicInfo()" class="text-regular-medium clr-cc-vilot c-p"><i
                                    class="bi bi-chevron-left"></i> Back</p>
                        </div>

                        <div class="col-md-10 col-lg-10 col-12">
                            <div class="save_btn_action align-items-center">
                                <div class=""
                                    *ngIf="this.editJobDetails?.job.job_status.job_status != 'L' && this.editJobDetails?.job.job_status.job_status != 'C'">
                                    <button *ngIf="this.edit_job_id == undefined" (click)="postJobs('draft')"
                                        class="btn_jobs_sec text-regular-bold c-p">Save
                                        as
                                        draft</button>
                                    <button *ngIf="this.edit_job_id != undefined" (click)="UpdateJobs('draft')"
                                        class="btn_jobs_sec text-regular-bold c-p">Save
                                        as
                                        draft</button>
                                </div>
                                <div class=""
                                    *ngIf="this.schoolProps.properties.job_only_school == true && this.edit_job_id == undefined">
                                    <button (click)="postJobs('post')"
                                        class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button>
                                    <!-- <button (click)="postJobs('post')" class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button> -->
                                </div>
                                <div class=""
                                    *ngIf="this.schoolProps.properties.job_only_school == true && this.edit_job_id != undefined">
                                    <button (click)="UpdateJobs('post')"
                                        class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button>
                                    <!-- <button (click)="postJobs('post')" class="btn pl-30 pr-30 cc_p_btn fs_13">Continue</button> -->
                                </div>
                                <div class="">
                                    <button
                                        *ngIf="!this.schoolProps.properties.job_only_school == true && this.edit_job_id == undefined"
                                        (click)="postJobs('post')" class="btn pl-30 pr-30 cc_p_btn fs_13">Post</button>
                                    <button
                                        *ngIf="!this.schoolProps.properties.job_only_school == true && this.edit_job_id != undefined"
                                        (click)="UpdateJobs('post')"
                                        class="btn pl-30 pr-30 cc_p_btn fs_13">Post</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-12 mb-20">
            <div *ngIf="showBasicInfo || showJobSummary" class="">
                <div class="d-flex s_between  mt-10 align-items-center">
                    <p class="user-title">Other jobs posted</p>
                    <a (click)="redirectToPublicPage()" class="place-content-end txt-r-sb clr-cc-vilot c-p">View all
                        jobs <i class="bi bi-arrow-right"></i></a>
                </div>
                <div class="card mt-10 px-15 c-p openJobs" *ngFor="let jobs of others_job">
                    <div class="row align-items-center" (click)="openPublicJobs(jobs.job_id)">
                        <div class="col-md-12 col-lg-12 d-flex">
                            <div class="pic-and-check ml-0">
                                <img [src]="(jobs.profile_image == null || jobs.profile_image == '') ? '../../../../assets/images/pro_avatar.png' : jobs.profile_image"
                                    class="profile-picture img-complete-match">
                            </div>
                            <div class="pic-and-check ml-10">
                                <div class="d-flex flex-wrap">
                                    <div class="user-title c-p h_b">{{jobs.job_title}}</div>
                                    <!-- <div class="type-badge user-sub-title">{{jobs.job_type}}</div> -->
                                    <div *ngIf="jobs.is_boost_job" class="job-boosted">Promoted</div>
                                </div>
                                <p class="user-sub-title mt-2">
                                    {{jobs.school_name}}
                                    <span class="divider-right ml-3 mr-5"></span>
                                    {{jobs.school_level}}
                                </p>
                                <p class="user-sub-title mt-2">
                                    {{jobs.school_state}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" *ngIf="showReview">
                <div class="row" *ngIf="this.editJobDetails?.job.job_status.job_status != 'L'">
                    <div class="col-lg-12">
                        <p (click)="hideJobInfo()" class="user-title clr-cc-vilot c-p float-right mt-10"><i
                                class="bi bi-pencil-fill"></i> Edit job post</p>
                    </div>
                </div>
                <div class="card px-35 mt-20">
                    <div class="row">
                        <div class="col-lg-12">
                            <p class="text-center">
                                <img alt="school avatar" width="42px" class="school_avatar"
                                    [src]="this.schoolProps.mixpanel_data.school_avatar == '' || this.schoolProps.mixpanel_data.school_avatar == null ? '../../../../assets/images/teacher-static-profile.png' :  this.schoolProps.mixpanel_data.school_avatar">
                            </p>

                            <p class="user-title text-center mt-10">{{this.schoolProps.mixpanel_data.school_name}}
                            </p>

                            <div class="d-flex flex-wrap justify-content-center mt-10">
                                <div *ngIf="this.jobRemote" class="">
                                    <span class="remote_t user-sub-title">Remote</span>
                                </div>
                                <div *ngIf="this.jobBoost" class="">
                                    <span class="promote_t user-sub-title">Promoted</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12  mb-10 mt-20">
                        <p class="small">JOB TYPE</p>
                        <p class="text-regular-bold mt-3">{{jobTypeReview}}</p>
                    </div>
                    <div class="col-lg-12  mb-10">
                        <p class="small">levels</p>
                        <p class="text-regular-bold mt-3">{{jobLevelReview}}</p>
                    </div>
                    <div class="col-lg-12  mb-10" *ngIf="jobSubjectReview != ''">
                        <p class="small">SUBJECTS</p>
                        <p class="text-regular-bold mt-3">{{jobSubjectReview}}</p>
                    </div>
                    <div class="col-lg-12  mb-10">
                        <p class="small">LOCATION</p>
                        <p class="text-regular-bold mt-3">{{jobLocationReview}}</p>
                    </div>
                    <div class="col-lg-12  mb-10">
                        <p class="small">jOB CLOSE DATE</p>

                        <p class="text-regular-bold mt-3">{{jobCloseDate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>