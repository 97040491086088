import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-process-modal',
  templateUrl: './payment-process-modal.component.html',
  styleUrls: ['./payment-process-modal.component.scss'],
  standalone: false
})
export class PaymentProcessModalComponent implements OnInit {
  display: any;

  constructor() {
    this.timer(1);
   }

  ngOnInit(): void {
  }

  timer(minute:any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 16;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 15;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }
}
