import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef, Input, ViewChild, TemplateRef } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { last, map, take } from "rxjs/operators";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SideNavComponent } from '../../../layout/side-nav/side-nav.component';
import { SharedService } from '../../../services/api/message.service';
import { environment } from 'src/environments/environment';
// import { ConfirmationModalComponent } from '../../../layout/confirmation-modal/confirmation-modal.component';
import {PaymentProcessModalComponent} from '../modals/payment-process-modal/payment-process-modal.component'
import { MixpanelService } from 'src/app/mixpanel.service';
// declare var myExternalJs: any;

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  standalone: false
})
export class JobsComponent implements OnInit {
  @Input() public SideNavComponent!: SideNavComponent;
  obsActiveArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  obsClosedArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  obsDraftArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  activeJobsList: Observable<any> = this.obsActiveArray.asObservable();
  closeJobsList: Observable<any> = this.obsClosedArray.asObservable();
  draftJobsList: Observable<any> = this.obsDraftArray.asObservable();
  @ViewChild('removeJobModal') removeJobModal: any;
  // @ViewChild('removeJobModal')
  // removeJobModal!: TemplateRef<any>;

  currentPage: number = 1;
  public schoolProps!: SchoolData;
  data: any;
  rotate = true;
  maxSize = 5;
  showBoundaryLinks = true;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  page: number = 1;
  apiService: any;
  totalCount: any;
  totalItems: any;
  filterCheck: any;
  filtersArray: any;
  activeJob: any;
  draftJob: any;
  closeJob: any;
  draft_jobs: any;
  close_job: any;
  statics: any;
  profilePercent: any;
  other_job: any;
  bsActiveValue: any;
  bsClosedValue: any;
  bsDraftValue: any;
  payment_status: any;
  job_id: any;
  id: any;

  activeLengthLimit: any
  draftLengthLimit: any
  closedLengthLimit: any

  activeTotalArrayCount: any;
  closedTotalArrayCount: any;
  draftTotalArrayCount: any;


  makeLiveForm: FormGroup = this.fb.group({
    job_id: ['']
  });
  jobUpdateForm: FormGroup = this.fb.group({
    plan_id: [''],
    sub_id: [''],
    hosted_id: [''],
    plan_name:['']
  });

  sub_id: any;
  plan_name: any;
  plan_id: any;
  job_ad_credit: any;
  is_premium_plan: boolean = false;
  is_subscription_on: any;

  jobActiveFilterForm: FormGroup = this.fb.group({
    filter_job_title: [''],
    tab: ['active'],
    filter_date: [null],
    page: ['']
  });
  jobClosedFilterForm: FormGroup = this.fb.group({
    filter_job_title: [''],
    tab: ['closed'],
    filter_date: [null],
    page: ['']
  });
  jobDraftFilterForm: FormGroup = this.fb.group({
    filter_job_title: [''],
    tab: ['draft'],
    filter_date: [null],
    page: ['']
  });
  butCredit: any;
  showSideComponent: boolean = false;
  plan_title: any;
  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private sharedService: SharedService,
    public bsModalRef: BsModalRef,
    private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    // console.log(process.env)

    // console.log("api",process.env['apiUrl'])
    // console.log("env",process.env['env'])
    // console.log("version",process.env['version'])
    this.ngxService.start();
    // this.bsModalRef = this.modalService.show(PaymentProcessModalComponent,{  class: 'modal-sm',backdrop: 'static' });

    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    // myExternalJs.SetIdCustomerIo(this.schoolProps.mixpanel_data);
    
    // this.bsValue = moment(new Date()).format('DD/MM/YYYY');
    // this.formInit();
    // this.getFiltersData();
    this.bsActiveValue = null
    this.route.queryParams.subscribe(params => {
      this.payment_status = params['state'];
    });
    this.route.queryParams.subscribe(params => {
      this.sub_id = params['sub_id'];
    });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
    this.route.queryParams.subscribe(params => {
      this.plan_title = params['plan_title'];
    });

    this.route.queryParams.subscribe(params => {
      this.plan_id = params['plan_id'];
    });
    if (this.job_id == null) {
      this.makeLiveForm.patchValue({
        job_id: '',
      });
    } else {
      this.makeLiveForm.patchValue({
        job_id: this.job_id,
      });
    }

    this.jobUpdateForm.patchValue({
      plan_id: this.plan_id,
      sub_id: this.sub_id,
      hosted_id: this.id,
      plan_name: this.plan_title,
    });
    
    if ((this.payment_status == 'succeeded' || this.payment_status == 'requested') && this.plan_id != undefined) {
      this._router.navigate(['/school/jobs']);
      // this.toastr.success("Payment Processing please wait", '', { timeOut: 30000 });
      this.mixpanelService.subscriptionPurchase(this.plan_title);
      setTimeout(() => this.returnPaymentAPI(this.jobUpdateForm.controls['hosted_id'].value), 1000)
      setTimeout(() => this.ngxService.stop(), 1000)

      
      // this.triggerOnLoad()
      // setTimeout(() => this.updateJobApi(), 15000);
      // setTimeout(() => this.sideComponent(), 15000);
      // localStorage.setItem('current_plan', data);

    } else if (this.payment_status == 'succeeded' || this.payment_status == 'requested') {
      this._router.navigate(['/school/jobs']);
      this.mixpanelService.jobCreditMixpanel('Individual job credit purchase');
      // this.toastr.success("Payment Processing please wait", '', { timeOut: 30000 });
      setTimeout(() => this.returnPaymentAPI(this.jobUpdateForm.controls['hosted_id'].value), 1000)
      setTimeout(() => this.ngxService.stop(), 1000)
      // this.toastr.success("Payment Successful", '', { timeOut: 15000 });
      // setTimeout(() => this.updateJobApi(), 15000);
      // setTimeout(() => this.sideComponent(), 15000);
    } else {
      this.updateJobApi()
      this.sideComponent()
    }
  }
  openSm(removeJobModals:any){
    this.modalService.show(removeJobModals , { class: 'modal-sm' });
  }
  // triggerOnLoad() {
  //   this.bsModalRef = this.modalService.show(this.removeJobModal, { class: 'modal-sm', backdrop: 'static' });
  // }
  returnPaymentAPI(id:any) {
    // this.bsModalRef.hide()
    // this.ngxService.start()
    this.bsModalRef = this.modalService.show(PaymentProcessModalComponent,{  class: 'modal-sm',backdrop: 'static' });
    this.bookingService.callBackPaymentFunction(id).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide()
        if(res.status){
          this.toastr.success(res.message, '', { timeOut: 10000 })
          if (this.jobUpdateForm.controls['plan_name'].value != undefined) {
            this.sharedService.current_plan.next(this.jobUpdateForm.controls['plan_name'].value)
          }
          console.log(res)
         let currentUrl = this._router.url;
         this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
           this._router.navigate([currentUrl]);
         });
        }else{
          this.bsModalRef.hide()
          this.toastr.error(res.message, '', { timeOut: 10000 })
          console.log(res)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        }
      },
      error: (err: any) => {
        this.toastr.error(err.message, '', { timeOut: 10000 })
        console.log(err)
        this.ngxService.stop();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      }
    })
  }
  sideComponent() {
    this.bookingService.getSideComponentJobListing().subscribe({
      next: (res: any) => {
        this.statics = res.data.stat
        this.job_ad_credit = res.data.job_ad_credit
        this.is_premium_plan = res.data.is_premium_plan
        this.is_subscription_on = res.data.is_subscription_on
        this.profilePercent = res.data.profile_percentage
        this.other_job = res.data.other_job
        this.butCredit = res.data.buy_more_ad
        this.showSideComponent = true
      }
    })
  }
  updateJobApi() {
    this.bookingService.getJobs(this.getFilterActiveFormValue()).subscribe({
      next: (res: any) => {
        if(res.status == true){
          this.activeJob = res.data.active_job
          // this.closeJob = res.data.expired_close_job
          this.activeLengthLimit = res.data.total_count;
          this.obsActiveArray.next(res.data.active_job);
          this.ngActiveListener()
          this.ngxService.stop();
          // myExternalJs.JobInfoCustomerIo(this.activeLengthLimit);
        }else{
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getjobUpdateFormValue() {
    return this.jobUpdateForm.value
  }
  getMakeLiveFormValue() {
    return this.makeLiveForm.value
  }
  getFilterActiveFormValue() {
    let date = ''
    if (this.bsActiveValue == null) {
      date = ''
    } else {
      date = moment(this.bsActiveValue).format('DD/MM/YYYY')
    }
    this.jobActiveFilterForm.patchValue({
      filter_date: date,
      page: this.currentPage
    })
    return this.jobActiveFilterForm.value
  }
  getFilterClosedFormValue() {
    let date = ''
    if (this.bsClosedValue == null) {
      date = ''
    } else {
      date = moment(this.bsClosedValue).format('DD/MM/YYYY')
    }
    this.jobClosedFilterForm.patchValue({
      filter_date: date,
      page: this.currentPage
    })
    return this.jobClosedFilterForm.value
  }
  getFilterDraftFormValue() {
    let date = ''
    if (this.bsDraftValue == null) {
      date = ''
    } else {
      date = moment(this.bsDraftValue).format('DD/MM/YYYY')
    }
    this.jobDraftFilterForm.patchValue({
      filter_date: date,
      page: this.currentPage
    })
    return this.jobDraftFilterForm.value
  }
  getFilterInputsActive() {
    this.ngxService.start();
    this.currentPage = 1
    this.bookingService.getFilterJobs(this.getFilterActiveFormValue()).subscribe({
      next: (res: any) => {
        this.activeJob = res.data.active_job
        this.obsActiveArray.next(res.data.active_job);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getFilterInputsClosed() {
    this.ngxService.start();
    this.currentPage = 1
    this.bookingService.getFilterJobs(this.getFilterClosedFormValue()).subscribe({
      next: (res: any) => {
        this.closeJob = res.data.expired_close_job
        this.obsClosedArray.next(res.data.expired_close_job);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getFilterInputsDraft() {
    this.ngxService.start();
    this.currentPage = 1
    this.bookingService.getFilterJobs(this.getFilterDraftFormValue()).subscribe({
      next: (res: any) => {
        if(res.status){
          this.draftJob = res.data.draft_job
          this.obsDraftArray.next(res.data.draft_job);
          this.ngxService.stop();
        }else{
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  pageChanged(event: PageChangedEvent): void {
    if (this.filterCheck != 1) {
      this.currentPage = event.page
      this.ngxService.start();
      this.bookingService.getJobs(this.currentPage).subscribe({
        next: (res: any) => {
          this.activeJob = res.data.active_job
          this.totalCount = parseInt(res.data.page_count) * 10;
          window.scroll(0, 0)
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
  }

  activeTab = 'activeJobs';

  activeJobs(activeTab: string) {
    this.ngxService.start();
    this.jobActiveFilterForm.patchValue({
      filter_date: '',
      filter_job_title: '',
    })
    this.activeTab = activeTab;
    this.ngActiveListener()
    this.ngDraftOnDestroy();
    this.ngClosedOnDestroy()
    this.currentPage = 1
    this.updateJobApi()
  }

  ClosedJobs(activeTab: string) {
    // this.bsModalRef = this.modalService.show(this.removeJobModal, { class: 'modal-sm', backdrop: 'static' });
    this.ngxService.start();
    this.jobClosedFilterForm.patchValue({
      filter_date: '',
      filter_job_title: '',
    })
    this.activeTab = activeTab;
    this.currentPage = 1
    this.ngClosedListener()
    this.ngActiveDestroy();
    this.ngDraftOnDestroy();
    this.bookingService.getJobs(this.getFilterClosedFormValue()).subscribe({
      next: (res: any) => {
        this.closeJob = res.data.expired_close_job
        this.closedLengthLimit = res.data.total_count;
        this.obsClosedArray.next(res.data.expired_close_job);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  DraftJobs(activeTab: string) {
    this.ngxService.start();
    this.jobDraftFilterForm.patchValue({
      filter_date: '',
      filter_job_title: '',
    })
    this.activeTab = activeTab;
    this.currentPage = 1
    this.ngDraftListener()
    this.ngActiveDestroy();
    this.ngClosedOnDestroy();
    this.bookingService.getJobs(this.getFilterDraftFormValue()).subscribe({
      next: (res: any) => {
        this.draftJob = res.data.draft_job
        this.draftLengthLimit = res.data.total_count;
        this.obsDraftArray.next(res.data.draft_job);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  sendJobId(id: any) {
    this.sharedService.navData.next(true)
    this._router.navigate(['/school/job-details'], { queryParams: { job_id: id } });
  }
  editJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id, type: 'edit' } });

    // this._router.navigate(['/school/post-job/' + job_id], { queryParams: { type: 'edit' } });
  }
  editDraftJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id, type: 'draftEdit' } });
  }

  reOpenJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id, type: 're_open' } });

    // this._router.navigate(['/school/post-job/' + job_id], { queryParams: { type: 're_open' } });
  }

  redirectToPostCallOut() {
    this._router.navigate(['/school/post-job/'], { queryParams: { type: 'postCallOut' } });
  }
  redirectToPublicPage() {
    window.open(environment.apiUrl + "jobs", "_blank");
  }
  openPublicJobs(id: any) {
    window.open(environment.apiUrl + "jobs/view-job/" + id, "_blank");
  }

  ngDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event: any): void => {
    this.getActiveLength();
    let totalPages = Math.ceil(this.activeLengthLimit / this.activeTotalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.activePaginator(this.activeTotalArrayCount, this.currentPage, 20);
      this.currentPage = activePage + 1;

      if (this.jobActiveFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.activeJobsList.pipe(take(1)), this.bookingService.getJobs(this.getFilterActiveFormValue())]).subscribe((data: any) => {
          this.activeLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.active_job];
          this.obsActiveArray.next(newArr);
          this.activeJob = newArr
          this.ngxService.stop();
        });
      }
    }
  };

  ngActiveListener() {
    window.addEventListener('scroll', this.scrollActive, true); //third parameter
  }
  ngClosedListener() {
    window.addEventListener('scroll', this.scrollClosed, true); //third parameter
  }
  ngDraftListener() {
    window.addEventListener('scroll', this.scrollDraft, true); //third parameter
  }


  ngActiveDestroy() {
    window.removeEventListener('scroll', this.scrollActive, true);
  }
  ngClosedOnDestroy() {
    window.removeEventListener('scroll', this.scrollClosed, true);
  }
  ngDraftOnDestroy() {
    window.removeEventListener('scroll', this.scrollDraft, true);
  }


  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollActive, true);
    window.removeEventListener('scroll', this.scrollClosed, true);
    window.removeEventListener('scroll', this.scrollDraft, true);
  }

  getActiveLength() {
    this.activeJobsList.subscribe(result => {
      this.activeTotalArrayCount = result.length;
    });
  }
  getDraftLength() {
    this.draftJobsList.subscribe(result => {
      this.draftTotalArrayCount = result.length;
    });
  }
  getClosedLength() {
    this.closeJobsList.subscribe(result => {
      this.closedTotalArrayCount = result.length;
    });
  }

  // Scroll to getData
  scrollActive = (event: any): void => {
    this.getActiveLength();
    let totalPages = Math.ceil(this.activeLengthLimit / this.activeTotalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.activePaginator(this.activeTotalArrayCount, this.currentPage, 20);
      this.currentPage = activePage + 1;

      if (this.jobActiveFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.activeJobsList.pipe(take(1)), this.bookingService.getJobs(this.getFilterActiveFormValue())]).subscribe((data: any) => {
          this.activeLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.active_job];
          this.obsActiveArray.next(newArr);
          this.activeJob = newArr
          this.ngxService.stop();
        });
      }
    }
  };

  scrollClosed = (event: any): void => {
    this.getClosedLength();
    let totalPages = Math.ceil(this.closedLengthLimit / this.closedTotalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.closedPaginator(this.closedTotalArrayCount, this.currentPage, 20);
      this.currentPage = activePage + 1;
      if (this.jobClosedFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.closeJobsList.pipe(take(1)), this.bookingService.getJobs(this.getFilterClosedFormValue())]).subscribe((data: any) => {
          this.closedLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.expired_close_job];
          this.obsClosedArray.next(newArr);
          this.closeJob = newArr
          this.ngxService.stop();
        });
      }
    }
  };


  scrollDraft = (event: any): void => {
    this.getDraftLength();
    let totalPages = Math.ceil(this.draftLengthLimit / this.draftTotalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.draftPaginator(this.draftTotalArrayCount, this.currentPage, 20);
      this.currentPage = activePage + 1;
      if (this.jobDraftFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.draftJobsList.pipe(take(1)), this.bookingService.getJobs(this.getFilterDraftFormValue())]).subscribe((data: any) => {
          this.draftLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.draft_job];
          this.obsDraftArray.next(newArr);
          this.draftJob = newArr
          this.ngxService.stop();
        });
      }
    }
  };
  activePaginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  draftPaginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  closedPaginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  subscriptionForm: FormGroup = this.fb.group({
    plan: [''],
  });
  getFormControlValue() {
    return this.subscriptionForm.value
  }
  callChargebee(butCredit: any) {
    this.ngxService.start();
    this.subscriptionForm.patchValue({
      plan: butCredit
    })
    this.bookingService.chargebee(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          window.location.href = res.data
          // window.open(res.data);
          // this._router.navigate([res.data]);
        } else {
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    })
  }
}
