import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SharedService } from '../../../../services/api/message.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { toUnicode } from 'punycode';
@Component({
  selector: 'app-job-terms-condition',
  templateUrl: './job-terms-condition.component.html',
  styleUrls: ['./job-terms-condition.component.scss'],
  standalone: false
})
export class JobTermsConditionComponent implements OnInit {
  public schoolProps!: SchoolData;
  showAcceptBtn: boolean = false;
  conditionCheck: any
  privacyCheck: any
  constructor(private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private bookingService: BookingService,
    private authService: AuthenticationService) { }

  termsAndConditionForm: FormGroup = this.fb.group({
    accept: ''
  });
  ngOnInit(): void {
    this.sharedService.navData.next(true)
    this.sharedService.header.next(true)
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    // if (this.schoolProps.properties.terms_accepted == true) {
    //   this._router.navigateByUrl('school/jobs')
    //   this.sharedService.header.next(false)
    //   this.sharedService.navData.next(false)
    // }
    this.ngxService.stop();
  }

  getFormControlValue(accept: any): any {
    this.termsAndConditionForm.patchValue({
      accept: accept
    })
    return this.termsAndConditionForm.value
  }
  logout() {
    this.authService.loggedOut();
    if (this._router) {
      this._router.ngOnDestroy();
    }
  }
  redirectToJobsPage(accept: any) {
    if (accept == 'rejected') {
      this.ngxService.start()
      this.toastr.error("Please accept the terms and conditions and privacy policy to continue")
      setTimeout(() => this.logout(), 3000);
    }
    if (accept == 'accepted') {
      this.bookingService.jobTermsConditionAPI(this.getFormControlValue(accept)).subscribe({
        next: (res: any) => {
          if (res.status) {
            this._router.navigateByUrl('school/post-job')
            this.sharedService.navData.next(true)
            this.sharedService.header.next(false)
          } else {
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          console.log(err);
        }
      })
    }
  }

  checkCondition(event: any) {
    if (event.target.checked == true) {
      this.conditionCheck = true
    }
    if (event.target.checked == false) {
      this.conditionCheck = false
    }
    if (this.privacyCheck == true && this.conditionCheck == true) {
      this.showAcceptBtn = true
    } else {
      this.showAcceptBtn = false
    }
  }
  checkPrivacy(event: any) {
    if (event.target.checked == true) {
      this.privacyCheck = true
    }
    if (event.target.checked == false) {
      this.privacyCheck = false
    }

    if (this.privacyCheck == true && this.conditionCheck == true) {
      this.showAcceptBtn = true
    } else {
      this.showAcceptBtn = false
    }
  }
}

