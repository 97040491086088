import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { TeacherProfilePopupComponent } from '../../teacher-profile-popup/teacher-profile-popup.component';
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../../services/api/message.service';
import { PaymentProcessModalComponent } from '../../modals/payment-process-modal/payment-process-modal.component';

@Component({
  selector: 'app-job-subscription',
  templateUrl: './job-subscription.component.html',
  styleUrls: ['./job-subscription.component.scss'],
  standalone: false
})
export class JobSubscriptionComponent implements OnInit {
  activeBillType: boolean = false;
  public schoolProps!: SchoolData;

  activeMonthly: boolean = true
  activeAnnual: boolean = true
  selectedSingle: boolean = false
  selectedMonthlyStarter: boolean = false
  selectedMonthlyPremium: boolean = false

  selectedAnnualStarter: boolean = false;
  selectedAnnualPremium: boolean = false;
  monthlyStarter = '200'
  monthlyPremium = '200'
  is_boost: boolean = false
  job_id: any;
  id: any;
  state: any;
  filtersArray: any;
  monthlyStarterPlan: boolean = true;
  checkBoost: any;
  billing_type: boolean = false;
  payment_status: any;
  sub_id: any;
  plan_id: any;
  monthlyBilling: boolean = true;
  annualBilling: boolean = false;
  hideSinglePost: boolean = false;
  closeExpired: boolean = false;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private el: ElementRef,
    private sharedService: SharedService) { }


  subscriptionForm: FormGroup = this.fb.group({
    plan: [''],
    boost_job: [false],
    job_id: ['']
  });
  jobUpdateForm: FormGroup = this.fb.group({
    plan_id: [''],
    sub_id: [''],
    hosted_id: ['']
  });

  ngOnInit(): void {
    this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.route.queryParams.subscribe(params => {
      this.job_id = params['job_id'];
    });
    this.route.queryParams.subscribe(params => {
      this.checkBoost = params['boost_job'];
    });

    this.route.queryParams.subscribe(params => {
      this.payment_status = params['state'];
    });
    this.route.queryParams.subscribe(params => {
      this.sub_id = params['sub_id'];
    });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });

    this.route.queryParams.subscribe(params => {
      this.plan_id = params['plan_id'];
    });
    this.jobUpdateForm.patchValue({
      plan_id: this.plan_id,
      sub_id: this.sub_id,
      hosted_id: this.id
    });

    if (this.payment_status == 'succeeded' || this.payment_status == 'requested') {

      setTimeout(() => this.returnPaymentAPI(this.id), 1000)
      setTimeout(() => this.ngxService.stop(), 1000)
    } else {
      this.activeBillType = false
      this.getFiltersData()
      this.checkBoost
      if (this.checkBoost == 'false') {
        this.is_boost = false
      } else if (this.checkBoost == undefined) {
        this.is_boost = false
      } else {
        this.is_boost = true
      }
      this.ngxService.stop();
    }
  }
  ReloadURL(){
    let currentUrl = '/school/job-subscription';
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
    // this.getFiltersData()
  }

  returnPaymentAPI(id:any) {
    // this.bsModalRef.hide()
    // this.ngxService.start()
    this.bsModalRef = this.modalService.show(PaymentProcessModalComponent, { class: 'modal-sm',backdrop: 'static' });
    this.bookingService.callBackPaymentFunction(id).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide()
        if(res.status){
          this.toastr.success(res.message, '', { timeOut: 10000 })
          console.log(res)
          this._router.navigate(['/school/job-subscription'])
          this.ReloadURL()
        }else{
          this.toastr.error(res.message, '', { timeOut: 10000 })
          console.log(res)
          this._router.navigate(['/school/job-subscription'])
          this.ReloadURL()
        }
      },
      error: (err: any) => {
        this.bsModalRef.hide()
        this.toastr.error(err.message, '', { timeOut: 10000 })
        console.log(err);
        this._router.navigate(['/school/job-subscription'])
        this.ReloadURL()
      }
    })
  }
  changeBillType(event: any) {
    if (event.target.checked == true) {
      this.annualBilling = true
      this.monthlyBilling = false
    } else {
      this.annualBilling = false
      this.monthlyBilling = true
    }
  }
  getjobUpdateFormValue() {
    return this.jobUpdateForm.value
  }


  selectplan(planType: any) {
    this.activeMonthly = false
    if (planType == 'singlePost') {
      this.selectedSingle = !this.selectedSingle
      this.selectedMonthlyStarter = false
      this.selectedMonthlyPremium = false
      this.selectedAnnualPremium = false
      this.selectedAnnualStarter = false
    } else if (planType == 'monthlyStarter') {
      this.selectedMonthlyStarter = !this.selectedMonthlyStarter
      this.selectedMonthlyPremium = false
      this.selectedAnnualPremium = false
      this.selectedAnnualStarter = false
      this.selectedSingle = false
    } else if (planType == 'monthlyPremium') {
      this.selectedMonthlyPremium = !this.selectedMonthlyPremium
      this.selectedMonthlyStarter = false
      this.selectedAnnualPremium = false
      this.selectedAnnualStarter = false
      this.selectedSingle = false
    } else if (planType == 'AnnualStarter') {
      this.selectedAnnualStarter = !this.selectedAnnualStarter
      this.selectedAnnualPremium = false
      this.selectedMonthlyPremium = false
      this.selectedMonthlyStarter = false
      this.selectedSingle = false
    } else if (planType == 'AnnualPremium') {
      this.selectedAnnualPremium = !this.selectedAnnualPremium
      this.selectedAnnualStarter = false
      this.selectedMonthlyPremium = false
      this.selectedMonthlyStarter = false
      this.selectedSingle = false
    }
  }
  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.commonService.schoolChoices('job_payment').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        localStorage.setItem('current_plan', res.data.active_plan)
        let chnagePlan = ''
        if(localStorage.getItem('current_plan') == '' || localStorage.getItem('current_plan') == null){
          chnagePlan =   '';
        }else{
          chnagePlan =   localStorage.getItem('current_plan') + ' plan';
        }
        this.sharedService.send_data.next(chnagePlan)
          // localStorage.setItem("current_plan", res.data.current_plan);
        if (this.filtersArray?.current_plan == 'annual_premium' || this.filtersArray?.current_plan == 'annual_starter') {
          this.annualBilling = true
          this.monthlyBilling = false
          this.billing_type = true
        } else {
          this.annualBilling = false
          this.monthlyBilling = true
          this.billing_type = false
        }
        if (this.filtersArray?.current_plan == 'annual_premium' || this.filtersArray?.current_plan == 'annual_starter' || this.filtersArray?.current_plan == 'monthly_starter' || this.filtersArray?.current_plan == 'monthly_premium') {
          this.activeMonthly = false
        }
        // alert(this.filtersArray?.current_plan)
        if (this.filtersArray?.current_plan == 'monthly_starter' || this.filtersArray?.current_plan == 'annual_starter' || this.filtersArray?.current_plan == '') {
          this.hideSinglePost = false
        } else {
          this.hideSinglePost = true
        }
        // this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  setBoostValue(event: any) {
    this.is_boost = event.target.checked
    if (event.target.checked) {
      this.is_boost = true
    } else {
      this.is_boost = false
    }
  }
  getFormControlValue() {
    return this.subscriptionForm.value
  }
  callChargebee() {
    this.ngxService.start();
    this.subscriptionForm.patchValue({
      boost_job: this.is_boost
    })

    if (this.monthlyBilling == true) {
      if (this.selectedMonthlyStarter == true) {
        this.subscriptionForm.patchValue({
          plan: this.filtersArray?.price_plan?.Monthly_Starter?.plan_name,
          job_id: this.job_id
        })
      } else if (this.selectedMonthlyPremium == true) {
        this.subscriptionForm.patchValue({
          plan: this.filtersArray?.price_plan?.Monthly_Premium?.plan_name,
          job_id: this.job_id
        })
      } else if (this.selectedSingle == true) {
        this.subscriptionForm.patchValue({
          plan: this.filtersArray?.price_plan?.Single_Post?.plan_name,
          job_id: this.job_id
        })
      }
    } else if (this.annualBilling == true) {
      if (this.selectedAnnualStarter == true) {
        this.subscriptionForm.patchValue({
          plan: this.filtersArray?.price_plan?.Annual_Starter?.plan_name,
          job_id: this.job_id
        })
      } else if (this.selectedAnnualPremium == true) {
        this.subscriptionForm.patchValue({
          plan: this.filtersArray?.price_plan?.Annual_Premium?.plan_name,
          job_id: this.job_id
        })
      } else if (this.selectedSingle == true) {
        this.subscriptionForm.patchValue({
          plan: this.filtersArray?.price_plan?.Single_Post?.plan_name,
          job_id: this.job_id
        })
      }
    }


    this.bookingService.chargebee(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          if (this.job_id != undefined) {
            localStorage.setItem('job_id', this.job_id);
          }
          window.location.href = res.data
          // window.open(res.data);
          // this._router.navigate([res.data]);
        } else {
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
  closeExpiredModal(){
    this.closeExpired = true
  }
  ngDestroy() {
    window.removeEventListener('scroll', this.scroll);
  }
  scroll() {
  }

}
