import { Component, Renderer2, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { last, map, take } from "rxjs/operators";
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { HttpClient } from '@angular/common/http';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CardProfileViewComponent } from '../card-profile-view/card-profile-view.component';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { AddNewModalComponent } from '../modals/add-new-modal/add-new-modal.component';

import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';

import { CreateSmartGroupComponent } from '../modals/create-smart-group/create-smart-group.component';
import { MixpanelService } from 'src/app/mixpanel.service';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { an } from '@fullcalendar/core/internal-common';
// declare var myExternalJs: any;

import { AddNotesComponent } from '../modals/add-notes/add-notes.component';

@Component({
  selector: 'app-my-list',
  templateUrl: './my-list.component.html',
  styleUrls: ['./my-list.component.scss'],
  standalone: false
})
export class MyListComponent implements OnInit {
  @ViewChild('createSmartGroupModal') createSmartGroupModal: any;
  @ViewChild('actionModals') actionModals: any;
  @ViewChild('suspendActionModals') suspendActionModals: any;
  @ViewChild('sendEmailModal') sendEmailModal: any;
  @ViewChild('nudgeModal') nudgeModal: any;
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent!: ElementRef<any>;

  myRequest: any;
  myInvites: any;
  teacherName: any;
  actionType: any;
  consumerDetails: Array<any> = [];
  consumerDetailsSmartGroup: Array<any> = [];
  selectedSmartGroups: Array<any> = [];
  selectedSmartGroupsFinal: Array<any> = []
  sendSlectedValue: Array<any> = [];

  ratings: any;
  provider_id: any;
  availTeacher: any;

  itemsPerSlide = 6;
  singleSlideOffset = true;
  noWrap = false;
  slidesChangeMessage: any


  // Scroll variables
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  myList: Observable<any> = this.obsArray.asObservable();

  teacherLengthLimit: any;
  totalArrayCount: any
  schoolStaffs: any;
  activeColor: boolean = false;
  showTab: boolean = false;
  Tabtitle: any;
  selectedGroupID: any;
  teacher_id: any;
  action: any;
  smartGroupForModal: any;
  teacher_name: any;
  selectedTeacherId: any;
  existingGroup: any;
  smart_groupsDuplicate: any = []
  exportTeachers: any;
  teacherId: any;
  selectedType!: string;
  smartTeacherId: any;
  nudgeUserName: any;
  nudgeTeacherId: any;
  is_checked: any;
  custom_text: any;
  ShowLenght: any;
  ShowLenghtError: any = 280;
  addAllGroups: boolean = false;
  broadcast: boolean = false;
  msgType: any;
  teacherMailId: any;
  teacherData: any;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private httpClient: HttpClient,
    private mixpanelService: MixpanelService,
    private renderer: Renderer2) { }
  public schoolProps!: SchoolData;
  currentPage: number = 1
  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  schoolTimings = [];
  consumerType = [];
  smart_groups: Array<any> = [];
  teacher: Array<any> = [];
  checkedProp: any
  listHealth: any;
  hideButton: boolean = true;
  hideButtonSmartGroup: boolean = true;
  pendingRequestData: any = [];
  myListData: any = [];
  myListDataCopy: any = []
  smartGroupData: any = [];
  selectAllCheck: boolean = false;
  selectAllCheckSmartGroup: boolean = false
  submitted: boolean = false;
  public shareStaffProfile: any;
  public sendEmailForm: any
  tagsAndSubjects: boolean = true;
  @ViewChild('smartGroupModal') smartGroupModal: any;
  @ViewChild('RemoveStaffsModal') RemoveStaffsModal: any;
  @ViewChild('customCheckModal') customCheckModal: any;

  declineInviteForm: FormGroup = this.fb.group({
  });
  submitRatingsForm: FormGroup = this.fb.group({
    rating: [''],
    provider_id: ['']
  });

  sendSelectedSmartGroup: FormGroup = this.fb.group({
    action: [''],
    teacher_id: [''],
    group_id: ['']
  });

  exportValueForm: FormGroup = this.fb.group({
    from: [''],
  });
  smartFormValue: FormGroup = this.fb.group({
    from: [''],
  });
  moveToForm: FormGroup = this.fb.group({
    group_id: [''],
    action: [''],
    teacher_ids: ['']
  });
  checkBoxForm: FormGroup = this.fb.group({
    teacher_id: [''],
    is_certified: [''],
    action: ['']
  });
  myListFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_experience_items: [''],
    filter_tags: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    distance: ['10000000'],
    filter_active_inactive: ['All'],
    page: [''],
    sort_by: ['']
  });

  smartGroups: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
  });

  sendNudgeform: FormGroup = this.fb.group({
    teacher_id: [''],
    type: [''],
    nudgeFrom: ['']
  });

  actionForm: FormGroup = this.fb.group({
    action: [''],
    teacher_id: [''],
    rating: [''],
    smart_group: ['']
  });


  ngOnInit(): void {
    this.ngxService.start();
    this.mixpanelService.myTeachers();
    this.getFiltersData();
    this.formInit();
    this.checkLength()
    // Booking request
    this.bookingService.getMyList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.myListData = res.data.my_list;
        this.myListDataCopy = res.data.my_list
        this.myRequest = res.data.pending_request;
        this.myInvites = res.data.pending_invite;
        this.obsArray.next(res.data.my_list);
        this.ngxService.stop();
        console.log(res)
      },
      error: (err: any) => {
        console.log(err);
      }
    })
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }
  loadMyListDataOnLoad() {
    this.bookingService.getMyList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.myListData = res.data.my_list;
        this.myListDataCopy = res.data.my_list;
        this.myRequest = res.data.pending_request;
        this.myInvites = res.data.pending_invite;
        this.obsArray.next(res.data.my_list);
        this.ngxService.stop();
        console.log(res)
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    })
  }
  loadSmartGroupDataOnLoad() {
    this.bookingService.getmyListSmartGroupsList(this.selectedGroupID, this.getSmartFormValue()).subscribe({
      next: (res: any) => {
        this.smartGroupData = [...res.preferred_list, ...res.back_uplist];
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  getTeacherLength() {
    this.myList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }
  // Scroll to getData
  scroll = (event: any): void => {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 15);
      this.currentPage = activePage + 1;
      if (this.myListFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.myList.pipe(take(1)), this.bookingService.getMyList(this.getInputFormControlValues())]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.my_list];
          this.obsArray.next(newArr);
          this.myListData = newArr
          this.myListDataCopy = newArr
          this.ngxService.stop();
        });
      }
    }
  };



  Paginator(totlallength: any, page: any, per_page: any) {

    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    // return {
    // page: page,
    // per_page: per_page,
    // pre_page: page - 1 ? page - 1 : null,
    // next_page: (total_pages > page) ? page + 1 : null,
    // total: items.length,
    // total_pages: total_pages,
    // data: paginatedItems
    // };
    return total_pages;
  }

  getUpdatedListData() {
    this.ngxService.start();
    this.bookingService.getMyList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.obsArray.next(res.data.my_list);
        this.myListData = res.data.my_list;
        this.myListDataCopy = res.data.my_list
        this.myRequest = res.data.pending_request;
        this.myInvites = res.data.pending_invite;
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  formInit() {
    this.shareStaffProfile = this.fb.group({
      to_mail: ['', [Validators.required]],
      action: [''],
      teacher_id: ['']
    });
    this.sendEmailForm = this.fb.group({
      email_content: ['', [Validators.required, Validators.maxLength(280)]],
      support_url: [''],
      provider_ids: ['']
    });

  }
  // Get filters data
  getFilterInputs() {
    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API
    this.bookingService.getMyFilterList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        if (this.activeTab == 'schoolList') {
          this.myListData = res.data.my_list
          this.myListDataCopy = res.data.my_list
          this.teacherLengthLimit = res.data.total_count;
          this.obsArray.next(res.data.my_list);
          this.ngxService.stop();
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
    // End of get filters data API
  }
  // End of get filters data

  getInputFormControlValues() {
    if (this.activeTab == 'schoolList') { //Filter for invites
      this.myListFilterForm.patchValue({
        filterType: 'my_list',
        page: this.currentPage
      });
      return this.myListFilterForm.value;
    }
    // else if(this.activeTab == 'history'){
    //   this.invitesHistoryFilterForm.patchValue({ 
    //     filterType: 'history',
    //   });
    //   return this.invitesHistoryFilterForm.value;     
    // }
  }

  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('my_list').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.subjectsAndTags = res.data.tags;
        this.levelFilter = res.data.experience_tree;
        this.consumerType = res.data.consumer_type;
        this.smart_groups = res.data.smart_group;
        this.smart_groupsDuplicate = res.data.smart_group;
        this.schoolStaffs = res.data.school_staff
        this.custom_text = res.data.custom_field
        // this.ngxService.stop();
        // myExternalJs.MylistCustomerIo(this.smart_groups.length);

      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  openAddnewModal() {
    this.bsModalRef = this.modalService.show(AddNewModalComponent, { class: 'modal-lg' });
  }
  getTeacherAvailabilityModal(teacher_id: any) {
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
        }

      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
      }
    });
  }


  selectAll(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.myListData.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.consumerDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.consumerDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.consumerDetails = this.consumerDetails.filter(x => x != type);
      }
    }
    this.selectAllCheck = this.myListData.length == this.consumerDetails.length ? true : false;
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }
  selectAllSmartGroup(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetailsSmartGroup = [];
      this.smartGroupData.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.consumerDetailsSmartGroup.push(x);
          this.hideButtonSmartGroup = false
        } else {
          this.hideButtonSmartGroup = true
          x.isChecked = false;
          this.consumerDetailsSmartGroup = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetailsSmartGroup.push(type)
        this.hideButtonSmartGroup = false
      } else {
        type.isChecked = false;
        this.consumerDetailsSmartGroup = this.consumerDetailsSmartGroup.filter(x => x != type);
      }
    }
    this.selectAllCheckSmartGroup = this.smartGroupData.length == this.consumerDetailsSmartGroup.length ? true : false;
    if (this.consumerDetailsSmartGroup.length > 0) {
      this.hideButtonSmartGroup = false
    } else {
      this.hideButtonSmartGroup = true
    }
  }
  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  activeTab = 'schoolList';
  schoolList(activeTab: string) {
    this.ngxService.start();
    this.activeTab = activeTab;
    this.currentPage = 1
    this.selectAllCheck = false
    this.hideButton = true
    this.hideButtonSmartGroup = true
    this.selectAllCheckSmartGroup = false
    this.consumerDetailsSmartGroup = []
    this.consumerDetails = []
    this.bookingService.getMyList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.myListData = res.data.my_list;
        this.myListDataCopy = res.data.my_list
        this.obsArray.next(res.data.my_list);
        this.ngxService.stop();
        console.log(res)
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  myPrefferedList(activeTab: string) {
    this.ngxService.start();
    this.selectAllCheck = false
    this.hideButton = true
    this.hideButtonSmartGroup = true
    this.selectAllCheckSmartGroup = false
    this.consumerDetailsSmartGroup = []
    this.consumerDetails = []
    this.activeTab = activeTab;
    this.bookingService.getmyListSmartGroupsList(this.selectedGroupID, this.getSmartFormValue()).subscribe({
      next: (res: any) => {
        this.smartGroupData = [...res.preferred_list, ...res.back_uplist];
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
    window.removeEventListener('scroll', this.scroll, true);
  }
  emergencyList(activeTab: string) {
    this.activeTab = activeTab;
  }


  get f(): { [key: string]: AbstractControl } {
    return this.shareStaffProfile.controls;
  }
  getFormControlValues() {
    if (this.actionType == 'suspend') {
      this.actionForm.patchValue({
        action: 'suspend'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'reactivate') {
      this.actionForm.patchValue({
        action: 'reactivate'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'remove') {
      this.actionForm.patchValue({
        action: 'remove'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'share') {
      this.shareStaffProfile.patchValue({
        action: 'share'
      });
      return this.shareStaffProfile.value;
    } else if (this.actionType == 'email') {
      this.actionForm.patchValue({
        action: 'email'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'update_rating') {
      this.actionForm.patchValue({
        action: 'update_rating'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'customCheck') {
      this.checkBoxForm.patchValue({
        action: 'customCheck'
      });
      return this.checkBoxForm.value;
    } else {
      return this.declineInviteForm.value;
    }
  }
  actionModal(teacher: any, action: any) {
    this.teacher = teacher
    this.teacherName = teacher.teacher.user.full_name
    this.actionType = action
    this.bsModalRef = this.modalService.show(this.actionModals, { class: 'modal-md', backdrop: 'static' });
  }
  OpenSuspendactionModal(type: any, action: any) {
    this.bsModalRef = this.modalService.show(this.suspendActionModals, { class: 'modal-md', backdrop: 'static' });
  }
  suspendActions() {
    this.ngxService.start();
    this.mixpanelService.removerTeacher()
    this.actionType = 'suspend'
    this.actionForm.patchValue({
      teacher_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(",")),
      rating: ''
    });
    this.bookingService.myListAction(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status == true) {
          this.bsModalRef.hide();
          this.smartGroups.reset();
          this.toastr.success(res.message);
          this.submitted = false;
          this.selectAllCheck = false
          this.selectAllCheckSmartGroup = false
          this.hideButton = true;
          this.hideButtonSmartGroup = true
          this.consumerDetails = [];
          this.consumerDetailsSmartGroup = []
          this.loadMyListDataOnLoad()
          // this.ngxService.stop();
          // this.getUpdatedListData();
        } else if (res.status == false) {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText);
        this.ngxService.stop();
        console.log(err);
      }
    })
  }
  actions(teacher: any, action: any, ratings: any) {
    this.actionType = action;
    if (action == 'remove') {
      this.mixpanelService.removerTeacher()
    }
    if (action == 'email' && teacher == '') {
      this.ngxService.start();
      if (this.activeTab == 'myPrefferedList') {
        this.actionForm.patchValue({
          teacher_id: (Array.prototype.map.call(this.consumerDetailsSmartGroup, function (item) { return item.id; }).join(",")),
          rating: ''
        });
      } else {
        this.actionForm.patchValue({
          teacher_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(",")),
          rating: ''
        });
      }
    } else if (action == 'share') {
      this.submitted = true;
      if (this.shareStaffProfile.invalid) {
        return;
      }
      this.ngxService.start();
      this.shareStaffProfile.patchValue({
        teacher_id: (teacher.teacher.id).toString(),
      });
      // this.shareStaffProfile.addControl('teacher_id', new FormControl((teacher.teacher.id).toString()));

    } else if (action == 'customCheck') {
      this.ngxService.start();
      this.checkBoxForm.patchValue({
        teacher_id: (teacher.teacher.id).toString(),
        is_certified: this.is_checked
      });
    }

    else if (action == 'update_rating') {
      this.ngxService.start();
      this.actionForm.patchValue({
        teacher_id: (teacher.teacher.id).toString(),
        rating: ratings
      });
    } else {
      this.ngxService.start();
      this.actionForm.patchValue({
        teacher_id: (teacher.teacher.id).toString(),
        rating: ''
      });
      // this.actionForm.addControl('teacher_id', new FormControl((teacher.teacher.id).toString()));
    }
    if (this.activeTab == 'schoolList') {
      this.actionForm.patchValue({
        smart_group: ''
      })
    } else {
      this.actionForm.patchValue({
        smart_group: this.selectedGroupID
      })
    }
    this.bookingService.myListAction(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status == true) {
          this.bsModalRef.hide();
          this.smartGroups.reset();
          this.toastr.success(res.message);
          if (this.activeTab == 'schoolList') {
            if (action == 'remove') {
              var index = this.myListData.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data.my_list[0].teacher.id);
              this.myListData.splice(index, 1);
            } else {
              var index = this.myListData.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data.my_list[0].teacher.id);
              this.myListData[index] = res.data.my_list[0];
            }
          } else {
            if (action == 'remove' && action != 'share') {
              var index = this.smartGroupData.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data.my_list[0].teacher.id);
              this.smartGroupData.splice(index, 1);
            } else if (action == 'update_rating') {
              this.loadSmartGroupDataOnLoad()
            } else if (action != 'share' && action != 'update_rating' && action != 'suspend') {
              var index = this.smartGroupData.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data.my_list[0].teacher.id);
              this.smartGroupData[index] = res.data.my_list[0];
            } else if (action == 'suspend') {
              var index = this.smartGroupData.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data.my_list[0].teacher.id);
              this.smartGroupData.splice(index, 1);
            }
          }
          this.selectAllCheck = false
          this.selectAllCheckSmartGroup = false
          this.hideButton = true;
          this.hideButtonSmartGroup = true
          this.consumerDetails = [];
          this.consumerDetailsSmartGroup = []
          this.submitted = false;
          this.ngxService.stop();
          // this.getUpdatedListData();
        } else if (res.status == false) {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText);
        this.ngxService.stop();
        console.log(err);
      }
    })
  }

  resetFilters() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }
  sendMailModal(teacher: any , type: any) {
    this.teacherId = teacher
    this.msgType = type
    this.bsModalRef = this.modalService.show(this.sendEmailModal, { class: 'modal-md', backdrop: 'static' });
  }
  get m(): { [key: string]: AbstractControl } {
    return this.sendEmailForm.controls;
  }
  checkBroad(event: any) {
    var checked = event.target.checked;
    if (checked == true) {
      this.sendEmailForm.addControl('broadcast_all', new FormControl(true));
    }else{
      this.sendEmailForm.removeControl('broadcast_all');
    }
  }
  getControlMailValues() {
    return this.sendEmailForm.value;
  }
  sendEmail(teacherId: any) {
    this.submitted = true;
    if (this.sendEmailForm.invalid) {
      return;
    }

    this.ngxService.start();
    if (teacherId == 'email') {
      if (this.activeTab == 'myPrefferedList') {
        this.sendEmailForm.patchValue({
          provider_ids: (Array.prototype.map.call(this.consumerDetailsSmartGroup, function (item) { return item.teacher.id; }).join(","))
        });
        this.teacherMailId = (Array.prototype.map.call(this.consumerDetailsSmartGroup, function (item) { return item.teacher.id; }).join(","))
      } else {
        this.sendEmailForm.patchValue({
          provider_ids: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
        });
        this.teacherMailId = (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
      }
    } else {
      this.sendEmailForm.patchValue({
        provider_ids: teacherId.toString()
      });
      this.teacherMailId = teacherId.toString()
    }
    this.bookingService.sendMailInvite(this.getControlMailValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          // myExternalJs.LastBroadcastMessage(this.teacherMailId);
          this.teacherMailId = [];
          this.submitted = false;
          this.bsModalRef.hide();
          this.consumerDetails.forEach((x: any) => {
            x.isChecked = false;
          });
          this.consumerDetailsSmartGroup.forEach((x: any) => {
            x.isChecked = false;
          });
          this.sendEmailForm.removeControl('broadcast_all');
          this.selectAllCheck = false
          this.selectAllCheckSmartGroup = false
          this.hideButton = true;
          this.hideButtonSmartGroup = true
          this.consumerDetails = [];
          this.consumerDetailsSmartGroup = []
          // this.getUpdatedListData();
          this.sendEmailForm.reset();
          this.sendEmailForm.patchValue({
            support_url: ''
          });
          this.toastr.success(res.message);
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
        console.log(err);
      }
    })
  }

  get s(): { [key: string]: AbstractControl } {
    return this.smartGroups.controls;
  }
  getControlSmartValues() {
    return this.smartGroups.value;
  }
  submitSmartGroup() {
    this.submitted = true;
    if (this.smartGroups.invalid) {
      return;
    }
    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API
    this.bookingService.CreateSmartGroup(this.getControlSmartValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message);
          this.bsModalRef.hide();
          this.commonService.schoolChoices('my_list').subscribe({
            next: (res: any) => {
              this.smart_groups = res.data.smart_group
              this.smartGroups.reset();
              this.submitted = false;
              this.ngxService.stop();
            },
            error: (err: any) => {
              console.log(err);
              this.toastr.error(res.message);
              this.ngxService.stop();
            }
          });
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    });
    // End of get filters data API
  }
  getSumbitFormValue() {
    this.submitRatingsForm.patchValue({
      rating: this.ratings,
      provider_id: this.provider_id
    });
    return this.submitRatingsForm.value;
  }
  submitRatings(ratings: any, id: any) {
    this.ngxService.start();
    // Get filters data API'
    this.ratings = ratings
    this.provider_id = id
    this.bookingService.ProviderRatings(this.getSumbitFormValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message);
          this.bsModalRef.hide();
          console.log(res)
          // var index = this.inviteList.map(x => {
          //   return x.id;
          // }).indexOf(arrayList.id);
          // if (this.addMoreBooking == false) {
          //   if (this.inviteList.length == 1) {
          //     this.status = false;
          //   }
          // }
          // this.inviteList.splice(index, 1);
          // this.inviteList = [...this.inviteList]
          // return;
          this.getUpdatedListData();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  closeSmartModal() {
    this.bsModalRef.hide();
    this.smartGroups.reset();
  }

  closeSendMailModal() {
    this.bsModalRef.hide();
    this.submitted = false;
    this.sendEmailForm.reset();
  }
  showSmartCreateModal() {
    const initialState = {
      pageFrom: 'my_list_page',
      schoolStaffs: this.schoolStaffs
    };
    // this.bsModalRef = this.modalService.show(this.createSmartGroupModal, { class: 'modal-md', backdrop: 'static' });

    this.bsModalRef = this.modalService.show(CreateSmartGroupComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  getSmartFormValue() {
    this.smartFormValue.patchValue({
      from: 'my_list'
    });
    return this.smartFormValue.value;
  }
  openTab(group_id: any, from: any) {
    if (this.selectedGroupID == group_id && from == '') {
      this.activeTab = 'schoolList';
      this.showTab = false
      this.smart_groups.forEach((data: any) => {
        if (data.value == group_id) {
          data.activeColor = false;
        }
        this.selectedGroupID = '';
      })
      window.addEventListener('scroll', this.scroll, true); //third parameter
    } else {
      this.ngxService.start();
      this.selectAllCheck = false
      this.hideButton = true
      this.activeTab = 'myPrefferedList';
      window.removeEventListener('scroll', this.scroll, true);
      this.smart_groups.forEach((data: any) => {
        if (data.value == group_id) {
          this.Tabtitle = data.title
          this.showTab = true
          this.selectedGroupID = group_id
          this.bookingService.getmyListSmartGroupsList(group_id, this.getSmartFormValue()).subscribe({
            next: (res: any) => {
              this.smartGroupData = [...res.preferred_list, ...res.back_uplist];
              this.ngxService.stop();
            },
            error: (err: any) => {
              console.log(err);
              this.ngxService.stop();
            }
          })
          data.activeColor = true;
        } else {
          data.activeColor = false;
        }
      })
    }
  }


  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 200), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 200), behavior: 'smooth' });
  }

  hideLevelSub() {
    this.tagsAndSubjects = !this.tagsAndSubjects
  }

  OpenRemoveStaffsModal(teacher_id: any, type: any) {
    if (type.is_preferred_list == true) {
      this.action = 'remove_preferred_list'
    } else if (type.is_backup_list == true) {
      this.action = 'remove_back_uplist'
    }
    this.teacher_id = teacher_id
    this.bsModalRef = this.modalService.show(this.RemoveStaffsModal, { class: 'modal-md', backdrop: 'static' });
  }
  openSmartGroupModal(smart: any, teacher_name: any, teacher_id: any, existingGroup: any) {
    if (smart == 'all') {
      this.selectedType = 'all'
      this.teacher_name = ''
      this.bsModalRef = this.modalService.show(this.smartGroupModal, { class: 'modal-lg', backdrop: 'static' });
    } else {
      this.existingGroup = existingGroup
      // alert(this.selectedGroupID)
      this.selectedType = 'single'
      // this.smartGroupForModal = smart
      this.teacher_name = teacher_name
      this.selectedTeacherId = teacher_id
      this.smart_groups.forEach((y: any) => {
        existingGroup.forEach((x: any) => {
          if (y.value == x.id) {
            y.existing = true
          }
        });
      });
      if (this.selectedGroupID != undefined || this.selectedGroupID != '') {
        this.smart_groups.forEach((y: any) => {
          if (!y.existing && y.value == this.selectedGroupID) {
            y.selected = true
          }
        });
      }
      this.bsModalRef = this.modalService.show(this.smartGroupModal, { class: 'modal-lg', backdrop: 'static' });
    }
  }
  getFormControlGroupValue() {
    return this.moveToForm.value
  }
  RemoveFromList(group_id: any, teacher_id: any, action: any) {
    this.moveToForm.patchValue({
      action: action,
      group_id: group_id,
      teacher_ids: teacher_id.toString()
    })
    this.bookingService.smartGroupsActions(this.getFormControlGroupValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.openTab(group_id, 'actionResult')
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    })
  }

  selectSmartGroup(value: any, existing: any) {
    if (existing == true) {
      return;
    }
    this.selectedSmartGroups = [];
    this.smart_groups.forEach((x: any) => {
      if ((x.value == value)) {
        if (x.selected == true) {
          x.selected = false
          this.selectedSmartGroups.push(x);
        } else {
          x.selected = true
          this.selectedSmartGroups.push(x);
        }
      }
    });
  }
  selectAllGroups(allGroups: any) {
    this.selectedSmartGroups = [];
    // if(this.addAllGroups == false) {
    this.smart_groups.forEach((x: any) => {
      allGroups.forEach((y: any) => {
        if ((x.value == y.value)) {
          if (x.existing != true) {
            x.selected = true
            this.selectedSmartGroups.push(x);
          }
        }
      });
    });
    //   this.addAllGroups = true
    // }else{
    //   this.smart_groups.forEach((x: any) => {
    //     allGroups.forEach((y: any) => {
    //       if ((x.value == y.value)) {
    //         if (x.existing != true) {
    //           x.selected = false
    //           this.selectedSmartGroups.push(x);
    //         }
    //       }
    //     });
    //   });
    //   this.addAllGroups = false
    // }
  }
  getSelectedSmartValue() {
    return this.sendSelectedSmartGroup.value
  }
  sendSmartList(smart_selected: any, type: any) {
    this.sendSlectedValue = []
    smart_selected.forEach((x: any) => {
      if (x.selected == true) {
        this.sendSlectedValue.push(x);
      }
    });
    if (this.sendSlectedValue.length == 0 && (this.sendSelectedSmartGroup.controls['action'].value == '' || this.sendSelectedSmartGroup.controls['action'].value == null)) {
      this.toastr.error('Please select smart groups and add to list to proceed');
      return;
    }
    if (this.sendSlectedValue.length == 0) {
      this.toastr.error('Select some smart group to proceed');
      return;
    }
    if (this.sendSelectedSmartGroup.controls['action'].value == '' || this.sendSelectedSmartGroup.controls['action'].value == null) {
      this.toastr.error('Please select prefered or backup list to proceed');
      return;
    }
    this.ngxService.start();
    if (type == 'all' && this.activeTab == 'myPrefferedList') {
      this.sendSelectedSmartGroup.patchValue({
        group_id: (Array.prototype.map.call(this.sendSlectedValue, function (item) { return item.value; }).join(",")),
        teacher_id: (Array.prototype.map.call(this.consumerDetailsSmartGroup, function (item) { return item.teacher.id; }).join(","))
      })
    } else if (type == 'all' && this.activeTab == 'schoolList') {
      this.sendSelectedSmartGroup.patchValue({
        group_id: (Array.prototype.map.call(this.sendSlectedValue, function (item) { return item.value; }).join(",")),
        teacher_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
      })
    } else {
      this.sendSelectedSmartGroup.patchValue({
        group_id: (Array.prototype.map.call(this.sendSlectedValue, function (item) { return item.value; }).join(",")),
        teacher_id: this.selectedTeacherId.toString()
      })
    }

    this.bookingService.addToSmartGroup(this.getSelectedSmartValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.modalService.hide();
          this.toastr.success(res.message);
          if (type == 'all' && this.activeTab == 'schoolList') {
            this.loadMyListDataOnLoad()
          }
          if (type == 'all' && this.activeTab == 'myPrefferedList') {
            this.loadSmartGroupDataOnLoad()
          }
          this.tagsAndSubjects = false
          this.selectedSmartGroups = [];
          this.sendSlectedValue = [];
          this.consumerDetails = []
          this.consumerDetailsSmartGroup = []
          this.selectAllCheck = false
          this.selectAllCheckSmartGroup = false
          this.hideButton = true;
          this.hideButtonSmartGroup = true
          this.smart_groups.forEach((x: any) => {
            delete x['existing'];
            delete x['selected'];
          });
          this.sendSelectedSmartGroup.reset()
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    })
  }
  closeAddToModal() {
    this.bsModalRef.hide();
    this.selectedSmartGroups = [];
    this.smart_groups.forEach((x: any) => {
      x.selected = false
      delete x['existing'];
      delete x['selected'];
    });
    this.sendSelectedSmartGroup.reset()
    this.sendSlectedValue = []
  }
  openNudgeModal(type: any, teacherId: any, userName: any) {
    if (type == 'singleNudge') {
      this.sendNudgeform.patchValue({
        nudgeFrom: type
      })
      this.nudgeTeacherId = teacherId;
      this.nudgeUserName = userName
      this.sendNudgeform.patchValue({
        nudgeFrom: type
      })
      this.bsModalRef = this.modalService.show(this.nudgeModal, { class: 'modal-md', backdrop: 'static' });
    } else if (type == 'multipleSmartList') {
      this.sendNudgeform.patchValue({
        nudgeFrom: type
      })
      this.bsModalRef = this.modalService.show(this.nudgeModal, { class: 'modal-md', backdrop: 'static' });
    } else if (type == 'multipleList') {
      this.sendNudgeform.patchValue({
        nudgeFrom: type
      })
      this.bsModalRef = this.modalService.show(this.nudgeModal, { class: 'modal-md', backdrop: 'static' });
    } else if (type == 'singleSmartNudge') {
      this.sendNudgeform.patchValue({
        nudgeFrom: type
      })
      this.smartTeacherId = teacherId;
      this.nudgeUserName = userName
      this.bsModalRef = this.modalService.show(this.nudgeModal, { class: 'modal-md', backdrop: 'static' });
    }
  }
  getnudgeFormValue() {
    return this.sendNudgeform.value
  }
  sendNudge(checkId: any) {
    this.ngxService.start();
    if (checkId != '' && checkId != undefined) {
      this.sendNudgeform.patchValue({
        teacher_id: checkId.toString(),
        type: 'single'
      })
      this.bookingService.sendNudgeApi(this.getnudgeFormValue()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bsModalRef.hide();
            if (this.sendNudgeform.controls['nudgeFrom'].value == 'singleSmartNudge') {
              this.loadSmartGroupDataOnLoad()
            }
            if (this.sendNudgeform.controls['nudgeFrom'].value == 'singleNudge') {
              var index = this.myListData.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data.teacher.id);
              this.myListData[index] = res.data;
            }
            this.toastr.success(res.message);
            this.ngxService.stop();
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error(err.statusText + ', Please contact admin');
          this.ngxService.stop();
        }
      })
    } else {
      if (this.sendNudgeform.controls['nudgeFrom'].value == 'multipleSmartList') {
        this.sendNudgeform.patchValue({
          teacher_id: (Array.prototype.map.call(this.consumerDetailsSmartGroup, function (item) { return item.teacher.id; }).join(",")),
          type: 'multiple'
        })
      } if (this.sendNudgeform.controls['nudgeFrom'].value == 'multipleList') {
        this.sendNudgeform.patchValue({
          teacher_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(",")),
          type: 'multiple'
        })
      }
      this.bookingService.sendNudgeApi(this.getnudgeFormValue()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bsModalRef.hide();
            this.toastr.success(res.message);
            if (this.sendNudgeform.controls['nudgeFrom'].value == 'multipleSmartList') {
              this.loadSmartGroupDataOnLoad()
            } if (this.sendNudgeform.controls['nudgeFrom'].value == 'multipleList') {
              this.loadMyListDataOnLoad()
            }
            this.tagsAndSubjects = false
            this.selectedSmartGroups = [];
            this.sendSlectedValue = [];
            this.consumerDetails = []
            this.consumerDetailsSmartGroup = []
            this.selectAllCheck = false
            this.selectAllCheckSmartGroup = false
            this.hideButton = true;
            this.hideButtonSmartGroup = true
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error(err.statusText + ', Please contact admin');
          this.ngxService.stop();
        }
      })
    }
  }
  getExportFormValue() {
    return this.exportValueForm.value
  }

  exportTeachersApi(from: any) {
    this.exportValueForm.patchValue({
      from: from
    })

    this.downloadLink().subscribe(
      (resp: any) => {
        var blob = new Blob([resp.body], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        // var anchor.download = "myList.csv";
        const contentDisposition = resp.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            anchor.download = matches[1].replace(/['"]/g, '');
          }
        }
        anchor.href = url;
        anchor.click();
        this.ngxService.stop()
      });
  }

  downloadLink(): Observable<HttpResponse<Blob>> {
    this.ngxService.start()
    return this.httpClient.get<Blob>(environment.apiUrl + 'api/v1/school-user/export/', {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  async sortBy(type: any) {
    this.myListFilterForm.patchValue({
      sort_by: type
    })
    this.getUpdatedListData()
    // if (type == 'name') {
    //   this.myListData = this.myListData.sort((a: any, b: any) => a.teacher.user.full_name > b.teacher.user.full_name ? 1 : -1);
    // } else if (type == 'lastSeen') {
    //   this.myListData = this.myListData.sort((a: any, b: any) => {
    //     return <any>new Date(b.teacher.user.last_login_min) - <any>new Date(a.teacher.user.last_login_min);
    //   });
    // } else {
    //   this.myListData = this.myListData.sort((i: any, j: any) => {
    //     if (j.teacher.distance_from == "") {
    //       return -1;
    //     }
    //     if (i.teacher.distance_from == "") {
    //       return 1;
    //     }
    //     if (Number(j.teacher.distance_from.split(" ")[0]) > Number(i.teacher.distance_from.split(" ")[0])) {
    //       return -1;
    //     } else {
    //       return 1;
    //     }
    //   });
    // }
  }
  redirectToPostCallOut() {
    if (window.location.hostname == "booking-test.classcover.com.au") {
      window.open("https://jobs-test.classcover.com.au/school/post-job?type=postCallOut")
    } else {
      window.open("https://jobs.classcover.com.au/school/post-job?type=postCallOut")
    }
    // this._router.navigate(['/school/post-job/'], { queryParams: { type: 'postCallOut' } });
  }
  openCustomCheckModal(event: any, teacher: any) {
    this.is_checked = event.target.checked
    this.teacher = teacher
    this.checkedProp = 'is_certified' + teacher.id
    this.actionType = 'customCheck'
    this.bsModalRef = this.modalService.show(this.customCheckModal, { class: 'modal-md', backdrop: 'static' });
  }
  closeCustomBoxModal(teacher: any) {
    if (teacher.is_certified == false) {
      const checkbox = this.renderer.selectRootElement('#' + this.checkedProp, true);
      this.renderer.setProperty(checkbox, 'checked', false);
    } else {
      const checkbox = this.renderer.selectRootElement('#' + this.checkedProp, false);
      this.renderer.setProperty(checkbox, 'checked', true);
    }
    this.bsModalRef.hide()
  }

  checkLength() {
    let timing = this.sendEmailForm.controls['email_content'].value;
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }
  // var index = this.myListData.map((x: any) => {
  //   return x.teacher.id;
  // }).indexOf(res.data.my_list[0].teacher.id);
  // this.myListData[index] = res.data.my_list[0];

   addEditNotes(teacherName: any, type: any, notes: any, id: any) {
      const initialState = {
        teacherName: teacherName,
        type: type,
        notes: notes,
        id: id,
        from_page: 'my_list',
        teachers_list: this.myListDataCopy
  
      };
      this.bsModalRef = this.modalService.show(AddNotesComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    }
}




