import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment'
import { CommonService } from 'src/app/services/api/common.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { MixpanelService } from 'src/app/mixpanel.service';
import { Intercom } from 'ng-intercom';
// declare var myExternalJs: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false
})
export class LoginComponent implements OnInit {

  mandatoryMessage: boolean = false;
  validationMessage: boolean = false;
  is_job_only_user: any;
  access_token: any
  username: any;
  password: any;
  public loginForm = this.loginFormFB.group({
    client_id: [environment.client_id],
    client_secret: [environment.client_secret],
    grant_type: ['password'],
    username: ['', Validators.required],
    password: ['', Validators.required],
  });
  token_type: any;
  refresh_token: any;
  booking_school_switch_angular: any;
  from: any;
  redirectToPage: any;
  window: any;
  jobSchoolUrl: boolean = false;
  postCallOut: any;
  terms_accepted: any;
  jobId: any;
environmentURL: any;
  CusMixData: any;
  constructor(
    private router: Router,
    private loginFormFB: FormBuilder,
    private authService: AuthenticationService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private mixpanelService: MixpanelService,
    public intercom: Intercom
  ) { }

  ngOnInit(): void {
    this.environmentURL = environment.apiUrl
    this.access_token = document.cookie.match('(^|;)\\s*' + 'access_token' + '\\s*=\\s*([^;]+)')?.pop() || '';
    this.refresh_token = document.cookie.match('(^|;)\\s*' + 'refresh_token' + '\\s*=\\s*([^;]+)')?.pop() || '';
    this.route.queryParams.subscribe(params => {
      this.jobId = params['jobId'];
    });
    // document.cookie = "sessionid=; path=/; domain=." + environment.cookDomain + "; expires=" + new Date(0).toUTCString();
    // document.cookie = "csrftoken=; path=/; domain=." + environment.cookDomain + "; expires=" + new Date(0).toUTCString();
    if (window.location.hostname == "jobs.classcover.com.au") {
      this.jobSchoolUrl = true
    }
    if (this.access_token != '') {
      this.ngxService.start()
      // document.cookie = "access_token=" + this.access_token + ";domain=." + environment.cookiesDomain + ";path=/";
      // document.cookie = "refresh_token=" + this.refresh_token + ";domain=." + environment.cookiesDomain + ";path=/";
      this.commonService.getUserData('').subscribe((res) => {
        if (res) {
          // console.log('res from me call', res);
          if (window.location.hostname == 'booking.classcover.com.au' && res.data.properties.job_only_school == true) {
            window.location.replace("https://jobs.classcover.com.au");
          }
          if (window.location.hostname == 'booking-test.classcover.com.au' && res.data.properties.job_only_school == true) {
            window.location.replace("https://jobs-test.classcover.com.au");
          }
          console.log("AccesstokenCheck", res)
          var userRole = res.data.properties.user_type.replace(/[^a-zA-Z ]/g, "");
          this.is_job_only_user = res.data.properties.job_only_school
          localStorage.setItem('schoolId', res.data.mixpanel_data.school_id);
          localStorage.setItem('current_plan', res.data.mixpanel_data.current_plan);
          localStorage.setItem('userData', JSON.stringify(res.data));
          localStorage.setItem('userRole', userRole);
          document.cookie = "userRole=" + userRole + ";domain=." + environment.cookiesDomain + ";path=/";
          localStorage.setItem('schoolAdId', res.data.properties.additional_user_id);
          localStorage.setItem('schoolLogo', res.data.mixpanel_data.school_avatar);
          localStorage.setItem('bookingSchool', res.data.properties.is_booking_school);
          localStorage.setItem('booking_school', res.data.properties.booking_school);
          this.mixpanelService.init(res.data.mixpanel_data.user_id.toString(), res.data.mixpanel_data.mixpanel_app_id)
          this.redirectToPage = res.data.properties.is_land_on_preconfirmed
          this.terms_accepted = res.data.properties.terms_accepted
          this.CusMixData = res.data.mixpanel_data
          this.toPageTredirection(res.data.properties.user_type,res.data.properties.is_onboarding)
        } else {
          this.ngxService.stop();
          console.log("error in the response", res);
        }
      });
    }
  }
  ngAfterContentInit() {
    if (window.location.hostname == "https://jobs.classcover.com.au") {
      this.jobSchoolUrl = true
    }
  }


  getFormControlValue() {
    return this.loginForm.value
  }

  logIn() {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;
    if (!username || !password) {
      this.validationMessage = false;
      this.mandatoryMessage = true;
    } else {
      this.authService.login(this.loginForm.value).subscribe({
        next: (res) => {
          this.commonService.getUserData('').subscribe((res) => {
            if (res) {
              if (window.location.hostname == 'booking.classcover.com.au' && res.data.properties.job_only_school == true) {
                window.location.replace("https://jobs.classcover.com.au");
              }
              // console.log('res from me call', res);
              var userRole = res.data.properties.user_type.replace(/[^a-zA-Z ]/g, "");
              // var schoolId = res.data.mixpanel_data.school_id.replace(/[^a-zA-Z ]/g, "");
              // var schoolAdId = res.data.properties.user_type.replace(/[^a-zA-Z ]/g, "");
              this.is_job_only_user = res.data.properties.job_only_school
              localStorage.setItem('schoolId', res.data.mixpanel_data.school_id);
              localStorage.setItem('current_plan', res.data.mixpanel_data.current_plan);
              localStorage.setItem('userData', JSON.stringify(res.data));
              localStorage.setItem('userRole', userRole);
              document.cookie = "userRole=" + userRole + ";domain=." + environment.cookiesDomain + ";path=/";
              localStorage.setItem('schoolAdId', res.data.properties.additional_user_id);
              localStorage.setItem('schoolLogo', res.data.mixpanel_data.school_avatar);
              localStorage.setItem('bookingSchool', res.data.properties.is_booking_school);
              localStorage.setItem('booking_school', res.data.properties.booking_school);
              this.mixpanelService.init(res.data.mixpanel_data.user_id.toString(), res.data.mixpanel_data.mixpanel_app_id)
              this.redirectToPage = res.data.properties.is_land_on_preconfirmed
              this.terms_accepted = res.data.properties.terms_accepted
              this.CusMixData = res.data.mixpanel_data
              this.toPageTredirection(res.data.properties.user_type,res.data.properties.is_onboarding)
            } else {
              console.log("error in the response", res);
            }
          });
        },
        error: (err) => {
          console.log(err);
          this.mandatoryMessage = false;
          this.validationMessage = true;
          this.ngxService.stop()
        }
      })
    }
  }

  toPageTredirection(user: any,onboardingCheck:any) {
    this.route.queryParams.subscribe(params => {
      this.postCallOut = params['type'];
    });
// return;
    if (this.is_job_only_user == false) {
      switch (user) {
        case 'School':
          // myExternalJs.SetIdCustomerIo(this.CusMixData);
          if (this.booking_school_switch_angular == true) {
            this.router.navigateByUrl('/school/jobs');
          }else { 
            // if(onboardingCheck == false){
            //   this.router.navigateByUrl('/school/school-onboarding');
            // }
            if (this.redirectToPage == true) {
              this.router.navigateByUrl('/school/preconfirm-booking');
            }
             else {
              this.router.navigateByUrl('/school/booking-request');
            }
          }
          break;
        case 'Teacher':
          this.router.navigateByUrl('/consumer');
          break;

        default:
          this.router.navigateByUrl('/login');
          break;
      }
    } else {
      switch (user) {
        case 'School':
          if(this.postCallOut == 'postCallOut') {
              this.router.navigate(['/school/post-job'], { queryParams: {type: 'postCallOut'} });
          }
          else if(this.jobId != undefined){
            this.router.navigate(['/school/job-details'], { queryParams: {job_id: this.jobId} });
          }else if(this.terms_accepted == true){
            this.router.navigateByUrl('/school/jobs');
          }
          else if(this.terms_accepted == false){
            this.router.navigateByUrl('/school/termsAndCondition');
          }
        break;
        case 'Teacher':
          this.router.navigateByUrl('/consumer');
          break;

        default:
          this.router.navigateByUrl('/login');
          break;
      }
    }
  }
}
