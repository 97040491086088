import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationModalComponent } from 'src/app/layout/confirmation-modal/confirmation-modal.component';
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
@Component({
  selector: 'app-add-new-modal',
  templateUrl: './add-new-modal.component.html',
  styleUrls: ['./add-new-modal.component.scss'],
  standalone: false
})
export class AddNewModalComponent implements OnInit {
  public bulkUploadForm!: FormGroup;
  public addNewForm!: FormGroup;
  @ViewChild("fileInput") fileInput: any;

  fields: any;
  fileName: any;
  file: any;
  fileUpload: any;
  // bulkUploadForm: FormGroup = this.fb.group({
  //   bulk_upload: [''],
  // })

  constructor(public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private _router: Router,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.formInIt()
  }
  formInIt() {
    this.addNewForm = this.fb.group({
      teacher_list: new FormArray([]),
    })
    this.addPhone()

    this.bulkUploadForm = this.fb.group({
      bulk_upload: ['']
    })
  }
  quantities(): FormArray {
    return this.addNewForm.get("teacher_list") as FormArray
  }
  getControlValue() {
    return this.addNewForm.value;
  }
  getControlValueBulk() {
    return this.bulkUploadForm;
  }
  newQuantity(): FormGroup {
    return this.fb.group({
      full_name: '',
      email: '',
      phone_no: '',
    })
  }
  addPhone() {
    this.quantities().push(this.newQuantity());
  }
  getPhonesFormControls() {
    // return (<FormArray> this.addNewForm.get('phone')).controls
    return this.addNewForm.get("teacher_list") as FormArray
  }
  saveAddNewList() {
    this.ngxService.start();
    // return;
    if (this.activeTab == 'pending') {
      this.bookingService.addNewListSingle(this.getControlValue()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bsModalRef.hide()
            this.toastr.success(res.message);
            this.ngxService.stop();
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error(err.message);
          this.ngxService.stop();
        }
      });
    } else {
      this.bookingService.addNewListBulk(this.fileUpload).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bsModalRef.hide()
            this.toastr.success(res.message);
            this.ngxService.stop();
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error(err.message);
          this.ngxService.stop();
        }
      });
    }
  }
  activeTab = 'pending';
  invites(activeTab: string) {
    this.activeTab = activeTab;
  }
  history(activeTab: string) {
    this.activeTab = activeTab;
  }
  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.fileName = !this.fileName;
      this.fileName = event.target.files[0].name;
      this.fileUpload = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
      };
    
    }
  }
  removeFile() {
    this.file = '';
  }
}
