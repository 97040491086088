import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-session-ends-modal',
  templateUrl: './session-ends-modal.component.html',
  styleUrls: ['./session-ends-modal.component.scss'],
  standalone: false
})
export class SessionEndsModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _router: Router,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  redirectToLogin(){
    this.bsModalRef.hide()
        // window.alert('invalid credential');
        this.authService.loggedOut();
  }
}
