import { environment } from './../../../../environments/environment.staging';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute , NavigationStart} from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, Dimensions } from 'ngx-image-cropper';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BookingService } from 'src/app/services/api/booking.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { SchoolData } from 'src/app/models/schoolUser';
import { CommonService } from 'src/app/services/api/common.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../services/api/message.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-school-profile',
  templateUrl: './school-profile.component.html',
  styleUrls: ['./school-profile.component.scss'],
  standalone: false
})

export class SchoolProfileComponent implements OnInit {
  firstPage: number = 1;
  profileData: any;
  show_state: any;
  listHealth: any;
  public submitted: boolean = false;
  country: any;
  state: any;
  public schoolProps!: SchoolData;
  public overviewForm: any
  public companyOverviewForm: any
  public jobsOnlyOverviewForm: any
  public profileInfoEdit: any
  recentJobsList: any
  sendData: any

  new_Announcement_InfoEdit: FormGroup = this.fb.group({
    from: ['announcementNew'],
    announcement: [''],
    new_pin_at_top: [''],
  });
  sendReciveRquestForm: FormGroup = this.fb.group({
    show_profile: [''],
  });


  school_overview_InfoEdit: FormGroup = this.fb.group({
    from: ['profileOverViewEdit'],
    school_email: [''],
    school_contact_number: [''],
    school_emergency_number: [''],
    school_principle: [''],
    school_rta_contact: [''],
    school_rta_contact_name: [''],
    school_overview: [''],
  });

  onboarding_documents_edit: FormGroup = this.fb.group({
    from: ['onboardingEdit'],
    doc_type: [''],
    doc_link: [''],
  });

  contactDetailsforCancelForm: FormGroup = this.fb.group({
    cancellation_email: ['', [Validators.required, this.noWhitespaceValidator]],
    cancellation_phone: ['', [Validators.required, this.noWhitespaceValidator]],
    from:['inAppCancel']
  });

  newAnnounceMentForm: FormGroup = this.fb.group({
    from: ['announcementNew'],
    announcement: ['', [Validators.required,]],
  });

  onboardingDocumentsForm: FormGroup = this.fb.group({
    from: ['onboarding_new'],
    doc_type: ['', [Validators.required,]],
    doc_link: ['', [Validators.required,]]
  });

  AdditionalInfoForm: FormGroup = this.fb.group({
    from: ['additionalinfo_new'],
    title: ['', [Validators.required,]],
    information: ['', [Validators.required,]]
  });

  @ViewChild('editJobOnlyUserOverview') editJobOnlyUserOverview: any;
  @ViewChild('editCompanyOverviewModal') editCompanyOverviewModal: any;
  @ViewChild('editSchoolOverviewModal') editSchoolOverviewModal: any;
  @ViewChild('editOnboardingDocumentsModal') editOnboardingDocumentsModal: any;
  @ViewChild('editAdditionalInformationModal') editAdditionalInformationModal: any;
  @ViewChild('editProfileInformationModal') editProfileInformationModal: any;
  @ViewChild('teacherReviewsModal') teacherReviewsModal: any;
  @ViewChild('schoolAnnouncementsModal') schoolAnnouncementsModal: any;
  @ViewChild('newAnnouncementModal') newAnnouncementModal: any;
  @ViewChild('RemoveAnnounceConfirmation') RemoveAnnounceConfirmation: any;
  @ViewChild('receiveRequestModal') receiveRequestModal: any;
  @ViewChild('inAppCancelModal') inAppCancelModal: any;

  lat: any;
  lng: any;
  mapsUrl: any;
  choices: any;
  filtersArray: any;
  school_type: any;
  ShowProfileData: any;
  actualState: any;
  videoUrl: any;
  showCropper = false;
  storageItem: any;
  editType?: string;

  containWithinAspectRatio = false;
  overview: any;
  href: any;
  public Csubmitted: boolean = false;
  public Jsubmitted: boolean = false;
  receiveRequestEvent: any;
  show_school: any;
  submittedInAppForm:boolean = false;
  checkToken: any;
  viewAsProducer: any;
  title: any;
  onboardingProcess: any;


  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private mixpanelService: MixpanelService
  ) { }
  currentPage: number = 1;

  ngOnInit(): void {
    this.checkToken = document.cookie.match('(^|;)\\s*' + 'access_token' + '\\s*=\\s*([^;]+)')?.pop() || ''
    this.route.params.subscribe(params => {
      this.viewAsProducer = params['slugId'];
    });

    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    })
    this.title = this._router.url.split('?');
    if (this.title[0] == '/school/onboarding-profile') {
    this.onboardingProcess = true
    } else{
      this.onboardingProcess = false
    }
      
    if(this.viewAsProducer != undefined && this.checkToken == ''){
      this.ngxService.start();
      this.commonService.publicSchool(this.viewAsProducer).subscribe({
        next: (res: any) => {
          this.profileData = res.data;
          this.recentJobsList = res?.data?.recent_job;
          let videoUrl = 'https://maps.google.com/maps?q=' + this.profileData.location.lat + ',' + this.profileData.location.lng + '&z=15&&output=embed'
          this.mapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl)
          console.log(res);

          this.ngxService.stop();
        },
        error: (err: any) => {
          this.toastr.error(err.message)
          this.ngxService.stop();
          console.log(err);
        }
      })
      return;
    }
    this.ngxService.start();
    this.route.queryParams.subscribe(params => {
      this.editType = params['editType'];
    });
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.getFiltersData();
    this.bookingService.profilePageDetails(this.firstPage).subscribe({
      next: (res: any) => {
        this.profileData = res.data;
        // this.overview = res.data.overview.replace(/\n/g, '<br/>');

        // console.log(this.profileData);
        this.lat = this.profileData.location.lat;
        this.lng = this.profileData.location.lng;
        if(this.profileData.info.show_school == 1){
          this.show_school = true
        }else if(this.profileData.info.show_school == 0){
          this.show_school = false
        }

        this.videoUrl = 'https://maps.google.com/maps?q=' + this.profileData.location.lat + ',' + this.profileData.location.lng + '&z=15&&output=embed'
        this.mapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl)
        // console.log(this.sanitizer.bypassSecurityTrustResourceUrl('https://maps.google.com/maps?q='+this.profileData.location.lat+','+this.profileData.location.lng+'&hl=es;z=14&amp;&output=embed'))
        this.formInit();
        if (this.profileData.additional_info.profile_image != undefined) {
          localStorage.setItem('schoolLogo', this.profileData.additional_info.profile_image);
          this.sharedService.school_logo.next(this.profileData.additional_info.profile_image)
        }

        this.ngxService.stop();
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    })
  }

  closeModal(data: any) {
    this.overviewForm.patchValue({
      school_overview: data.overview,
      school_email: data.email,
      contact_number: data.phone_number,
      school_type: data.subscription.school_type,
    });
    this.bsModalRef.hide()
  }

  formInit() {
    this.overviewForm = this.fb.group({
      from: ['additionalInfoEdit'],
      school_overview: ['', [Validators.required, this.noWhitespaceValidator]],
      school_email: ['', [Validators.required, this.noWhitespaceValidator]],
      contact_number: ['', [Validators.required, this.noWhitespaceValidator]],
      school_type: [null, [Validators.required, this.noWhitespaceValidator]],
      school_emergency_phone_number: [''],
      school_principal_full_name: [null, [Validators.required, this.noWhitespaceValidator]],
      rta_name: [null, [Validators.required, this.noWhitespaceValidator]],
      rta_number: [null, [Validators.required, this.noWhitespaceValidator]],
      enrolment_size: [null, [Validators.required, this.noWhitespaceValidator]],
      teachers_size: [''],
      school_gender: [null, [Validators.required, this.noWhitespaceValidator]],
      school_level:[''],
    });

    this.companyOverviewForm = this.fb.group({
      from: ['additionalInfoEdit'],
      school_overview: ['', [Validators.required, this.noWhitespaceValidator]],
      school_email: ['', [Validators.required, this.noWhitespaceValidator]],
      contact_number: ['', [Validators.required, this.noWhitespaceValidator]],
      school_type: [null]
    });

    this.jobsOnlyOverviewForm = this.fb.group({
      from: ['additionalInfoEdit'],
      school_overview: ['', [Validators.required, this.noWhitespaceValidator]],
      school_email: ['', [Validators.required, this.noWhitespaceValidator]],
      contact_number: ['', [Validators.required, this.noWhitespaceValidator]],
      school_type: ['', [Validators.required, this.noWhitespaceValidator]],
    });

    this.profileInfoEdit = this.fb.group({
      from: ['profileInfoEdit'],
      school_logo: [''],
      school_website: ['', [Validators.required, this.noWhitespaceValidator]],
      profile_country: ['', [Validators.required, this.noWhitespaceValidator]],
      profile_state: ['', [Validators.required, this.noWhitespaceValidator]],
      street_1: ['', [Validators.required, this.noWhitespaceValidator]],
      street_number: ['', [Validators.required, this.noWhitespaceValidator]],
      postal_code: ['', [Validators.required, this.noWhitespaceValidator]],
      StatetimeZone: ['', [Validators.required, this.noWhitespaceValidator]],
      city: ['', [Validators.required, this.noWhitespaceValidator]],
      logo: ['']
    });


    if (this.editType == 'school-profile') {
      this.showEditProfInfoModal(this.profileData);
    }

  }

  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('profile').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.school_type = res.data.school_type;
        this.state = res.data.state
        this.country = res.data.country
        // this.ngxService.stop();
      },

      error: (err: any) => {
        console.log(err);
      }
    });

    this.commonService.recentJobs().subscribe({
      next: (res: any) => {
        this.recentJobsList = res?.data;
      },

      error: (err: any) => {
        console.log(err);
      }
    });
  }
  countryOnChange() {
    let b1 = this.profileInfoEdit.controls['profile_country'].value
    let arrObj = Array();
    this.state.map((e: any, i: any) => {
      if (e.country == b1) {
        arrObj.push(e);
      }
    })
    this.actualState = [...arrObj]
    this.profileInfoEdit.patchValue({
      profile_state: null
    });
  }

  getFiltersValue() {
    this.country = this.profileInfoEdit.controls['profile_country'].value;
    this.bookingService.profilePageChanges(this.country).subscribe({
      next: (res: any) => {
        this.show_state = res.response;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getPinValues() {
    return this.new_Announcement_InfoEdit.value;
  }
  getAddInforValues() {
    return this.overviewForm.value;
  }
  getSendReciveRquestForm() {
    return this.sendReciveRquestForm.value;
  }
  
  getCompanyOverviewValues() {
    return this.companyOverviewForm.value;
  }
  getJobOverviewValues() {
    return this.jobsOnlyOverviewForm.value;
  }

  getProfileInfoValues() {
    return this.profileInfoEdit.value;
  }
  getCancelContactDetails() {
    
    return this.contactDetailsforCancelForm.value;
  }
  closeProfileInfo() {
    this.profileInfoEdit.patchValue({
      logo: '',
    });
    this.bsModalRef.hide()
  }
  pinAnnouncement(index: any) {
    this.new_Announcement_InfoEdit.patchValue({
      new_pin_at_top: index,
      from: 'announcementView',
    });
    this.bookingService.editProfileData(this.getPinValues()).subscribe({
      next: (res: any) => {
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.profileInfoEdit.controls;
  }

  get po(): { [key: string]: AbstractControl } {
    return this.overviewForm.controls;
  }
  get Co(): { [key: string]: AbstractControl } {
    return this.companyOverviewForm.controls;
  }
  get Jo(): { [key: string]: AbstractControl } {
    return this.companyOverviewForm.controls;
  }
  getTimeZoneValue() {
    let b1 = this.profileInfoEdit.controls['profile_state'].value;
    this.state.map((e: any, i: any) => {
      if (e.value == b1) {
        this.profileInfoEdit.patchValue({
          StatetimeZone: e.timezone
        });
        this.toastr.success('Timezone ' + e.timezone + ' updated')
      }
    })
  }

  submitOverview() {
    this.submitted = true;
    if (this.overviewForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.overviewForm.patchValue({
      from: 'editOverview',
    });
    this.bookingService.editProfileData(this.getAddInforValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  submitJobOnlyOverview() {
    this.Jsubmitted = true;
    if (this.jobsOnlyOverviewForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.jobsOnlyOverviewForm.patchValue({
      from: 'editOverview',
    });
    this.bookingService.editProfileData(this.getJobOverviewValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  submitCompanyOverview() {
    this.Csubmitted = true;
    if (this.companyOverviewForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.companyOverviewForm.patchValue({
      from: 'editOverview',
    });
    this.bookingService.editProfileData(this.getCompanyOverviewValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  saveProfileInformation() {
    this.submitted = true;
    if (this.profileInfoEdit.invalid) {
      return;
    }
    this.ngxService.start();

    if (this.croppedImage != '') {
      this.profileInfoEdit.patchValue({
        from: 'profileInfo',
        school_logo: this.croppedImage
      });
    } else {
      this.profileInfoEdit.patchValue({
        from: 'profileInfo',
        school_logo: ''
      });
    }

    this.bookingService.editProfileData(this.getProfileInfoValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  showTeacherReviewModal() {
    this.bsModalRef = this.modalService.show(this.teacherReviewsModal, { class: 'modal-lg', backdrop: 'static' });
  }
  showOverviewJobModal(data: any) {
    this.Jsubmitted = false
    this.ShowProfileData = data
    this.jobsOnlyOverviewForm.patchValue({
      school_overview: this.ShowProfileData.overview,
      school_email: this.ShowProfileData.email,
      contact_number: this.ShowProfileData.phone_number,
      school_type: this.ShowProfileData.subscription.school_type,
    });

    this.bsModalRef = this.modalService.show(this.editJobOnlyUserOverview, { class: 'modal-lg', backdrop: 'static' });
  }
  showOverviewModal(data: any) {
    this.submitted = false
    this.ShowProfileData = data
    this.overviewForm.patchValue({
      school_overview: this.ShowProfileData.overview,
      school_email: this.ShowProfileData.email,
      contact_number: this.ShowProfileData.phone_number,
      school_type: this.ShowProfileData.subscription.school_type,

      school_emergency_phone_number: this.ShowProfileData.additional_info.school_emergency_phone_number,
      school_principal_full_name: this.ShowProfileData.additional_info.school_principal_full_name,
      rta_name: this.ShowProfileData?.rta_user?.user?.full_name,
      rta_number: this.ShowProfileData?.rta_user?.phone,
      enrolment_size: this.ShowProfileData?.subscription?.enrolment_size,
      teachers_size: this.ShowProfileData.additional_info.teachers_size,
      school_gender: this.ShowProfileData?.additional_info?.school_gender,
      school_level: this.ShowProfileData?.subscription?.school_level,
    });

    this.bsModalRef = this.modalService.show(this.editSchoolOverviewModal, { class: 'modal-lg', backdrop: 'static' });
  }
  showCompanyOverviewModal(data: any) {
    this.Csubmitted = false
    this.ShowProfileData = data
    this.companyOverviewForm.patchValue({
      school_overview: this.ShowProfileData.overview,
      school_email: this.ShowProfileData.email,
      contact_number: this.ShowProfileData.phone_number,
    });
    this.bsModalRef = this.modalService.show(this.editCompanyOverviewModal, { class: 'modal-lg', backdrop: 'static' });
  }
  showEditeAdditionalModal() {
    this.bsModalRef = this.modalService.show(this.editAdditionalInformationModal, { class: 'modal-md', backdrop: 'static' });
  }

  showEditProfInfoModal(data: any) {
    this.submitted = false
    this.ShowProfileData = data
    this.profileInfoEdit.patchValue({
      // school_logo: this.ShowProfileData.additional_info.profile_image,
      school_website: this.ShowProfileData.additional_info.school_website,
      profile_country: this.ShowProfileData.address.address_timezone.country,
      profile_state: this.ShowProfileData.address.address_timezone.state,
      street_1: this.ShowProfileData.address.locality,
      city: this.ShowProfileData.address.city,
      street_number: this.ShowProfileData.address.street,
      postal_code: this.ShowProfileData.address.postal_code,
      StatetimeZone: this.ShowProfileData.address.address_timezone.timezone,
    });

    let b1 = this.ShowProfileData.address.address_timezone.country
    let arrObj = Array();
    this.state.map((e: any, i: any) => {
      if (e.country == b1) {
        arrObj.push(e);
        this.profileInfoEdit.patchValue({
          profile_state: this.ShowProfileData.address.address_timezone.state
        });
      }
    })
    this.actualState = [...arrObj]
    this.bsModalRef = this.modalService.show(this.editProfileInformationModal, { class: 'modal-lg', backdrop: 'static' });
  }
  showSchoolAnnModal() {
    this.bsModalRef = this.modalService.show(this.schoolAnnouncementsModal, { class: 'modal-lg', backdrop: 'static' });
  }


  imageChangedEvent: any = '';
  croppedImage: any = '';
  school_logo: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // this.school_logo = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;
    // show cropper
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
    // cropper ready
  }
  loadImageFailed() {
    this.toastr.warning('Upload PNG format images')
    this.profileInfoEdit.patchValue({
      logo: '',
    });
    this.croppedImage = ''
    return;
    // show message
  }
  edit_school_overview(): void {
    this.submitted = true;
    this.ngxService.start();
    this.bookingService.editProfileData(this.profileInfoEdit.value).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  new_announcement(): void {
    this.submitted = true;
    this.ngxService.start();
    this.bookingService.editProfileData(this.new_Announcement_InfoEdit.value).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  school_overview_edit(): void {
    this.submitted = true;
    this.ngxService.start();
    // Get filters data API
    this.bookingService.editProfileData(this.school_overview_InfoEdit.value).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  school_onboarding_documents_edit(): void {
    this.submitted = true;
    this.ngxService.start();
    // Get filters data API
    this.bookingService.editProfileData(this.onboarding_documents_edit.value).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  // Annoucement CRED start

  shownewAnnouncementModal() {
    this.newAnnounceMentForm.removeControl('index');
    this.newAnnounceMentForm.patchValue({
      from: 'announcementNew',
      announcement: ''
    });
    this.bsModalRef = this.modalService.show(this.newAnnouncementModal, { class: 'modal-md', backdrop: 'static' });
  }

  saveNewAnnoucement() {
    if (this.getAnnoucemNetFormControl['from'].value != 'remove_announcement' && this.getAnnoucemNetFormControl['from'].value != 'announcement_pin') {
      if (this.newAnnounceMentForm.invalid) {
        return;
      }
    }
    this.sendData = this.getAnnounceMentValues();
    this.publicEditProfile();
  }

  getAnnounceMentValues() {
    return this.newAnnounceMentForm.value;
  }

  get getAnnoucemNetFormControl() {
    return this.newAnnounceMentForm.controls;
  }

  editAnnoucement(index: any) {
    this.bsModalRef.hide();
    this.newAnnounceMentForm.addControl('index', new FormControl(index));
    this.newAnnounceMentForm.patchValue({
      from: 'announcementEdit',
      announcement: this?.profileData?.additional_info?.announcements[index]?.announcement
    });
    this.bsModalRef = this.modalService.show(this.newAnnouncementModal, { class: 'modal-md', backdrop: 'static' });
  }

  pinAnnoucement(index: any) {
    this.bsModalRef.hide();
    this.newAnnounceMentForm.addControl('index', new FormControl(index));
    // this.newAnnounceMentForm.removeControl('announcement');
    this.newAnnounceMentForm.patchValue({
      from: 'announcement_pin',
      announcement: this?.profileData?.additional_info?.announcements[index]?.announcement
    });
    this.saveNewAnnoucement();
  }

  removeAnnoucement(index: any) {
    this.bsModalRef.hide();
    this.newAnnounceMentForm.addControl('index', new FormControl(index));
    // this.newAnnounceMentForm.removeControl('announcement');
    this.newAnnounceMentForm.patchValue({
      from: 'remove_announcement',
    });
    this.sendData = this.getAnnounceMentValues();
    this.bsModalRef = this.modalService.show(this.RemoveAnnounceConfirmation, { class: 'modal-md', backdrop: 'static' });
  }

  closeAnnocementModal() {
    this.bsModalRef.hide();
  }

  // Annoucement CRED end

  // Onboarding CRED start

  showOnboardDocModal() {
    this.onboardingDocumentsForm.removeControl('index');
    this.onboardingDocumentsForm.patchValue({
      from: 'onboarding_new',
      doc_type: '',
      doc_link: ''
    });
    this.bsModalRef = this.modalService.show(this.editOnboardingDocumentsModal, { class: 'modal-md', backdrop: 'static' });
  }

  saveOnboarding() {
    if (this.getOnBoardingFormControl['from'].value != 'remove_announcement') {
      if (this.onboardingDocumentsForm.invalid) {
        return;
      }
    }
    this.sendData = this.getOnBoardingValues();
    this.publicEditProfile();
  }

  get getOnBoardingFormControl() {
    return this.onboardingDocumentsForm.controls;
  }

  getOnBoardingValues() {
    return this.onboardingDocumentsForm.value;
  }

  editOnBoarding(index: any) {
    this.bsModalRef.hide();
    this.onboardingDocumentsForm.addControl('index', new FormControl(index));
    this.onboardingDocumentsForm.patchValue({
      from: 'onboarding_edit',
      doc_type: this?.profileData?.additional_info?.onboarding_documents[index]?.doc_type,
      doc_link: this?.profileData?.additional_info?.onboarding_documents[index]?.doc_url,
    });
    this.bsModalRef = this.modalService.show(this.editOnboardingDocumentsModal, { class: 'modal-md', backdrop: 'static' });
  }

  removeOnBoarding(index: any) {
    this.bsModalRef.hide();
    this.onboardingDocumentsForm.addControl('index', new FormControl(index));
    // this.onboardingDocumentsForm.removeControl('doc_type');
    // this.onboardingDocumentsForm.removeControl('doc_link');
    this.onboardingDocumentsForm.patchValue({
      from: 'onboarding_remove',
    });
    this.sendData = this.getOnBoardingValues();
    this.bsModalRef = this.modalService.show(this.RemoveAnnounceConfirmation, { class: 'modal-md', backdrop: 'static' });
  }

  closeOnBoardingModal() {
    this.bsModalRef.hide();
  }

  // Onboarding CRED end


  // Additional Info CRED start
  saveAdditionalInfo() {
    if (this.AdditionalInfoForm.invalid) {
      return;
    }
    this.sendData = this.getAdditionalInfoValues();
    this.publicEditProfile();
  }

  get getAdditionalInfoFormControl() {
    return this.AdditionalInfoForm.controls;
  }

  getAdditionalInfoValues() {
    return this.AdditionalInfoForm.value;
  }

  editAdditionalInfo(index: any) {
    this.bsModalRef.hide();
    this.AdditionalInfoForm.addControl('index', new FormControl(index));
    this.AdditionalInfoForm.patchValue({
      from: 'additionalinfo_edit',
      title: this?.profileData?.additional_info?.additional_information[index]?.title,
      information: this?.profileData?.additional_info?.additional_information[index]?.content,
    });
    this.bsModalRef = this.modalService.show(this.editAdditionalInformationModal, { class: 'modal-md', backdrop: 'static' });
  }

  removeAdditionalInfo(index: any) {
    this.bsModalRef.hide();
    this.AdditionalInfoForm.addControl('index', new FormControl(index));
    // this.AdditionalInfoForm.removeControl('title');
    // this.AdditionalInfoForm.removeControl('information');
    this.AdditionalInfoForm.patchValue({
      from: 'remove_additional_info',
    });
    this.sendData = this.getAdditionalInfoValues();
    this.bsModalRef = this.modalService.show(this.RemoveAnnounceConfirmation, { class: 'modal-md', backdrop: 'static' });
  }


  viewAsTeacher() {
    console.log("called");
    // this._router.navigate(["school/school-profile/"+this.profileData?.slug]);
    window.open(environment.siteUrl + 'school/school-profile/' + this.profileData?.slug)
    
  }

  closeAdditionalInfoModal() {
    this.AdditionalInfoForm.removeControl('index');
    this.AdditionalInfoForm.patchValue({
      from: 'additionalinfo_new',
      title: '',
      information: ''
    });
    this.bsModalRef.hide();
  }

  // Additional Info CRED end



  publicEditProfile() {
    this.ngxService.start();
    this.bookingService.editProfileData(this.sendData).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  completeProfile() {
    if (!this.profileData?.additional_info?.profile_image.length || !this.profileData?.additional_info?.school_website.length) {
      this.showEditProfInfoModal(this.profileData);
      return;
    }

    if (!this.profileData?.overview.length || !this.profileData?.phone_number.length || !this.profileData?.email.length || !this.profileData?.additional_info?.school_principal_full_name.length || !this.profileData?.additional_info?.school_gender.length || !this.profileData?.subscription?.enrolment_size.length || !this.profileData?.subscription?.school_type.length) {
      this.showOverviewModal(this.profileData);
    }
  }
  openPublicJobs(id: any) {
    window.open(environment.apiUrl + "jobs/view-job/" + id, "_blank");
  }
  openPublicJobsCommon() {
    window.open(environment.apiUrl + "jobs", "_blank");
  }
  openAngularJobDetail(id: any){
    window.open("https://jobs.classcover.com.au/login"+'?jobId='+id,  '_blank');
  }
  openAngularJobs(){
    window.open("https://jobs.classcover.com.au",  '_blank');
  }
  onCheckboxChange(event: any) {
    if (event.target.checked == false) {
      this.receiveRequestEvent = event.target.checked
      this.bsModalRef = this.modalService.show(this.receiveRequestModal, { class: 'modal-md', backdrop: 'static' });
    }else{
      this.receiveRequestEvent = event.target.checked
      this.bsModalRef = this.modalService.show(this.receiveRequestModal, { class: 'modal-md', backdrop: 'static' });
    }
  }
  sendReceiveRequest() {
    if(this.receiveRequestEvent == true){
      this.sendReciveRquestForm.patchValue({
        show_profile: 1
      })
    }else{
      this.sendReciveRquestForm.patchValue({
        show_profile: 0
      })
    }
    
    this.bookingService.sendReceiveRequestAPI(this.getSendReciveRquestForm()).subscribe({
      next: (res: any) => {
        this.ngxService.start();
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  cancelRequest(){
    if(this.profileData.info.show_school == 1){
      this.show_school = true
    }else if(this.profileData.info.show_school == 0){
      this.show_school = false
    }
    this.bsModalRef.hide()
  }
  editCancelDetailsModal(){
          this.bsModalRef = this.modalService.show(this.inAppCancelModal, { class: 'modal-md', backdrop: 'static' });
    this.contactDetailsforCancelForm.patchValue({
      cancellation_email:this.profileData.additional_info.booking_cancel_casuals.cancellation_email,
      cancellation_phone:this.profileData.additional_info.booking_cancel_casuals.cancellation_phone
    })
  }
  sendInAppCancelData() {
    this.submittedInAppForm = true
    if (this.contactDetailsforCancelForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.bookingService.editProfileData(this.getCancelContactDetails()).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide()
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        this.toastr.error("something went wrong")
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  routerChangeMethod(url: any) {
    this.title = url.split('?');
    if (this.title[0] == '/school/onboarding-profile') {
    this.onboardingProcess = true
    } else{
      this.onboardingProcess = false
    }
  }
  stepCompleted(){
    this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_completed' } });
  }
  stepTwo(){
    this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_three' } });
  }
}