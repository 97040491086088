import { ChangeDetectorRef, Component, OnInit, ViewChildren, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { map, take } from "rxjs/operators";

// Libs
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

import { ConfirmationModalComponent } from '../../../layout/confirmation-modal/confirmation-modal.component';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { BookingConfrimModalComponent } from '../modals/booking-confrim-modal/booking-confrim-modal.component';
import { ValidatedDictComponent } from '../validated-dict/validated-dict.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AddNotesComponent } from '../modals/add-notes/add-notes.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-hubandspoke',
  templateUrl: './hubandspoke.component.html',
  styleUrls: ['./hubandspoke.component.scss'],
  standalone: false
})
export class HubandspokeComponent implements OnInit {
  hubSPokeForm: FormGroup = this.fb.group({
    status: [''],
  });
  acceptDeclineForm: FormGroup = this.fb.group({
    booking_id: [''],
    action: [''],
  });
  hubSpokeData: any;
  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private _router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.ngxService.start()
    this.bookingService.getHubSpokeDataAPI(this.getFilterFormControlValues()).subscribe({
      next: (res: any) => {
        console.log(res);
        if(res.status){
          this.hubSpokeData = res.data;
          this.ngxService.stop();
        }else{
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  getFilterFormControlValues() {
    return this.hubSPokeForm.value
  }

  getAcceptDeclineForm() {
    return this.acceptDeclineForm.value
  }
  filterHubSpoke(){
    this.ngxService.start()
    this.bookingService.getHubSpokeDataAPI(this.getFilterFormControlValues()).subscribe({
      next: (res: any) => {
        if(res.status){
          this.hubSpokeData = res.data;
          this.ngxService.stop();
        }else{
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  action(teacher_id: any , action: any){
      this.acceptDeclineForm.patchValue({
        action:action,
        booking_id:teacher_id
      })
    
    this.ngxService.start()
    this.bookingService.acceptDecHubSpokeAPI(this.getAcceptDeclineForm()).subscribe({
      next: (res: any) => {
        if(res.status){
          this.toastr.success(res.message)
          // this.hubSpokeData = res.data;
          this.filterHubSpoke()
          // var index = this.hubSpokeData.map((x: any) => {
          //   return x.id;
          // }).indexOf(res.data.id);
          // this.hubSpokeData[index] = res.data.id;
          // this.ngxService.stop();
        }else{
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.message)
        this.ngxService.stop();
      }
    }) 
  }
}
