import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsuscribe-email',
  templateUrl: './unsuscribe-email.component.html',
  styleUrls: ['./unsuscribe-email.component.scss'],
  standalone: false
})
export class UnsuscribeEmailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
