import { Component } from '@angular/core';
import { SchoolData } from 'src/app/models/schoolUser';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/api/booking.service';

@Component({
  selector: 'app-onboarding-school',
  templateUrl: './onboarding-school.component.html',
  styleUrls: ['./onboarding-school.component.scss'],
  standalone: false
})
export class OnboardingSchoolComponent {
  public schoolProps!: SchoolData;
  profile_percent: any;
  step: any;
  stepsCheck: any;

  
  constructor(
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private _router:Router,
    private bookingService: BookingService
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.bookingService.onboardingProcessCheck('').subscribe({
      next: (res: any) => {
        this.stepsCheck = res.data
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.ngxService.stop()
        console.log(err);
      }
    })  

   this.profile_percent = 'width:' + this.schoolProps.properties.profile_percentage + '%';
  }

  
}
