import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { CommonService } from 'src/app/services/api/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import { environment } from '../../../environments/environment'


declare var $: any;


@Component({
  selector: 'app-swich-back-to-python',
  templateUrl: './swich-back-to-python.component.html',
  styleUrls: ['./swich-back-to-python.component.scss'],
  standalone: false
})
export class SwichBackToPythonComponent implements OnInit {

  @Input() fromComp: any;
  @Input() jobOnly: any;

  currentUrl!: string;
  pageLoad: Boolean = false;

  constructor(
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private _router: Router
  ) {
  }

  ngOnInit(): void {

    // this.route.events.subscribe((event: any) => {

    //   if (event instanceof NavigationEnd) {
    //     const segments: string[] = [];
    //     let currentRoute: ActivatedRouteSnapshot = this.route.routerState.root.snapshot;
    //     while (currentRoute) {
    //       const path = currentRoute.routeConfig?.path;
    //       if (path && path !== '') { // Filter out these empty paths
    //         segments.push(path);
    //       }
    //       currentRoute = currentRoute.children[0];
    //     }
    //     const rawPath = segments.join('/');
    //     this.pageLoad = rawPath === 'login' || rawPath === 'register' ? false : true;
    //     console.log(this.jobOnly)
    //     console.log(this.pageLoad)

    //   }
    // });
    // this.pageLoad = this.jobOnly != null ? true : false;
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    })
    $(document).ready(function () {
      $('[data-bs-toggle="tooltip"]').tooltip();
    })
  }
  routerChangeMethod(url: string) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pageLoad = this.jobOnly == null || this.jobOnly == true ? false : true;
  }

  // routerChangeMethod(url: string) {
  //   this.pageLoad = this.jobOnly != null ? true : false;
  // }

  switchBack() {
    this.ngxService.start();
    this.commonService.switchBackToPython().subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        if (res) {
          window.location.replace(environment.apiUrl);
        } else {
          console.log("error in the response", res);
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.message)
        this.ngxService.stop();
      }
    })
  }

}
