import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-type',
  templateUrl: './role-type.component.html',
  styleUrls: ['./role-type.component.scss'],
  standalone: false
})
export class RoleTypeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
