import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-validated-dict',
  templateUrl: './validated-dict.component.html',
  styleUrls: ['./validated-dict.component.scss'],
  standalone: false
})
export class ValidatedDictComponent implements OnInit {
@Input() validateDict: any;
@Input() from: any;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.validateDict)
  }

}
