import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { last, map, take } from "rxjs/operators";
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

// models
import { SchoolData } from 'src/app/models/schoolUser';

@Component({
  selector: 'app-profile-school-settings',
  templateUrl: './profile-school-settings.component.html',
  styleUrls: ['./profile-school-settings.component.scss'],
  standalone: false
})
export class ProfileSchoolSettingsComponent implements OnInit {
  editType: any;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.ngxService.stop();

  }
  editSettings(editType: any) {
    if (editType == 'school-profile') {
      this._router.navigate(['/school/school-profile'], { queryParams: { editType: 'school-profile' } });
      return;
    }
    this._router.navigate(['/school/edit-my-settings', editType]);
  }
}
