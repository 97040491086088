import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { TeacherProfilePopupComponent } from '../../teacher-profile-popup/teacher-profile-popup.component';
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
import { SendMailComponent } from '../../modals/send-mail/send-mail.component';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../services/api/message.service';
import { PaymentProcessModalComponent } from '../../modals/payment-process-modal/payment-process-modal.component';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
  standalone: false
})
export class JobDetailsComponent implements OnInit {
  currentPage: number = 1;
  data: any;
  job_id: any;
  allApplicant: any;
  hiredApplicant: any;
  rejectedApplicant: any;
  shortlistApplicant: any;
  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  schoolTimings = [];
  consumerType = [];
  teacher: Array<any> = [];
  public schoolProps!: SchoolData;
  hideButton: boolean = true;
  consumerRoleType: any = [];
  doubleClickDisabled: boolean = false
  selectAllAppCheck: boolean = false;
  selectAllHiredAppCheck: boolean = false;
  selectAllShortAppCheck: boolean = false;
  selectAllRejectAppCheck: boolean = false;

  waitForProfileModal: boolean = false;
  showLinkCopiedText: boolean = false;

  selectedOption: any
  allAppDetails: Array<any> = [];
  hiredAppDetails: Array<any> = [];
  shortAppDetails: Array<any> = [];
  rejectedAppDetails: Array<any> = [];

  @ViewChild('actionModals') actionModals: any;
  @ViewChild('removeJobModal') removeJobModal: any;
  @ViewChild('updateStatusModal') updateStatusModal: any;

  allAppJobDetailsFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    filter_distance: ['10000000'],
    job_id: ['']
  });

  jobUpdateForm: FormGroup = this.fb.group({
    hosted_id: [''],
  });

  addTeacherForm: FormGroup = this.fb.group({
    teacher_id: [''],
  });

  removeJobForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    filter_distance: ['10000000'],
    job_id: ['']
  });

  hiredAppJobDetailsFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    filter_distance: ['10000000'],
    job_id: ['']
  });
  shortAppJobDetailsFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    filter_distance: ['10000000'],
    job_id: ['']
  });
  rejectedAppJobDetailsFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    filter_distance: ['10000000'],
    job_id: ['']
  });
  jobDetails: any;
  jobDescription: any;
  teacherName: any;
  actionType: any;
  public actionForm: any
  applicant: any;
  availTeacher: any;
  showAllApplicantFilter: boolean = false;
  showHiredApplicantFilter: boolean = false;
  showShortlistApplicantFilter: boolean = false;
  showRejectedApplicantFilter: boolean = false;
  others_job: any;
  showFilters: boolean = false;
  payment_status: any;
  copyLink: any;
  request: any;
  siteUrl: any;
  id: any;
  is_free_boost: any;
  is_free_boostCount: any;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private mixpanelService: MixpanelService,
    private titleService: Title) { }

  ngOnInit(): void {
    this.siteUrl = environment.apiUrl
    this.getFiltersData();
    this.route.queryParams.subscribe(params => {
      this.job_id = params['job_id'];
    });
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
    
    this.jobUpdateForm.patchValue({
      hosted_id: this.id,
    });
    this.route.queryParams.subscribe(params => {
      this.payment_status = params['state'];
    });
    this.copyLink = environment.apiUrl + 'jobs/view-job/' + this.job_id
    this.titleService.setTitle(" ClassCover Jobs | "+this.job_id)
    if (this.payment_status == 'succeeded' || this.payment_status == 'requested') {
      this._router.navigate(['/school/job-details'], { queryParams: { job_id: this.job_id } });
      setTimeout(() => this.returnPaymentAPI(this.jobUpdateForm.controls['hosted_id'].value), 1000)
      setTimeout(() => this.ngxService.stop(), 1000)
    } else {
      this.updateJobPage()
    }
  }

  returnPaymentAPI(id:any) {
    // this.bsModalRef.hide()
    // this.ngxService.start()
    this.bsModalRef = this.modalService.show(PaymentProcessModalComponent, { class: 'modal-sm',backdrop: 'static' });
    this.bookingService.callBackPaymentFunction(id).subscribe({
      next: (res: any) => {
        this.bsModalRef.hide()
        if(res.status){
          this.toastr.success(res.message, '', { timeOut: 10000 })
          this.mixpanelService.postJobAction(res.is_remote_job,res.is_boost_job,res.status);
          console.log(res)         
          this.updateJobPage()
        }else{
          this.toastr.error(res.message, '', { timeOut: 10000 })
          this.mixpanelService.postJobAction(res.is_remote_job,res.is_boost_job, res.status);
          console.log(res)
          this.updateJobPage()
        }
      },
      error: (err: any) => {
        this.bsModalRef.hide()
        this.ngxService.stop();
        this.toastr.error(err.message, '', { timeOut: 10000 })
        console.log(err);
        this.updateJobPage()
      }
    })
  }
  updateJobPage() {
    this.ngxService.start();
    this.route.queryParams.subscribe(params => {
      this.job_id = params['job_id'];
    });

    this.bookingService.getJobDetails(this.job_id).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.allApplicant = res.data.all_applicant
          this.hiredApplicant = res.data.hired
          this.rejectedApplicant = res.data.rejected
          this.shortlistApplicant = res.data.shortlist
          this.jobDetails = res.data.job_details
          this.is_free_boost = res.data.free_boost
          this.is_free_boostCount = res.data.free_boost_count
          this.others_job = res.data.others_job
          this.jobDescription = res.data.job_details.job_description
          this.titleService.setTitle(" ClassCover Jobs | "+this.jobDetails.job_title)

          this.setAllTrue()
          if (this.allApplicant.length > 0) {
            this.showAllApplicantFilter = true
          } else {
            this.showAllApplicantFilter = false
          }
          if (this.hiredApplicant.length > 0) {
            this.showHiredApplicantFilter = true
          } else {
            this.showHiredApplicantFilter = false
          }
          if (this.shortlistApplicant.length > 0) {
            this.showShortlistApplicantFilter = true
          } else {
            this.showShortlistApplicantFilter = false
          }
          if (this.rejectedApplicant.length > 0) {
            this.showRejectedApplicantFilter = true
          } else {
            this.showRejectedApplicantFilter = false
          }
          this.formInit();
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  formInit() {
    this.actionForm = this.fb.group({
      optional_message: [''],
      status: [''],
      application_id: ['']
    });
  }
  // onSelectionChange() {
  //   this.actionForm.patchValue({
  //     status: this.selectedOption
  //   })
  // }
  sendMessage(teacher: any) {
    const initialState = {
      teacher: teacher,
      from: 'job_details'
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }
  // send email modal
  sendMail() {
    if (this.activeTab == 'all') {
      const initialState = {
        teacher: Array.prototype.map.call(this.allAppDetails, function (item) { return item.teacher.id; }).join(","),
        from: 'job_details_bulk',
        all_checkbox: true,
        checked_status: this.allAppDetails,
        job_id: this.job_id,
        job_active_tab: this.activeTab
      };
      this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    } else if (this.activeTab == 'hired') {
      const initialState = {
        teacher: Array.prototype.map.call(this.hiredAppDetails, function (item) { return item.teacher.id; }).join(","),
        from: 'job_details_bulk',
        all_checkbox: true,
        checked_status: this.hiredAppDetails,
        job_id: this.job_id,
        job_active_tab: this.activeTab
      };
      this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    } else if (this.activeTab == 'shortlisted') {
      const initialState = {
        teacher: Array.prototype.map.call(this.shortAppDetails, function (item) { return item.teacher.id; }).join(","),
        from: 'job_details_bulk',
        all_checkbox: true,
        checked_status: this.shortAppDetails,
        job_id: this.job_id,
        job_active_tab: this.activeTab
      };
      this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    } else if (this.activeTab == 'rejected') {
      const initialState = {
        teacher: Array.prototype.map.call(this.rejectedAppDetails, function (item) { return item.teacher.id; }).join(","),
        from: 'job_details_bulk',
        all_checkbox: true,
        checked_status: this.rejectedAppDetails,
        job_id: this.job_id,
        job_active_tab: this.activeTab
      };
      this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    }

  }


  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('job_details').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.subjectsAndTags = res.data.tags;
        this.levelFilter = res.data.experience_tree;
        this.consumerType = res.data.consumer_type;
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  // Get filters data
  getFilterInputs() {
    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API
    this.bookingService.getJobFilterDetails(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.setAllTrue()
        if (this.activeTab == 'all') {
          this.allApplicant = res.data.all_applicant
          this.showAllApplicantFilter = true
          this.ngxService.stop();
        } else if (this.activeTab == 'hired') {
          this.showHiredApplicantFilter = true
          this.hiredApplicant = res.data.hired
          this.ngxService.stop();
        } else if (this.activeTab == 'rejected') {
          this.showRejectedApplicantFilter = true
          this.rejectedApplicant = res.data.rejected
          this.ngxService.stop();
        } else if (this.activeTab == 'shortlisted') {
          this.showShortlistApplicantFilter = true
          this.shortlistApplicant = res.data.shortlist
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
    // End of get filters data API
  }
  // End of get filters data

  getInputFormControlValues() {
    if (this.activeTab == 'all') { //Filter for all
      this.allAppJobDetailsFilterForm.patchValue({
        filterType: 'all_applicant',
        job_id: this.job_id
      });
      return this.allAppJobDetailsFilterForm.value;
    } else if (this.activeTab == 'hired') {//Filter for hired
      this.hiredAppJobDetailsFilterForm.patchValue({
        filterType: 'hired',
        job_id: this.job_id
      });
      return this.hiredAppJobDetailsFilterForm.value;
    } else if (this.activeTab == 'shortlisted') {//Filter for rejected
      this.shortAppJobDetailsFilterForm.patchValue({
        filterType: 'shortlisted',
        job_id: this.job_id
      });
      return this.shortAppJobDetailsFilterForm.value;
    } else if (this.activeTab == 'rejected') {//Filter for shortlisted
      this.rejectedAppJobDetailsFilterForm.patchValue({
        filterType: 'rejected',
        job_id: this.job_id
      });
      return this.rejectedAppJobDetailsFilterForm.value;
    }
  }

  selectAllApp(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.allAppDetails = [];
      this.allApplicant.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.allAppDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.allAppDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.allAppDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.allAppDetails = this.allAppDetails.filter(x => x != type);
      }
    }
    this.selectAllAppCheck = this.allApplicant.length == this.allAppDetails.length ? true : false;
    if (this.allAppDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }

  selectAllHired(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.hiredAppDetails = [];
      this.hiredApplicant.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.hiredAppDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.hiredAppDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.hiredAppDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.hiredAppDetails = this.hiredAppDetails.filter(x => x != type);
      }
    }
    this.selectAllHiredAppCheck = this.hiredApplicant.length == this.hiredAppDetails.length ? true : false;
    if (this.hiredAppDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }

  selectAllRejected(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.rejectedAppDetails = [];
      this.rejectedApplicant.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.rejectedAppDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.rejectedAppDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.rejectedAppDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.rejectedAppDetails = this.rejectedAppDetails.filter(x => x != type);
      }
    }
    this.selectAllRejectAppCheck = this.rejectedApplicant.length == this.rejectedAppDetails.length ? true : false;
    if (this.rejectedAppDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }
  selectAllShort(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.shortAppDetails = [];
      this.shortlistApplicant.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.shortAppDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.shortAppDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.shortAppDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.shortAppDetails = this.shortAppDetails.filter(x => x != type);
      }
    }
    this.selectAllShortAppCheck = this.shortlistApplicant.length == this.shortAppDetails.length ? true : false;
    if (this.shortAppDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }
  openChangeStatusModal(teacher: any, actionType: any) {
    if (actionType == 'Hired' || actionType == 'Shortlisted' || actionType == 'Rejected') {
      this.applicant = teacher
      this.teacherName = teacher.teacher.user.full_name
      this.actionType = actionType;
      this.bsModalRef = this.modalService.show(this.actionModals, { class: 'modal-md', backdrop: 'static' });
    }
  }
  openUpdateStatusModal() {
    if (this.activeTab == 'shortlisted'){
      this.actionForm.patchValue({
        status: 'S'
      })
    }
    if (this.activeTab == 'rejected'){
      this.actionForm.patchValue({
        status: 'R'
      })
    }
    if (this.activeTab == 'hired'){
      this.actionForm.patchValue({
        status: 'H'
      })
    }
    this.bsModalRef = this.modalService.show(this.updateStatusModal, { class: 'modal-md', backdrop: 'static' });
  }

  setAllTrue() {
    this.selectAllAppCheck = false
    this.selectAllHiredAppCheck = false
    this.selectAllShortAppCheck = false
    this.selectAllRejectAppCheck = false
    this.hideButton = true
    this.allAppDetails = []
    this.hiredAppDetails = []
    this.rejectedAppDetails = []
    this.shortAppDetails = []
    this.shortlistApplicant.forEach((x: any) => {
      x.isChecked = false;
    });
    this.allApplicant.forEach((x: any) => {
      x.isChecked = false;
    });
    this.hiredApplicant.forEach((x: any) => {
      x.isChecked = false;
    });
    this.rejectedApplicant.forEach((x: any) => {
      x.isChecked = false;
    });
    this.actionType = ''
    window.scroll(0, 0);
  }
  actions(applicant: any, action: any) {
    if (this.actionForm.controls['status'].value == '' && applicant == '') {
      this.toastr.warning("No status selected")
      return;
    }
    this.ngxService.start();
    if (action == "selectAll" && this.activeTab == "all") {
      this.actionForm.patchValue({
        application_id: Array.prototype.map.call(this.allAppDetails, function (item) { return item.id; }).join(","),
      })
    } else if (action == "selectAll" && this.activeTab == "hired") {
      this.actionForm.patchValue({
        application_id: Array.prototype.map.call(this.hiredAppDetails, function (item) { return item.id; }).join(","),
      })
    } else if (action == "selectAll" && this.activeTab == "shortlisted") {
      this.actionForm.patchValue({
        application_id: Array.prototype.map.call(this.shortAppDetails, function (item) { return item.id; }).join(","),
      })
    } else if (action == "selectAll" && this.activeTab == "rejected") {
      this.actionForm.patchValue({
        application_id: Array.prototype.map.call(this.rejectedAppDetails, function (item) { return item.id; }).join(","),
      })
    } else {
      this.actionForm.patchValue({
        application_id: applicant.id.toString()
      })
    }
    if(this.actionForm.controls['status'].value == ''){
      this.mixpanelService.applicantStatusChange(this.actionType);
    }else{
      var status = ''
      if(this.actionForm.controls['status'].value == 'H'){
        status = "Hired"
      }
      if(this.actionForm.controls['status'].value == 'S'){
        status = "Shortlisted"
      }
      if(this.actionForm.controls['status'].value == 'R'){
        status = "Rejected"
      }
      this.mixpanelService.applicantStatusChangeonBulk(status , "Applicant Status change");
    }

    this.bookingService.jobDetailsAction(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        this.toastr.success(res.message)
        this.setAllTrue()
        this.bookingService.getJobDetails(this.job_id).subscribe({
          next: (res: any) => {
            this.allApplicant = res.data.all_applicant
            this.hiredApplicant = res.data.hired
            this.rejectedApplicant = res.data.rejected
            this.shortlistApplicant = res.data.shortlist
            if (this.allApplicant.length > 0) {
              this.showAllApplicantFilter = true
            } else {
              this.showAllApplicantFilter = false
            }
            if (this.hiredApplicant.length > 0) {
              this.showHiredApplicantFilter = true
            } else {
              this.showHiredApplicantFilter = false
            }
            if (this.shortlistApplicant.length > 0) {
              this.showShortlistApplicantFilter = true
            } else {
              this.showShortlistApplicantFilter = false
            }
            if (this.rejectedApplicant.length > 0) {
              this.showRejectedApplicantFilter = true
            } else {
              this.showRejectedApplicantFilter = false
            }
            this.formInit();
            this.ngxService.stop();
          },
          error: (err: any) => {
            console.log(err);
            this.ngxService.stop();
          }
        })

        this.bsModalRef.hide();
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getjobUpdateFormValue() {
    return this.jobUpdateForm.value
  }
  getFormControlValues() {
    if (this.actionType == 'Hired') {
      this.actionForm.patchValue({
        status: 'H'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'Shortlisted') {
      this.actionForm.patchValue({
        status: 'S'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'Rejected') {
      this.actionForm.patchValue({
        status: 'R'
      })

      return this.actionForm.value;
    } else {
      return this.actionForm.value;
    }
  }

  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }


  activeTab = 'all';

  all(activeTab: string) {
    this.activeTab = activeTab;
    this.setAllTrue()
  }

  hired(activeTab: string) {
    this.activeTab = activeTab;
    this.setAllTrue()
  }
  shortlisted(activeTab: string) {
    this.activeTab = activeTab;
    this.setAllTrue()
  }
  rejected(activeTab: string) {
    this.activeTab = activeTab;
    this.setAllTrue()
  }

  getTeacherAvailabilityModal(event: any, teacher_id: any) {
    event.preventDefault()
    this.doubleClickDisabled = true
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          const initialState = {
            teacher: res.data
          };
          this.doubleClickDisabled = false
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.doubleClickDisabled = false
        }
      },
      error: (err: any) => {
        this.doubleClickDisabled = false
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  openCloseJobModal() {
    this.bsModalRef = this.modalService.show(this.removeJobModal, { class: 'modal-md', backdrop: 'static' });
  }
  closeJob(id: any) {
    this.ngxService.start();
    this.bookingService.cancelJobsAPi(id).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.updateJobPage()
          this.toastr.success(res.message);
          this.ngxService.stop();
        } else {
          alert("error! " + res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err);
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  editJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id, type: 'edit' } });
  }
  editDraftJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id, type: 'draftEdit' } });
  }

  reOpenJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id, type: 're_open' } });
  }
  redirectToHeadhunter(id: any) {
    this._router.navigate(['/school/headhunter/'], { queryParams: { jobId: id } });
  }
  openPublicJobs(id: any) {
    window.open(environment.apiUrl + "jobs/view-job/" + id, "_blank");
  }
  copyUrlLink(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showLinkCopiedText = true
  }

  getTeacherIdFormValue() {
    return this.addTeacherForm.value
  }
  addTeacherToList(teacher_id: any) {
    this.ngxService.start();
    this.addTeacherForm.patchValue({
      teacher_id: teacher_id
    })
    this.bookingService.addTeacherToList(this.getTeacherIdFormValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.updateJobPage()
          this.toastr.success(res.message);
          this.ngxService.stop();
        } else {
          alert("error! " + res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err);
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  sub!: Subscription
  ngAfterContentInit() {
    this.sub = this.sharedService.setValData.subscribe(
      data => {
        if (data == 'triggerSetAllFunc') {
          this.setAllTrue()
        }
      }
    )
  }
  closeStatusModal() {
    this.actionForm.patchValue({
      status: [''],
      optional_message: [''],
    })
    this.bsModalRef.hide()
  }
}


