import { ChangeDetectorRef, Component, OnInit, ViewChildren, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { map, take } from "rxjs/operators";

// Libs
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

import { ConfirmationModalComponent } from '../../../layout/confirmation-modal/confirmation-modal.component';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { BookingConfrimModalComponent } from '../modals/booking-confrim-modal/booking-confrim-modal.component';
import { ValidatedDictComponent } from '../validated-dict/validated-dict.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AddNotesComponent } from '../modals/add-notes/add-notes.component';
import { ToastrService } from 'ngx-toastr';
// import { PreBookingModalsComponent } from '../modals/pre-booking-modals/pre-booking-modals.component';
import timepicker from 'src/app/pages/producer/timepicker';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-pre-confirm-booking',
  templateUrl: './pre-confirm-booking.component.html',
  styleUrls: ['./pre-confirm-booking.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class PreConfirmBookingComponent implements OnInit {
  currentPage: number = 1;
  bsRangeValue: Date[] | undefined;
  dateCustomClasses: DatepickerDateCustomClasses[];
  bsInlineValue = new Date();
  @ViewChild('customPickerDiv', { static: true, read: BsModalRef }) customPickerDiv?: ElementRef
  @ViewChild('customDatepickerExport') customDatepickerExport?: ElementRef
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  teachersList: Observable<any> = this.obsArray.asObservable();
  @ViewChild('preConfrimModal') preConfrimModal: any;
  @ViewChild('preConfrimBookedModal') preConfrimBookedModal: any;
  @ViewChild('reviewOccurenceModal') reviewOccurenceModal: any;
  @ViewChild('sendPreconfirmBooking') sendPreconfirmBooking: any;
  @ViewChild('flexiBookingModal') flexiBookingModal: any;
  @ViewChild('BookingRequestModal') BookingRequestModal: any;

  totalArrayCount: any;
  teacherLengthLimit: any;
  CloseBookingAfter = [
    {
      'title': "5 minutes",
      'value': "300"
    }, {
      'title': 2,
      'value': 2
    }, {
      'title': 3,
      'value': 3
    }, {
      'title': 4,
      'value': 4
    },
  ]
  preConfrimFilterForm: FormGroup = this.fb.group({
    date: [''],
    distance: ['10000000'],
    rating: [''],
    filter_consumer_type: [''],
    filter_experience_items: [''],
    tags: [''],
    name: [''],
    filter_isactive: [''],
    page: [''],
    booking_smartgroup: [''],
    filter_is_certified: [false],
    order_by: [''],
    providerId: ['']
  });




  date = new Date();
  isOpen = false;

  // Filters
  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  consumerType = [];
  consumerRoleType: any = [];
  selectedSubjects = [];
  schoolTimings: any;
  datesHeaderArr: any = [];
  title?: string;
  closeBtnName?: string;
  teacher: any;
  minDate: Date;
  maxDate: Date;
  tagsAndSubjects: boolean = false;
  HideOnOccurenceWeekly: boolean = true
  HideOnOccurenceTimeWeek: boolean = true
  HideOnOccurenceMonthWeekly: boolean = false
  sendTeacher: any
  datesArray: any;
  modalData: any;
  bsValue: any;
  selectedModalDate: any;
  selectedModalStatus: any;
  selectedModalDay: any;
  selectedModalIntervals: any;
  datepipe: any;
  dates_selected: any;
  showOccurenceReview: boolean = true;
  showOccurenceModalTab: boolean = false;
  bsOneDayValue: any;
  bsRepeatStartValue: any
  bsRepeatEndValue: any;
  OccuringData: any;
  teacherId: any;
  rowDate: any;
  public preConfrimBookingForm: any;
  showActiveTab: boolean = false;
  inlineEndDate: any;
  inviteDetails: boolean = true;
  showUpdateBtn: boolean = true;
  submitted: boolean = false
  availTeacher: any;
  showRemoveText: boolean = false;
  fileName: any;
  preConfirmList: any = [];
  showSendBookingBtn: boolean = true;
  // nextFortnight: Date;
  bsModalRef1!: BsModalRef;
  bsModalRef2!: BsModalRef;
  booking_id: any;
  showDelay: boolean = false
  makeMadantory: boolean = false;
  hidetimeRange: boolean = false;
  smartGroups: any;
  bookingRoleType: any;
  selectedItem: any = [];
  is_showCusCal: boolean = false;
  timepicker: typeof timepicker | undefined;
  hours_d: any;
  minutes_d: any;
  custom_text: any;
  monthsShown: any;
  showTopBtn: boolean = false;
  providerId: any = [];

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private _router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService
  ) {
    {
      this.renderer.listen('window', 'click', (e: Event) => {
        // console.log(this.is_showCusCal)
        // console.log(this.customPickerDiv)
        // console.log(this.customDatepickerExport)
        // if (e.target !== this.customPickerDiv?.nativeElement && e.target !== this.customDatepickerExport?.nativeElement) {
        //   console.log("hehe")
        //   this.is_showCusCal = false;
        // }

      });

      this.minDate = new Date();
      this.maxDate = new Date();
      this.minDate.setFullYear(this.minDate.getFullYear());
      this.maxDate.setDate(this.maxDate.getFullYear());
      this.bsValue = moment(new Date()).format('DD/MM/YYYY');
      this.bsOneDayValue = moment(new Date()).format('DD/MM/YYYY');
      this.inlineEndDate = moment(new Date()).format('DD/MM/YYYY');

      this.dateCustomClasses = [
        { date: new Date(), classes: ['date-bg'] }
      ];
    }
  }

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  ngOnInit(): void {
    this.formInit();
    this.getFiltersData();
    this.ngxService.start();
    this.bookingService.getPreconfirmedList(this.getFilterFormControlValues('')).subscribe({
      next: (res: any) => {
        console.log(res);
        this.teacherLengthLimit = res.data.total_count;
        this.obsArray.next(res.data.data);
        this.preConfirmList = res.data.data
        this.monthsShown = res.data.date_month
        this.datesHeaderArr = res.data.dates
        if (this.preConfirmList.length > 0) {
          this.inlineEndDate = res.data.data[0].availability[0].intervals[0].date;
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })

    this.timepicker = timepicker
    this.hours_d = this.timepicker[0].hours
    this.minutes_d = this.timepicker[0].minutes
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }


  setPrevNxtData(res: any, apiCall: boolean, sendDate: number) {
    this.inlineEndDate = res;
    // Set Next Prev Api Call
    if (apiCall) {
      this.ngxService.start();
      this.preConfirmList.forEach((x: any) => {
        this.providerId.push(x.teacher.id);
      });
      if (sendDate == 1) {
        let nxtPrevDate = this.addDays(this.inlineEndDate)
        this.preConfrimFilterForm.patchValue({
          date: nxtPrevDate,
          providerId: this.providerId
        });
      } else if (sendDate == 2) {
        let nxtPrevDate = this.substractDays(this.inlineEndDate)
        this.preConfrimFilterForm.patchValue({
          date: nxtPrevDate,
          providerId: this.providerId
        });
      }
      // let apiDate = sendDate == 1 ? this.inlineEndDate : sendDate == 2 ? this.addDays(this.inlineEndDate) : this.substractDays(this.inlineEndDate);

      // let nxtPrevDate = { 'date': apiDate };
      // console.log(nxtPrevDate);

      this.bookingService.nextPrevFortAPI(this.getFilterFormControlValues('nextPrew')).subscribe({
        next: (res: any) => {
          // this.teacherLengthLimit = res.data.total_count;
          // this.preConfirmList = res.data.data
          this.monthsShown = res.date_month
          // this.obsArray.next(res.data.data);
          // this.datesHeaderArr = res.data.dates;

          this.updateItemsFromApi(res)
          // var index = this.preConfirmList.map((x: any) => {
          //   return x.teacher.id.toString();
          // }).indexOf(res.data.id).toString();
          // this.preConfirmList.availability[index] = res.data.availability;
          this.inlineEndDate = res.data[0].availability[0].date_format
          this.bsValue = this.formatSameDate(this.inlineEndDate);
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
  }

  updateItemsFromApi(apiResponse: any) {
    console.log(apiResponse)


    this.preConfirmList = this.preConfirmList.map((node: any) => {
      // debugger
      const oldInfo = apiResponse.data.find((item: any) => item.id === node.teacher.id);
      console.log(oldInfo)
      if (oldInfo) {
        return { ...node, availability: oldInfo.availability }
      } else {
        return { ...node };
      }
    });
    this.obsArray.next(this.preConfirmList);
  }

  addDays(date: any) {
    var currentDate = date;
    var momentObj = moment(currentDate, 'DD/MM/YYYY');
    return momentObj.add(14, 'd').format('DD/MM/YYYY')
  }

  substractDays(date: any) {
    var currentDate = date;
    var momentObj = moment(currentDate, 'DD/MM/YYYY');
    return momentObj.subtract(14, 'd').format('DD/MM/YYYY')
  }

  nextFortnight() {
    this.setPrevNxtData(this.inlineEndDate, true, 1);
  }

  prevFortnight() {
    this.setPrevNxtData(this.inlineEndDate, true, 2);
  }

  public setArrData(data: any): void {
    console.log(data);
  }
  getTeacherAvailabilityModal(teacher_id: any) {
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
        }

      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  formInit() {
    this.preConfrimBookingForm = this.fb.group({
      booking_dates: [''],
      start_date: [''],
      end_date: [''],
      consumer_id: [''],
      booking_classcovered: ['', [Validators.required, this.noWhitespaceValidator]],
      booking_teachercovered: ['', [Validators.required, this.noWhitespaceValidator]],
      booking_absencereason: ['', [Validators.required, this.noWhitespaceValidator]],
      booking_payrollfield1: [''],
      booking_payrollfield2: [''],
      booking_payrollfield3: [''],
      start_hrs: ['08'],
      start_min: ['00'],
      start_time: [''],
      end_time: [''],
      end_hrs: ['15'],
      end_min: ['30'],
      booking_notes: [''],
      booking_leaveapproved: ['1'],
      timerange: ['full_day'],
      occurrence: ['weekly'],
      times_per_week_month: ['1'],
      week_repeater: ['1'],
      recurrent_end: ['1'],
      occurrence_number: ['1'],
      booking_type: ['preconfirmed'],
      booking_attachement_link: [''],
      month_repeater: ['1'],
      time_to_live: ['300'],
      booking_attachment: [''],
      date: [''],
      readTerms: [''],
      flexi_booking: [''],
      disable_preconfirm: [''],
      schedule_booking: [false],
      schudule_time_hours: ['05'],
      schudule_time_mins: ['00'],
      filter_is: [''],
      schedule_day: ['today'],
      booking_role_type: ['1'],
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  getTeacherLength() {
    this.teachersList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }

  enableDisableSendBtn(event: any) {
    if (event.target.checked == true) {
      this.showSendBookingBtn = false
    } else {
      this.showSendBookingBtn = true
    }
  }
  changeMandatory(make: any) {
    if (make == 'mandaotry') {
      this.filtersArray.classbeingcovered_required = true;
      this.filtersArray.teachercovered_required = true;
      this.submitted = true;
    } else if (make == 'not_mandaotry') {
      this.filtersArray.classbeingcovered_required = false;
      this.filtersArray.teachercovered_required = false;
    }
  }
  closeBookingModal() {
    this.bsModalRef2.hide()
    this.closeModal();
    this.showDelay = false;
    this.showSendBookingBtn = true;
    this.is_showCusCal = false;
  }
  checkFlexyFilled() {
    if (this.preConfrimBookingForm.controls['flexi_booking'].value == '') {
      this.toastr.warning("Please select one option to continue");
      return;
    }
    if (this.preConfrimBookingForm.controls['flexi_booking'].value == '0') {
      this.bsModalRef1.hide();
      this.submitted = true;
    } else {
      this.bsModalRef2 = this.modalService.show(this.sendPreconfirmBooking, { class: 'modal-md', backdrop: 'static' });
      this.bsModalRef1.hide();
      this.bsModalRef.hide();
    }
  }
  checkFlexi() {
    if (this.makeMadantory == true || (this.filtersArray.absencereason_required == true && this.preConfrimBookingForm.controls['booking_absencereason'].value == '')) {
      this.submitted = true;
      if (this.preConfrimBookingForm.invalid) {
        return;
      }
    }
    if ((this.preConfrimBookingForm.controls['booking_teachercovered'].value == null || this.preConfrimBookingForm.controls['booking_teachercovered'].value == '') && this.preConfrimBookingForm.controls['booking_classcovered'].value == '') {
      this.bsModalRef1 = this.modalService.show(this.flexiBookingModal, { class: 'modal-md', backdrop: 'static' });
    } else {
      this.bsModalRef.hide();
      this.bsModalRef2 = this.modalService.show(this.sendPreconfirmBooking, { class: 'modal-md', backdrop: 'static' });
    }
  }
  showDelayTime(event: any) {
    this.showDelay = !this.showDelay;
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  scrollOnPervNext() {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    // console.log(this.teacherLengthLimit);
    // console.log(this.totalArrayCount)
    let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 30);
    // console.log(this.currentPage);
    // console.log(activePage);
    this.currentPage = activePage + 1;
    if (this.preConfrimFilterForm.controls['page'].value != this.currentPage) {
      this.ngxService.start();
      forkJoin([this.teachersList.pipe(take(1)), this.bookingService.getPreconfirmedList(this.getFilterFormControlValues('nextPrew'))]).subscribe((data: any) => {
        this.teacherLengthLimit = data[1].data.total_count;
        // console.log(data[0]);
        const newArr = [...data[0], ...data[1].data.data];
        this.obsArray.next(newArr);
        this.monthsShown = data[1].data.date_month

        this.preConfirmList = newArr
        this.datesHeaderArr = data[1].data.data.dates;
        this.inlineEndDate = data[1].data.data[0].availability[0].intervals[0].date;
        this.bsValue = this.formatSameDate(this.inlineEndDate);
        this.ngxService.stop();
      });
    }
  };
  // Scroll to getData
  scroll = (event: any): void => {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 30);
      this.currentPage = activePage + 1;
      if (this.preConfrimFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.teachersList.pipe(take(1)), this.bookingService.getPreconfirmedList(this.getFilterFormControlValues(''))]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.data];
          this.obsArray.next(newArr);
          this.preConfirmList = newArr
          this.monthsShown = data[1].data.date_month
          this.ngxService.stop();
        });
      }
    }
    if (window.scrollY < 100) {
      this.showTopBtn = false
    }
    if (window.scrollY > 1200) {
      this.showTopBtn = true
    }


  };
  Paginator(totlallength: any, page: any, per_page: any) {

    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  showtags() {
    this.tagsAndSubjects = !this.tagsAndSubjects;
  }
  getFiltersData() {
    this.commonService.schoolChoices('booking').subscribe({
      next: (res: any) => {
        this.filtersArray = res.choices;
        this.schoolTimings = res.choices.school_timings;
        this.subjectsAndTags = res.choices.tags;
        this.levelFilter = res.choices.experience_tree;
        // this.consumerType = res.choices.consumer_type;
        this.smartGroups = res.choices.smart_group;
        this.bookingRoleType = res.choices.booking_role_type;
        this.custom_text = res.choices.certified_text;
        let newArr: any[] = []
        this.consumerType.forEach((type: any) => {
          type.sub_type.forEach((subtype: any) => {
            newArr.push({ id: subtype.id, title: subtype.title, type: type.title });
          });
        });
        this.consumerRoleType = newArr

        if (this.filtersArray.teachercovered_required == true || this.filtersArray.classbeingcovered_required == true) {
          this.makeMadantory = true
        } else {
          this.makeMadantory = false
        }
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  getFilterInputs() {
    this.currentPage = 1;
    this.ngxService.start();

    this.bookingService.getPreconfrimFilterList(this.getFilterFormControlValues('')).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.preConfirmList = res.data.data
        this.monthsShown = res.data.date_month
        this.obsArray.next(res.data.data);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  getFilterFormControlValues(nextPrew: any) {
    // debugger;
    // let date = this.bsValue;
    // if (moment(date, 'DD/MM/YYYY', true).isValid() == false) {
    //   date = moment(date).format('DD/MM/YYYY')
    // }
    if (nextPrew == 'nextPrew') {
      if (moment(this.bsValue, 'mm/dd/YYYY', true).isValid()) {
        this.inlineEndDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
        this.preConfrimFilterForm.patchValue({
          date: this.inlineEndDate,
          page: this.currentPage
        });
      } else {
        this.inlineEndDate = this.bsValue
        this.preConfrimFilterForm.patchValue({
          page: this.currentPage
        });
      }
    } else {
      if (moment(this.bsValue, 'mm/dd/YYYY', true).isValid()) {
        this.inlineEndDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
        this.preConfrimFilterForm.patchValue({
          date: this.inlineEndDate,
          page: this.currentPage
        });
      } else {
        this.inlineEndDate = this.bsValue
        this.preConfrimFilterForm.patchValue({
          date: this.inlineEndDate,
          page: this.currentPage
        });
      }
    }

    // console.log(date);
    // console.log(this.formatSameDate(date));
    // this.inlineEndDate = date;
    // alert(this.inlineEndDate)
    // let startDate = new DatePipe('en-US').transform(date, 'dd/MM/yyyy');   
    // this.setPrevNxtData(moment(this.bsValue).format('DD/MM/YYYY'), false, 0);
    // this.preConfrimFilterForm.patchValue({
    //   page: this.currentPage,
    //   date: this.inlineEndDate
    // });
    return this.preConfrimFilterForm.value;
  }

  getFormControlValues(flag: any) {
    if (flag == 'repeating') {
      this.currentPage
      this.preConfrimBookingForm.patchValue({
        page: this.currentPage,
        booking_dates: Array.prototype.map.call(this.OccuringData, function (list) { return moment(list.date).format('DD/MM/YYYY') }).join("-"),
      });
    } else if (flag == 'sendOccurance') {
      let date = this.preConfrimBookingForm.controls['booking_dates'].value;
      let startDate = this.preConfrimBookingForm.controls['start_date'].value;
      let endDate = this.preConfrimBookingForm.controls['end_date'].value;
      // if (moment(date, 'DD/MM/YYYY', true).isValid()) {
      //   date = moment(date).format('DD/MM/YYYY')
      // }
      // if (moment(startDate, 'MM/DD/YYYY', true).isValid()) {
      //   startDate = moment(startDate).format('DD/MM/YYYY')
      // }
      // if (moment(endDate, 'MM/DD/YYYY', true).isValid()) {
      //   endDate = moment(endDate).format('DD/MM/YYYY')
      // }
      this.preConfrimBookingForm.patchValue({
        page: this.currentPage,
        booking_dates: date,
        start_date: startDate,
        end_date: endDate
      });
    } else {
      let date = this.preConfrimBookingForm.controls['booking_dates'].value;
      // console.log(date)
      // let dates =this.datepipe.transform(date, 'DD/MM/YYYY');

      // if (moment(date, 'DD/MM/YYYY', true).isValid() == false) {
      //   console.log(dates)
      //   dates = moment(date).format('DD/MM/YYYY')
      // }
      // console.log(dates)

      this.preConfrimBookingForm.patchValue({
        page: this.currentPage,
        booking_dates: this.selectedItem
      });
    }

    return this.preConfrimBookingForm.value;
  }
  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  preConfrimBookingModal(teacher: any, bookingDatas: any, selectedDay: any) {
    this.modalData = teacher;
    this.selectedModalDay = selectedDay
    this.selectedModalIntervals = bookingDatas
    if (this.selectedModalDay == "Sun" || this.selectedModalDay == "Sat") {
      return;
    }
    if (this.selectedModalIntervals.status != "booked" && this.selectedModalIntervals.status != "booked_externally") {
      const consumer_id = teacher.teacher.id
      let selectedModalDate = this.formatSameDate(this.selectedModalIntervals.date);

      // let selectedModalDate = new DatePipe('en-US').transform(this.selectedModalIntervals.date, 'dd/MM/yyyy')
      this.dateCustomClasses = [];
      this.onChangeDate(selectedModalDate);
      this.preConfrimBookingForm.patchValue({
        page: this.currentPage,
        booking_dates: selectedModalDate,
        start_date: selectedModalDate,
        end_date: selectedModalDate,
        consumer_id: consumer_id
      });
      this.cdr.detectChanges();

      this.bsModalRef = this.modalService.show(this.preConfrimModal, { class: 'modal-lg', backdrop: 'static' });
    } else if (this.selectedModalIntervals.status == "booked") {
      const initialState = {
        bookingDatas: bookingDatas
      };
      this.bsModalRef = this.modalService.show(BookingConfrimModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
    }
  }

  formatSameDate(date: any) {
    var currentDate = date;
    var momentObj = moment(currentDate, 'DD/MM/YYYY');
    return momentObj.format('DD/MM/YYYY')
  }


  sendPreconfrimBooking(dayType: any, preconfirmedStatus: any, userName: any) {
    if (dayType == "oneDayBooking") {
      if ((this.filtersArray.absencereason_required == true && this.preConfrimBookingForm.controls['booking_absencereason'].value == '') || (this.filtersArray.teachercovered_required == true && this.preConfrimBookingForm.controls['booking_teachercovered'].value == '') || (this.filtersArray.classbeingcovered_required == true && this.preConfrimBookingForm.controls['booking_classcovered'].value == '')) {
        this.submitted = true;
        if (this.preConfrimBookingForm.invalid) {
          return;
        }
      }
      // if (this.makeMadantory == true || (this.filtersArray.absencereason_required == true && this.preConfrimBookingForm.controls['booking_absencereason'].value == '')) {
      //   this.submitted = true;
      //   if (this.preConfrimBookingForm.invalid) {
      //     return;
      //   }
      // }
      let start_hrs = this.preConfrimBookingForm.controls['start_hrs'].value
      let start_min = this.preConfrimBookingForm.controls['start_min'].value
      let end_hrs = this.preConfrimBookingForm.controls['end_hrs'].value
      let end_min = this.preConfrimBookingForm.controls['end_min'].value
      this.preConfrimBookingForm.patchValue({
        start_time: start_hrs + ':' + start_min,
        end_time: end_hrs + ':' + end_min,
      });
    }
    if (dayType == "bookingRequest") {
      let start_hrs = this.preConfrimBookingForm.controls['start_hrs'].value
      let start_min = this.preConfrimBookingForm.controls['start_min'].value
      let end_hrs = this.preConfrimBookingForm.controls['end_hrs'].value
      let end_min = this.preConfrimBookingForm.controls['end_min'].value

      this.preConfrimBookingForm.patchValue({
        start_time: start_hrs + ':' + start_min,
        end_time: end_hrs + ':' + end_min,
        disable_preconfirm: true,
        booking_type: 'booking_request'
      });
    }
    if (preconfirmedStatus == false) {
      this.teacher = userName
      return;
      // this.bsModalRef = this.modalService.show(this.sendPreconfirmBooking, { class: 'modal-md', backdrop: 'static' });
    } else {
      this.ngxService.start();
      let book_date = this.preConfrimBookingForm.controls['booking_dates'].value;
      if (moment(book_date, 'mm/dd/YYYY', true).isValid()) {
        let date = new DatePipe('en-US').transform(book_date, 'dd/MM/YYYY')
        this.preConfrimBookingForm.patchValue({
          booking_dates: date,
        });
      }
      this.preConfrimBookingForm.patchValue({
        date: this.inlineEndDate,
      });
      this.bookingService.sendBookingRequest(this.getFormControlValues(dayType)).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.modalService.hide()
            this.toastr.success(res.message);
            if (dayType == "bookingRequest") {
              this.booking_id = res.booking_id
              this._router.navigate(['/school/bookings-in-flight', this.booking_id])
            } else {
              // this.setPrevNxtData(this.inlineEndDate, true, 0);
              var index = this.preConfirmList.map((x: any) => {
                return x.teacher.id;
              }).indexOf(res.data[0].teacher.id);
              this.preConfirmList[index] = res.data[0];
              this.mixpanelService.preconfirmedBooking(dayType);

              // console.log(this.inlineEndDate);
              this.showOccurenceModalTab = false
              this.showOccurenceReview = true
              this.HideOnOccurenceWeekly = true
              this.HideOnOccurenceTimeWeek = true
              this.hidetimeRange = false
              this.HideOnOccurenceMonthWeekly = false
              // this.filtersArray.classbeingcovered_required = false;
              // this.filtersArray.teachercovered_required = false;
              this.submitted = false;
              this.preConfrimBookingForm.valid
              this.preConfrimBookingForm.reset();
              this.formInit();
              this.selected = [1]
              this.fileName = false
              this.preConfrimBookingForm.patchValue({
                week_repeater: this.selected,
              });
              this.ngxService.stop();
              return this.preConfrimBookingForm.value;
            }

          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          this.toastr.error(err.error.message);
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
  }



  reviewOccurrences(teacher: any) {

    let week_repeater = this.preConfrimBookingForm.controls['week_repeater'].value;
    let occurrence = this.preConfrimBookingForm.controls['occurrence'].value;

    if (week_repeater == '' && occurrence == 'weekly') {
      this.toastr.warning('Repeat on value should not be empty')
      return;
    }

    this.ngxService.start();
    let start_date = this.preConfrimBookingForm.controls['start_date'].value;
    let end_date = this.preConfrimBookingForm.controls['end_date'].value;
    // let latest_date =DatePipe.transform(this.preConfrimBookingForm.controls['booking_dates'].value, 'yyyy-MM-dd');
    if (moment(start_date, 'mm/dd/YYYY', true).isValid()) {
      let str_date = new DatePipe('en-US').transform(start_date, 'dd/MM/YYYY')
      this.preConfrimBookingForm.patchValue({
        start_date: str_date,
      });
    }
    if (moment(end_date, 'mm/dd/YYYY', true).isValid()) {
      let en_date = new DatePipe('en-US').transform(end_date, 'dd/MM/YYYY')
      this.preConfrimBookingForm.patchValue({
        end_date: en_date,
      });
    }
    this.bookingService.getReviewOccurrrence(this.getFormControlValues('sendOccurance')).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.teacher = teacher;
          this.OccuringData = res.data;
          let arr = []
          for (let i = 0; i < this.OccuringData.length; i++) {
            if (this.OccuringData[i]['is_booked'] == true) {
              arr.push(this.OccuringData[i])
            }
          }
          if (arr.length > 0) {
            this.showUpdateBtn = false
          } else {
            this.showUpdateBtn = true
          }
          this.showOccurenceReview = !this.showOccurenceReview;
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.error.message);
        this.ngxService.stop();
      }
    })
  }

  showOccurrences() {
    this.showOccurenceModalTab = true
    this.showOccurenceReview = !this.showOccurenceReview;
  }

  selected: any[] = [1];
  // check if the item are selected
  checked(isChecked: any) {
    if (this.selected.indexOf(isChecked) != -1) {
      return true;
    }
    return false;
  }
  onChange(checked: any, isChecked: any) {
    let checkedData = checked.target.checked;
    if (checkedData) {
      this.selected.push(isChecked);
      this.preConfrimBookingForm.patchValue({
        week_repeater: this.selected,
      });
    } else {
      this.selected.splice(this.selected.indexOf(isChecked), 1)
      this.preConfrimBookingForm.patchValue({
        week_repeater: this.selected,
      });
    }
    if (this.selected.length == 0) {
      this.preConfrimBookingForm.patchValue({
        week_repeater: "",
      });
    }

    return this.preConfrimBookingForm.value;
  }

  ocurrenceChange() {
    let showOccurenceType = this.preConfrimBookingForm.controls['occurrence'].value;
    if (showOccurenceType == 'daily') {
      this.HideOnOccurenceWeekly = false
      this.HideOnOccurenceTimeWeek = false
      this.HideOnOccurenceMonthWeekly = false
    } else if (showOccurenceType == 'weekly') {
      this.HideOnOccurenceWeekly = true
      this.HideOnOccurenceTimeWeek = true
      this.HideOnOccurenceMonthWeekly = false
    } else if (showOccurenceType == 'monthly') {
      this.HideOnOccurenceWeekly = false
      this.HideOnOccurenceTimeWeek = false
      this.HideOnOccurenceMonthWeekly = true
    }
  }
  removeOccurrence(listDate: any) {
    // this.ngxService.start();
    var index = this.OccuringData.map((x: any) => {
      return x.date;
    }).indexOf(listDate);
    this.OccuringData.splice(index, 1);
    this.OccuringData = [...this.OccuringData]

    let OccArr = []
    for (let i = 0; i < this.OccuringData.length; i++) {
      if (this.OccuringData[i]['is_booked'] == true) {
        OccArr.push(this.OccuringData[i])
      } else {
        this.showUpdateBtn = true
      }
    }
    if (OccArr.length > 0) {
      this.showUpdateBtn = false
    } else {
      this.showUpdateBtn = true
    }
    // this.ngxService.stop();
  }
  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.fileName = !this.fileName;
      this.fileName = event.target.files[0].name

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        //me.modelvalue = reader.result;
      };
    }
  }
  closeFlexiModal() {
    this.bsModalRef1.hide()
  }
  closeRequestModal() {
    this.bsModalRef2.hide()
  }
  closeModal() {
    this.bsModalRef.hide()
    this.preConfrimBookingForm.reset();
    this.formInit();
    this.showDelay = false;
    this.showSendBookingBtn = true;
    this.submitted = false;
    this.showOccurenceModalTab = false
    this.showOccurenceReview = true
    this.HideOnOccurenceWeekly = true
    this.HideOnOccurenceTimeWeek = true
    this.HideOnOccurenceMonthWeekly = false
    this.hidetimeRange = false
    this.selected = [1]
    this.fileName = false
    this.preConfrimBookingForm.patchValue({
      week_repeater: this.selected,
    });
    this.is_showCusCal = false
    return this.preConfrimBookingForm.value;
  }
  showInviteDetails() {
    this.inviteDetails = !this.inviteDetails
  }
  get f(): { [key: string]: AbstractControl } {
    return this.preConfrimBookingForm.controls;
  }
  get getFormControl() {
    return this.preConfrimFilterForm.controls;
  }
  get getBookingFormControl() {
    return this.preConfrimFilterForm.controls;
  }

  acceptBookingRequest() {

  }
  addEditNotes(teacherName: any, type: any, notes: any, id: any) {
    const initialState = {
      teacherName: teacherName,
      type: type,
      notes: notes,
      id: id,
      from_page: 'preconfirmed',
      teachers_list: this.preConfirmList,
      date: this.inlineEndDate
    };
    this.bsModalRef = this.modalService.show(AddNotesComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }
  openAdminSettings() {
    this.modalService.hide()
    this._router.navigate(['/school/edit-admin-settings/payroll-report-settings']);
  }

  getSpecialValue() {
    let timing = this.preConfrimBookingForm.controls['timerange'].value;
    if (timing == 'special') {
      this.hidetimeRange = true
    } else {
      this.hidetimeRange = false
    }
  }
  async sortBy(type: any) {
    this.preConfrimFilterForm.patchValue({
      order_by: type
    })
    this.getFilterInputs()
    // if (type == 'name') {
    //   this.preConfirmList = this.preConfirmList.sort((a: any, b: any) => a.teacher.user.full_name > b.teacher.user.full_name ? 1 : -1);
    // } else if (type == 'lastSeen') {
    //   this.preConfirmList = this.preConfirmList.sort((a: any, b: any) => {
    //     return <any>new Date(b.teacher.user.last_login_min) - <any>new Date(a.teacher.user.last_login_min);
    //   });
    // } else {
    //   this.preConfirmList = this.preConfirmList.sort((i: any, j: any) => {
    //     if (j.teacher.distance_from == "") {
    //       return -1;
    //     }
    //     if (i.teacher.distance_from == "") {
    //       return 1;
    //     }
    //     if (Number(j.teacher.distance_from.split(" ")[0]) > Number(i.teacher.distance_from.split(" ")[0])) {
    //       return -1;
    //     } else {
    //       return 1;
    //     }
    //   });
    // }
  }

  onChangeDate(eventDate: any) {
    this.bsRangeValue = undefined;
    let currentDate = new Date(moment(eventDate, 'DD/MM/YYYY').toDate());

    // Find the index of an object with a specific property value
    let index = this.dateCustomClasses.findIndex((item: any) => moment(item.date).format('DD/MM/YYYY') === moment(currentDate).format('DD/MM/YYYY'));
    // Check if the object with the specified property value exists in the array
    if (index === -1) {
      // If not found, push a new object with the desired properties
      this.dateCustomClasses.push({ date: currentDate, classes: ['date-bg'] });
    } else {
      // If found, log a message indicating that the object already exists
      this.dateCustomClasses.splice(index, 1);
    }


    this.selectedItem = Array.prototype.map.call(this.dateCustomClasses, function (item) { return moment(item.date).format('DD/MM/YYYY'); }).join(",");
    if (this.bsRangeValue != null) {
      this.bsRangeValue = undefined;
    }
  }

  datepickerOpen() {
    if (this.bsRangeValue != null) {
      this.bsRangeValue = undefined;
    }
  }

  triggerDP(e: any) {
    e.preventDefault();
    let element: HTMLElement = document.getElementsByClassName('multi-select-dp')[0] as HTMLElement;
    element.click();
  }

  showCusCal() {
    this.is_showCusCal = true
  }
  closeBookingDatepicker() {
    this.is_showCusCal = false
  }

  redirectToPostCallOut() {
    if (window.location.hostname == "booking-test.classcover.com.au") {
      window.open("https://jobs-test.classcover.com.au/school/post-job?type=postCallOut")
    } else {
      window.open("https://jobs.classcover.com.au/school/post-job?type=postCallOut")
    }
    // this._router.navigate(['/school/post-job/'], { queryParams: { type: 'postCallOut' } });
  }
  topFunction() {
    window.scroll(0, 0)
  }
}




