import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, ViewChild, IterableDiffers } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { SchoolData } from 'src/app/models/schoolUser';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import timepicker from 'src/app/pages/producer/timepicker';

@Component({
  selector: 'app-edit-admin-settings',
  templateUrl: './edit-admin-settings.component.html',
  styleUrls: ['./edit-admin-settings.component.scss'],
  standalone: false
})

export class EditAdminSettingsComponent implements OnInit {
  @ViewChild('schoolTimeFirst') schoolTimeFirst: any;
  @ViewChild('schoolTimeSecond') schoolTimeSecond: any;
  @ViewChild('schoolTimeThird') schoolTimeThird: any;
  @ViewChild('addBreakTimeModel') addBreakTimeModel: any;
  @ViewChild('editBreakTimeModel') editBreakTimeModel: any;
  @ViewChild('updateStaffAbsenceListmodel') updateStaffAbsenceListmodel: any;
  @ViewChild('inviteNewMemberModel') inviteNewMemberModel: any;

  editType?: string;
  dailySummaryValues: any;
  dailyStartTime: any;
  manageTeamData: any;
  roleList: any;
  dailyEndTime: any;
  leaveList: any = [];
  finalList: any = [];
  merged: any = [];
  bookingConfirm: any;
  checkBoxSelectedTeacher: any = [];
  activeTab = 'manage-access';
  timepicker: typeof timepicker | undefined;
  hours_d: any;
  minutes_d: any;
  permissionsList = [
    {
      tag: 'owner',
      name: 'Owner'
    },
    {
      tag: 'administrator_access',
      name: 'Administrator Access'
    },
    {
      tag: 'limited_access',
      name: 'Limited Access'
    }
  ];

  public schoolProps!: SchoolData;
  @ViewChild('resendPendingInviteModal') resendPendingInviteModal: any;
  @ViewChild('removeAddUserModal') removeAddUserModal: any;
  @ViewChild('editSelectedStaff') editSelectedStaff: any;
  @ViewChild('RemovestaffConfirmation') RemovestaffConfirmation: any;
  @ViewChild('inAppCancelModal') inAppCancelModal: any;
  @ViewChild('customCheckboxModal') customCheckboxModal: any;

  public dailyBookingForm: any;
  public customCheckboxForm: any;
  public inAppCancelForm: any;


  public abcenseReasonForm: any;
  public bookingConfirmForm: any;
  public payrollSettingsForm: any;
  public smsSettingsForm: any;
  public orderList: any;
  showSchoolTime: boolean = true;
  selectAll: boolean = false;
  submittedStaffDetails: boolean = false;
  submittedAdduser: boolean = false;
  isCheckJobAccess: boolean = false;


  // editSortable: boolean = false
  iterableDiffer: any
  selectedStaff: any;
  inviteList: any = [
    {
      "name": "Test 1",
      "salary": 56000,
      "married": true
    }, {
      "name": "Test 2",
      "salary": 56000,
      "married": true
    }, {
      "name": "Test 3",
      "salary": 56000,
      "married": true
    }, {
      "name": "Test 4",
      "salary": 56000,
      "married": true
    }, {
      "name": "Test 5",
      "salary": 56000,
      "married": true
    }, {
      "name": "Test 6",
      "salary": 56000,
      "married": true
    },
  ]
  list: any;
  jobAccessAction: any
  editAllDataBreak: any;
  editAllDataSchoolFirst: any;
  editAllDataSchoolSecond: any;
  editAllDataSchoolThird: any;
  userName: any;
  removeId: any;
  inAppCancelSwitch: any
  submittedInAppForm: boolean = false;
  submitCheckboxForm: boolean = false;
  automaticallyReplace: any
  showContactInfo: boolean = false;
  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    public bsModalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private iterableDiffers: IterableDiffers,
    private toastr: ToastrService) {
    this.iterableDiffer = iterableDiffers.find([]).create();
  }


  editAllDataSchoolFirstForm: FormGroup = this.fb.group({
    start_time: ['', [Validators.required]],
    end_time: ['', [Validators.required]],
    from: ['', [Validators.required]],
    action: ['time_setting', [Validators.required]],
  });

  editAllDataSchoolSecondForm: FormGroup = this.fb.group({
    start_time: ['', [Validators.required]],
    end_time: ['', [Validators.required]],
    from: ['', [Validators.required]],
    action: ['time_setting', [Validators.required]],
  });

  editAllDataSchoolThirdForm: FormGroup = this.fb.group({
    start_time: ['', [Validators.required]],
    end_time: ['', [Validators.required]],
    from: ['', [Validators.required]],
    action: ['time_setting', [Validators.required]],
  });

  addBreakTimeForm: FormGroup = this.fb.group({
    start_time: ['', [Validators.required]],
    end_time: ['', [Validators.required]],
    action: ['break_time', [Validators.required]],
    from: ['add', [Validators.required]],
  });

  removeBreakTimeForm: FormGroup = this.fb.group({
    action: ['break_time', [Validators.required]],
    from: ['delete', [Validators.required]],
    index: ['', [Validators.required]],
  });

  editBreakTimeForm: FormGroup = this.fb.group({
    start_time: ['', [Validators.required]],
    end_time: ['', [Validators.required]],
    action: ['break_time', [Validators.required]],
    from: ['edit', [Validators.required]],
    index: ['', [Validators.required]],
  });

  updateStaffAbsenceListForm: FormGroup = this.fb.group({
    action: ['absent_list', [Validators.required]],
    from: ['add', [Validators.required]],
    quantities: new FormArray([]),
    reason_absent_list: ['', [Validators.required]],
  });

  newInviteMemberStaff: FormGroup = this.fb.group({
    from: ['', [Validators.required]],
    action: ['new_invite', [Validators.required]],
  });

  additionalUserForm: FormGroup = this.fb.group({
    first_name: [''],
    last_name: [''],
    email: ['', [Validators.required, this.noWhitespaceValidator]],
    phone: ['', [Validators.required, this.noWhitespaceValidator]],
  });

  manageTeamForm: FormGroup = this.fb.group({
    jobAccess: [false],
    add_user_id: [''],
    action: [''],
  });

  manageTeamAccessForm: FormGroup = this.fb.group({
    role_id: ['', [Validators.required]],
    permission: ['', [Validators.required]],
    action: ['edit'],
    add_user_id: ['']
  });


  contactDetailsforCancelForm: FormGroup = this.fb.group({
    cancellation_email: ['', [Validators.required, this.noWhitespaceValidator]],
    cancellation_phone: ['', [Validators.required, this.noWhitespaceValidator]],
    is_casual_cancel: [''],
    rebook: [''],
    action: [''],
  });


  ngOnInit(): void {
    this.ngxService.start();
    this.timepicker = timepicker
    this.hours_d = this.timepicker[0].hours
    this.minutes_d = this.timepicker[0].minutes
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.route.params.subscribe(params => {
      this.editType = params['editType'];
    });

    if (this.editType == 'additional-users' && this.schoolProps.properties.job_only_school) {
      this.ngxService.start();
      this.bookingService.manageTeamSettings('').subscribe({
        next: (res: any) => {
          this.manageTeamData = res.data;
          this.roleList = res?.role;
          if (this.editType == 'additional-users') {
            this.newInviteMemberStaff = this.fb.group({
              new_staff_first_name: ['', [Validators.required, this.noWhitespaceValidator]],
              new_staff_last_name: ['', [Validators.required, this.noWhitespaceValidator]],
              new_staff_email: ['', [Validators.required, this.noWhitespaceValidator]],
              new_staff_phone_number: ['', [Validators.required, this.noWhitespaceValidator]],
            });
          }
          this.manageTeamForm.patchValue({
            jobAccess: this.manageTeamData.is_admin
          })
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else {
      this.bookingService.editAdminSettings('').subscribe({
        next: (res: any) => {
          this.editAllDataBreak = res.data.admin_settings.break_time;
          this.editAllDataSchoolFirst = res.data.admin_settings.default_school_time.first_half;
          this.editAllDataSchoolSecond = res.data.admin_settings.default_school_time.second_half;
          this.editAllDataSchoolThird = res.data.admin_settings.default_school_time.full_day;
          this.bookingConfirm = res.data.admin_settings.booking_confirmation_settings;
          if (this.editType == 'inAppCancel') {
            if (res.data.admin_settings.casuals_cancel_booking.auto_rebook == "1") {
              this.automaticallyReplace = true
            } else {
              this.automaticallyReplace = false
            }
            if (res.data.admin_settings.casuals_cancel_booking.is_casual_cancel == "1") {
              this.inAppCancelSwitch = true
              this.showContactInfo = true
              this.contactDetailsforCancelForm.patchValue({
                cancellation_email:res.data.additional_info.booking_cancel_casuals.cancellation_email,
                cancellation_phone:res.data.additional_info.booking_cancel_casuals.cancellation_phone
              })
            } else {
              this.inAppCancelSwitch = false
              this.showContactInfo = false
            }
          }
          if (this.editType == 'customCheckbox') {
            this.customCheckboxForm = this.fb.group({
              admin_custom_field: [res.data.admin_settings.certified_text, [Validators.required,this.noWhitespaceValidator]],
              change_checked:[''],
              action: ['customCheckbox'],
            });
          }
          if (this.editType == 'daily-summary') {
            this.dailySummaryValues = res.data.admin_settings.daily_booking_summary_time;
            var splitted = this.dailySummaryValues.split(":");
            this.dailyStartTime = splitted[0];
            this.dailyEndTime = splitted[1];

            this.dailyBookingForm = this.fb.group({
              booking_summary_hr: [this.dailyStartTime, [Validators.required]],
              booking_summary_min: [this.dailyEndTime, [Validators.required]],
              action: ['booking_summary', [Validators.required]],
            });
          } else if (this.editType == 'staff-reason-for-absent') {
            this.leaveList = res.data.admin_settings.reason_absent_list;
            this.abcenseReasonForm = this.fb.group({
              index: ['', [Validators.required]],
              from: ['delete', [Validators.required]],
              action: ['absent_list', [Validators.required]],
            });

            this.updateStaffAbsenceListForm = this.fb.group({
              action: ['absent_list', [Validators.required]],
              from: ['add', [Validators.required]],
              quantities: new FormArray([]),
              reason_absent_list: ['', [Validators.required]],
            });

          } else if (this.editType == 'booking-confirmation-form') {
            this.bookingConfirmForm = this.fb.group({
              is_class_being_covered: [this.bookingConfirm.is_class_being_covered, [Validators.required]],
              class_being_covered_data: [this.bookingConfirm.Class_being_covered, [Validators.required]],
              teacher_being_covered_data: [this.bookingConfirm.Teacher_being_covered, [Validators.required]],
              is_teacher_being_covered: [this.bookingConfirm.is_teacher_being_covered, [Validators.required]],
              confirmation_reason_absense: [this.bookingConfirm.reason_for_absence, [Validators.required]],
              booking_without_communication: [this.bookingConfirm.booking_without_communication, [Validators.required]],
              action: ['form_setting', [Validators.required]],
            });
          } else if (this.editType == 'payroll-report-settings') {
            this.payrollSettingsForm = this.fb.group({
              payroll_field_label_1: [res.data.admin_settings.payroll_report_setting.payroll_field_label_1, [Validators.required]],
              payroll_field_label_2: [res.data.admin_settings.payroll_report_setting.payroll_field_label_2, [Validators.required]],
              payroll_field_label_3: [res.data.admin_settings.payroll_report_setting.payroll_field_label_3, [Validators.required]],
              leave_approved_label: [res.data.admin_settings.payroll_report_setting.leave_approved_labell_value, [Validators.required]],
              action: ['payroll_setting', [Validators.required]],
            });
          } else if (this.editType == 'sms-settings') {
            this.smsSettingsForm = this.fb.group({
              sms_invite_limit: [res.data.admin_settings.sms_setting.sms_limit, [Validators.required]],
              is_disable_sms: [res.data.admin_settings.sms_setting.disable_reply_after_filled, [Validators.required]],
              action: ['sms_setting', [Validators.required]],
            });
          } else if (this.editType == 'default-school-times') {
            this.editAllDataSchoolFirstForm = this.fb.group({
              first_form_start_time: [this.editAllDataSchoolFirst.start_time.slice(0, -3), [Validators.required]],
              first_form_end_time: [this.editAllDataSchoolFirst.start_time.slice(3), [Validators.required]],
              one_form_start_time: [this.editAllDataSchoolFirst.end_time.slice(0, -3), [Validators.required]],
              one_form_end_time: [this.editAllDataSchoolFirst.end_time.slice(3), [Validators.required]],
              from: [this.editAllDataSchoolFirst.booking_type, [Validators.required]],
              start_time: ['', [Validators.required]],
              action: ['time_setting', [Validators.required]],
              end_time: ['', [Validators.required]],
            });
            this.editAllDataSchoolSecondForm = this.fb.group({
              second_start_time: [this.editAllDataSchoolSecond.start_time.slice(0, -3), [Validators.required]],
              second_end_time: [this.editAllDataSchoolSecond.start_time.slice(3), [Validators.required]],
              two_start_time: [this.editAllDataSchoolSecond.end_time.slice(0, -3), [Validators.required]],
              two_end_time: [this.editAllDataSchoolSecond.end_time.slice(3), [Validators.required]],
              from: [this.editAllDataSchoolSecond.booking_type, [Validators.required]],
              action: ['time_setting', [Validators.required]],
              start_time: ['', [Validators.required]],
              end_time: ['', [Validators.required]],
            });
            this.editAllDataSchoolThirdForm = this.fb.group({
              third_start_time: [this.editAllDataSchoolThird.start_time.slice(0, -3), [Validators.required]],
              third_end_time: [this.editAllDataSchoolThird.start_time.slice(3), [Validators.required]],
              three_start_time: [this.editAllDataSchoolThird.end_time.slice(0, -3), [Validators.required]],
              three_end_time: [this.editAllDataSchoolThird.end_time.slice(3), [Validators.required]],
              from: [this.editAllDataSchoolThird.booking_type, [Validators.required]],
              action: ['time_setting', [Validators.required]],
              start_time: ['', [Validators.required]],
              end_time: ['', [Validators.required]],
            });
            this.addBreakTimeForm = this.fb.group({
              add_start_time: ['', [Validators.required]],
              add_end_time: ['', [Validators.required]],
              plus_start_time: ['', [Validators.required]],
              plus_end_time: ['', [Validators.required]],
              action: ['break_time', [Validators.required]],
              from: ['add', [Validators.required]],
              start_time: ['', [Validators.required]],
              end_time: ['', [Validators.required]],
            });

            this.editBreakTimeForm = this.fb.group({
              action: ['break_time', [Validators.required]],
              from: ['edit', [Validators.required]],
              edit_start_time: ['', [Validators.required]],
              edit_end_time: ['', [Validators.required]],
              second_edit_start_time: ['', [Validators.required]],
              second_edit_end_time: ['', [Validators.required]],
              index: ['', [Validators.required]],
              start_time: ['', [Validators.required]],
              end_time: ['', [Validators.required]],
            });
          }

          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
    this.formInit();
  }

  getmanageTeamForm() {
    return this.manageTeamForm.value
  }
  jobAccess(userId: any, event: any) {
    this.ngxService.start();
    this.manageTeamForm.patchValue({
      add_user_id: userId.toString(),
      jobAccess: event.target.checked,
      action: 'additional_user'
    })
    this.bookingService.jobAccessApi(this.getmanageTeamForm()).subscribe({
      next: (res: any) => {
        let currentUrl = this._router.url;
        if (res.status) {
          this.toastr.success(res.message);
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        }
      },
      error: (err: any) => {
        console.log(err);
        alert('error')
        this.ngxService.stop();
      }
    });
  }
  formInit() {
    this.orderList = this.fb.group({

    });
    this.editType
    if (this.editType == 'customCheckbox') {
      this.customCheckboxForm = this.fb.group({
        admin_custom_field: ['', [Validators.required,this.noWhitespaceValidator]],
        change_checked:['', [Validators.required]],
        action: [this.editType]
      });
    }
    if (this.editType == 'daily-summary') {
      this.dailyBookingForm = this.fb.group({
        booking_summary_hr: ['', [Validators.required]],
        booking_summary_min: ['', [Validators.required]],
        action: [this.editType]
      });
    } else if (this.editType == 'booking-and-notification-settings') {
      this.abcenseReasonForm = this.fb.group({
        index: ['', [Validators.required]],
        from: ['delete', [Validators.required]],
        action: ['absent_list', [Validators.required]],
      });
    } else if (this.editType == 'booking-confirmation-form') {
      this.bookingConfirmForm = this.fb.group({
        is_class_being_covered: ['', [Validators.required]],
        class_being_covered_data: ['', [Validators.required]],
        teacher_being_covered_data: ['', [Validators.required]],
        is_teacher_being_covered: ['', [Validators.required]],
        confirmation_reason_absense: ['', [Validators.required]],
        booking_without_communication: ['', [Validators.required]],
      });
    } else if (this.editType == 'payroll-report-settings') {
      this.payrollSettingsForm = this.fb.group({
        payroll_field_label_1: ['', [Validators.required]],
        payroll_field_label_2: ['', [Validators.required]],
        payroll_field_label_3: ['', [Validators.required]],
        leave_approved_label: ['', [Validators.required]],
      });
    } else if (this.editType == 'sms-settings') {
      this.smsSettingsForm = this.fb.group({
        sms_invite_limit: ['', [Validators.required]],
        is_disable_sms: ['', [Validators.required]],
      });
    }
  }
  openAddUserModal() {
    this.bsModalRef = this.modalService.show(this.resendPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
  }
  removeAddModal(userName: any, removeId: any) {
    this.userName = userName
    this.removeId = removeId
    this.bsModalRef = this.modalService.show(this.removeAddUserModal, { class: 'modal-md', backdrop: 'static' });
  }
  customModal() {
      this.submitCheckboxForm = true
      if (this.customCheckboxForm.invalid) {
        return;
      }else{
        this.submitCheckboxForm = false  
      }
    this.bsModalRef = this.modalService.show(this.customCheckboxModal, { class: 'modal-md', backdrop: 'static' });
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  quantities(): FormArray {
    return this.updateStaffAbsenceListForm.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      new_leave_reason: '',
    })
  }

  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
  removeTempStaffAbsent(index: number) {
    const remove = this.updateStaffAbsenceListForm.get('quantities') as FormArray;
    remove.removeAt(index);
  }
  removeAdd(userId: any) {
    this.ngxService.start();
    this.manageTeamForm.patchValue({
      add_user_id: userId.toString(),
      action: 'remove',
      jobAccess: ''
    })
    this.bookingService.jobAccessApi(this.getmanageTeamForm()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide()
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
          this.bsModalRef.hide();
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        alert('error')
        this.ngxService.stop();
      }
    });
  }
  getControlSmartValuesFirst() {
    return this.editAllDataSchoolFirstForm.value;
  }
  getControlSmartValuesSecond() {
    return this.editAllDataSchoolSecondForm.value;
  }
  getControlSmartValuesThird() {
    return this.editAllDataSchoolThirdForm.value;
  }
  getControlBreakValue() {
    return this.addBreakTimeForm.value;
  }
  getControlBreakDeleteValue() {
    return this.removeBreakTimeForm.value;
  }
  getControlBreakEditValue() {
    return this.editBreakTimeForm.value;
  }
  getControlUpdateAbsentValue() {
    return this.updateStaffAbsenceListForm.value;
  }
  getControlInviteNewStaff() {
    return this.newInviteMemberStaff.value;
  }
  getAddUserFormControl() {
    return this.additionalUserForm.value;
  }
  get i(): { [key: string]: AbstractControl } {
    return this.newInviteMemberStaff.controls;
  }

  get ad(): { [key: string]: AbstractControl } {
    return this.additionalUserForm.controls;
  }

  getFormControlValues() {
    if (this.editType == 'daily-summary') {
      return this.dailyBookingForm.value;
    } else if (this.editType == 'staff-reason-for-absent') {
      return this.abcenseReasonForm.value;
    } else if (this.editType == 'booking-confirmation-form') {
      return this.bookingConfirmForm.value;
    } else if (this.editType == 'payroll-report-settings') {
      return this.payrollSettingsForm.value;
    } else if (this.editType == 'sms-settings') {
      return this.smsSettingsForm.value;
    } else if (this.editType == 'customCheckbox') {
      return this.customCheckboxForm.value;
    } else if (this.editType == 'inAppCancel') {
      return this.contactDetailsforCancelForm.value;
    }

  }

  inviteTeam() {
    this.bsModalRef = this.modalService.show(this.inviteNewMemberModel, { class: 'modal-md', backdrop: 'static' });
  }

  closeModal() {
    this.bsModalRef.hide()
  }

  showEditSchoolTime() {
    this.showSchoolTime = !this.showSchoolTime
  }
  editSortableBtn() {
    this.bsModalRef = this.modalService.show(this.updateStaffAbsenceListmodel, { class: 'modal-md', backdrop: 'static' });
  }

  editSchoolTime(schoolTimeCheck: any) {
    if (schoolTimeCheck == 'first-time') {
      this.bsModalRef = this.modalService.show(this.schoolTimeFirst, { class: 'modal-md', backdrop: 'static' });
    } else if (schoolTimeCheck == 'second-time') {
      this.bsModalRef = this.modalService.show(this.schoolTimeSecond, { class: 'modal-md', backdrop: 'static' });
    } else if (schoolTimeCheck == 'third-time') {
      this.bsModalRef = this.modalService.show(this.schoolTimeThird, { class: 'modal-md', backdrop: 'static' });
    }
  }

  saveEditSchoolTime(schoolTime: any) {
    this.ngxService.start();
    if (schoolTime == 'first-time') {
      let start_hrs = this.editAllDataSchoolFirstForm.controls['first_form_start_time'].value;
      let start_min = this.editAllDataSchoolFirstForm.controls['first_form_end_time'].value;
      let end_hrs = this.editAllDataSchoolFirstForm.controls['one_form_start_time'].value;
      let end_min = this.editAllDataSchoolFirstForm.controls['one_form_end_time'].value;
      this.editAllDataSchoolFirstForm.patchValue({
        start_time: start_hrs + ':' + start_min,
        end_time: end_hrs + ':' + end_min,
      });
      this.bookingService.saveChangesAdminSettings(this.getControlSmartValuesFirst()).subscribe({
        next: (res: any) => {
          this.ngxService.stop();
          this.modalService.hide();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else if (schoolTime == 'second-time') {
      let start_hrs = this.editAllDataSchoolSecondForm.controls['second_start_time'].value;
      let start_min = this.editAllDataSchoolSecondForm.controls['second_end_time'].value;
      let end_hrs = this.editAllDataSchoolSecondForm.controls['two_start_time'].value;
      let end_min = this.editAllDataSchoolSecondForm.controls['two_end_time'].value;
      this.editAllDataSchoolSecondForm.patchValue({
        start_time: start_hrs + ':' + start_min,
        end_time: end_hrs + ':' + end_min,
      });
      this.bookingService.saveChangesAdminSettings(this.getControlSmartValuesSecond()).subscribe({
        next: (res: any) => {
          this.ngxService.stop();
          this.modalService.hide();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else if (schoolTime == 'third-time') {
      let start_hrs = this.editAllDataSchoolThirdForm.controls['third_start_time'].value;
      let start_min = this.editAllDataSchoolThirdForm.controls['third_end_time'].value;
      let end_hrs = this.editAllDataSchoolThirdForm.controls['three_start_time'].value;
      let end_min = this.editAllDataSchoolThirdForm.controls['three_end_time'].value;
      this.editAllDataSchoolThirdForm.patchValue({
        start_time: start_hrs + ':' + start_min,
        end_time: end_hrs + ':' + end_min,
      });
      this.bookingService.saveChangesAdminSettings(this.getControlSmartValuesThird()).subscribe({
        next: (res: any) => {
          this.ngxService.stop();
          this.modalService.hide();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });

  }

  addNewBreakTime() {
    this.bsModalRef = this.modalService.show(this.addBreakTimeModel, { class: 'modal-md', backdrop: 'static' });
  }

  addBreakSchoolTime() {
    let start_hrs = this.addBreakTimeForm.controls['add_start_time'].value
    let start_min = this.addBreakTimeForm.controls['add_end_time'].value
    let end_hrs = this.addBreakTimeForm.controls['plus_start_time'].value
    let end_min = this.addBreakTimeForm.controls['plus_end_time'].value
    this.addBreakTimeForm.patchValue({
      start_time: start_hrs + ':' + start_min,
      end_time: end_hrs + ':' + end_min,
    });
    if (start_hrs == '' || start_min == '' || end_hrs == '' || end_min == '') {
      this.toastr.warning('Please fill the time');
    } else {
      this.ngxService.start();
      this.bookingService.saveChangesAdminSettings(this.getControlBreakValue()).subscribe({
        next: (res: any) => {
          this.ngxService.stop();
          this.modalService.hide();
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }

  updateListSwap(event: any) {
    event.preventDefault();
    this.updateStaffAbsenceListForm.patchValue({
      reason_absent_list: this.leaveList,
    });
    this.ngxService.start();
    this.bookingService.saveChangesAdminSettings(this.getControlUpdateAbsentValue()).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  removeBreakSchoolTime(index: number) {
    this.ngxService.start();
    this.removeBreakTimeForm.patchValue({
      index: index,
    });
    this.bookingService.saveChangesAdminSettings(this.getControlBreakDeleteValue()).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  addNewStaffSave() {
    this.submittedStaffDetails = true;
    this.ngxService.start();
    this.bookingService.saveChangesAdminSettings(this.getControlInviteNewStaff()).subscribe({
      next: (res: any) => {
        this.submittedStaffDetails = false;
        this.ngxService.stop();
        this.modalService.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  closeAddUserModal() {
    this.bsModalRef.hide()
    this.submittedAdduser = false;
  }

  addAdditionalUser() {
    this.submittedAdduser = true;
    if (this.additionalUserForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.bookingService.addAdditionalUserApi(this.getAddUserFormControl()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message)
          this.submittedAdduser = false;
          this.ngxService.stop();
          this.modalService.hide();
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  editModelOpen(timeEdit: any, indexEdit: any) {
    this.bsModalRef = this.modalService.show(this.editBreakTimeModel, { class: 'modal-md', backdrop: 'static' });
    this.editBreakTimeForm.patchValue({
      edit_start_time: timeEdit.start_time.slice(0, -3),
      edit_end_time: timeEdit.start_time.slice(3),
      second_edit_start_time: timeEdit.end_time.slice(0, -3),
      second_edit_end_time: timeEdit.end_time.slice(3),
      index: indexEdit,
    });
  }

  editBreakSchoolTime() {
    let start_hrs = this.editBreakTimeForm.controls['edit_start_time'].value
    let start_min = this.editBreakTimeForm.controls['edit_end_time'].value
    let end_hrs = this.editBreakTimeForm.controls['second_edit_start_time'].value
    let end_min = this.editBreakTimeForm.controls['second_edit_end_time'].value
    this.editBreakTimeForm.patchValue({
      start_time: start_hrs + ':' + start_min,
      end_time: end_hrs + ':' + end_min,
    });
    this.ngxService.start();
    this.bookingService.saveChangesAdminSettings(this.getControlBreakEditValue()).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        this.modalService.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  removeStaffAbsenceList(id: number) {
    this.ngxService.start();
    this.abcenseReasonForm.patchValue({
      index: id,
    });
    this.bookingService.saveChangesAdminSettings(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        this.modalService.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  updateStaffAbsenceListSave() {
    this.finalList = [];
    for (let i = 0; i < this.updateStaffAbsenceListForm.value.quantities.length; i++) {
      this.finalList.push(Object.values(this.updateStaffAbsenceListForm.value.quantities[i]).toString().replace(/\[.*\]/g, ''))
    }
    this.merged = [...this.leaveList, ...this.finalList];
    this.updateStaffAbsenceListForm.patchValue({
      reason_absent_list: this.merged,
    });
    this.ngxService.start();
    this.bookingService.saveChangesAdminSettings(this.getControlUpdateAbsentValue()).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        this.modalService.hide();
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  saveAdminSettings() {
    if(this.showContactInfo == true){
      this.submittedInAppForm = true
      if (this.contactDetailsforCancelForm.invalid) {
        return;
      }
    }else{
      this.submittedInAppForm = false
    }
    if (this.editType == 'customCheckbox') {
      if (this.customCheckboxForm.controls['change_checked'].value == '') {
        this.submitCheckboxForm = true
        return;
      }else{
        this.submitCheckboxForm = false
      }
    }
   
    if (this.editType == 'inAppCancel') {
      this.contactDetailsforCancelForm.patchValue({
        is_casual_cancel: this.inAppCancelSwitch,
        action: this.editType,
        rebook: this.automaticallyReplace
      })
    }
    this.ngxService.start();
    this.orderList.addControl('order_list', new FormControl(Array.prototype.map.call(this.inviteList, function (item) { return item.name; }).join(",")));

    this.bookingService.saveChangesAdminSettings(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        this.toastr.success(res.message);
        this.bsModalRef.hide()
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        this.toastr.error(err.statusText);
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  //   ngDoCheck() {
  //     let changes = this.iterableDiffer.diff(this.inviteList);
  //     if (changes) {
  //       this.inviteList = changes.collection
  //       this.orderList.addControl('order_list', new FormControl(Array.prototype.map.call(this.inviteList, function (item) { return item.name; }).join(",")));
  //       console.log(this.orderList)
  //     }
  // }

  saveSortableSettings() {
    this.orderList.addControl('order_list', new FormControl(Array.prototype.map.call(this.inviteList, function (item) { return item.name; }).join(",")));
    return;
  }

  onChangeValue(valueString: any, index: any) {
    this.leaveList[index] = valueString?.target?.value;
  }

  makeAdminRta() {
    if (this.selectAll) {
      this.manageTeamAccessForm.patchValue({
        add_user_id: this.checkBoxSelectedTeacher.map((u: any) => u.additional_user?.user?.id).join(','),
        action: 'edit'
      });
    }
    this.commonService.makeRtaAdmin(this.manageTeamAccessForm.value).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  sendInAppCancelData() {
    this.submittedInAppForm = true
    if (this.contactDetailsforCancelForm.invalid) {
      return;
    }
    this.bsModalRef.hide()
  }

  openSelectedStaffEdit(staff: any) {
    this.selectedStaff = staff;
    this.selectAll = false;
    this.bsModalRef = this.modalService.show(this.editSelectedStaff, { class: 'modal-md', backdrop: 'static' });
    this.manageTeamAccessForm.patchValue({
      add_user_id: staff?.additional_user?.user?.id.toString(),
      role_id: staff?.role_access?.role_id,
      permission: staff?.role_access?.check_access,
    });
  }

  // set checkbox selected teachers
  setCheckbox(event: any, index: number) {
    if (!this.checkBoxSelectedTeacher.includes(event)) {
      this.checkBoxSelectedTeacher = [...this.checkBoxSelectedTeacher, event];
    } else {
      this.checkBoxSelectedTeacher = this.checkBoxSelectedTeacher.filter((item: any) => item !== event);
    }
    this.selectAll = this.checkBoxSelectedTeacher.length > 0 ? true : false;
  }

  checkAllStaff(event: any) {
    var checked = event.target.checked;
    this.checkBoxSelectedTeacher = [];
    this.selectAll = checked ? true : false;
    this.manageTeamData.forEach((x: any) => {
      if (checked) {
        if (x.role_access?.check_access != "owner") {
          x.isChecked = true;
        }
        this.checkBoxSelectedTeacher.push(x);
      } else {
        x.isChecked = false;
        this.checkBoxSelectedTeacher = [];
      }
    });
  }

  deleteSelected() {
    this.bsModalRef = this.modalService.show(this.RemovestaffConfirmation, { class: 'modal-md', backdrop: 'static' });
  }

  editSelected() {
    this.bsModalRef = this.modalService.show(this.editSelectedStaff, { class: 'modal-md', backdrop: 'static' });
  }

  removeStaff() {
    this.manageTeamAccessForm.patchValue({
      add_user_id: this.checkBoxSelectedTeacher.map((u: any) => u.additional_user?.user?.id).join(','),
      action: 'remove'
    });
    this.commonService.makeRtaAdmin(this.manageTeamAccessForm.value).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  invites(activeTab: string) {
    this.activeTab = activeTab;
  }
  cancelBookingInApp(event: any) {
    if (event.target.checked) {
      this.showContactInfo = true
      // this.bsModalRef = this.modalService.show(this.inAppCancelModal, { class: 'modal-md', backdrop: 'static' });
    } else {
      this.showContactInfo = false
    }
  }
  makeTrue(){
    this.submitCheckboxForm = false
  }
  closeCustomModal(){
    this.bsModalRef.hide()
    this.submitCheckboxForm = false
    this.customCheckboxForm.patchValue({
      change_checked: ''
    })
  }

  // automaticallyReplaceCancel(event: any){
  //   if(event.target.checked){

  //   }
  // }

  closeInAppModal() {
    this.submittedInAppForm = false
    this.inAppCancelSwitch = this.inAppCancelSwitch
    // this.dailyBookingForm.patchValue({
    //   is_casual_cancel: false
    // })
    this.bsModalRef.hide()
  }
}
