import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SchoolData } from 'src/app/models/schoolUser';
import { BookingService } from 'src/app/services/api/booking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-boost-job',
  templateUrl: './boost-job.component.html',
  styleUrls: ['./boost-job.component.scss'],
  standalone: false
})
export class BoostJobComponent implements OnInit {
  @Input() boostJob: any;
  @Input() freeBoost: any;
  public schoolProps!: SchoolData;
  @Input() freeBoostCount: any
  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    console.log(this.freeBoost)
  }

  boostPostedJob(id: any) {
    this.ngxService.start();
    this.bookingService.boostJobApi(id).subscribe({
      next: (res: any) => {
        if (res.status) {
          console.log(res)
          localStorage.setItem('job_id', this.boostJob.id);
          window.location.href = res.data
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        // this.toastr.error(err.message)
        this.ngxService.stop();
      }
    })
  }
}
