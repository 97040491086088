import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/api/booking.service';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { SchoolData } from 'src/app/models/schoolUser';
import { CommonService } from 'src/app/services/api/common.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-job-payment',
  templateUrl: './job-payment.component.html',
  styleUrls: ['./job-payment.component.scss'],
  standalone: false
})
export class JobPaymentComponent implements OnInit {
  payment_status: any;
  job_id: any;
  id: any;
  makeLiveForm: FormGroup = this.fb.group({
    job_id: ['']
  });
  jobUpdateForm: FormGroup = this.fb.group({
    plan_id: ['']
  });

  
  sub_id: any;
  plan_name: any;
  plan_id: any;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.route.queryParams.subscribe(params => {
      this.payment_status = params['state'];
    });
    this.route.queryParams.subscribe(params => {
      this.sub_id = params['sub_id'];
    });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });

    this.route.queryParams.subscribe(params => {
      this.plan_id = params['plan_id'];
    });


    this.job_id = localStorage.getItem('job_id')
    this.makeLiveForm.patchValue({
      job_id: this.job_id,
    });
    this.jobUpdateForm.patchValue({
      plan_id: this.plan_id,
    });
    if (this.payment_status != 'succeeded') {
      localStorage.removeItem('job_id');
    }
    if (this.payment_status == 'succeeded' || this.payment_status == 'requested') {
      localStorage.removeItem('job_id');
      this._router.navigate(['/school/jobs/']);
    }
  }

  getjobUpdateFormValue(){
    return this.jobUpdateForm.value
  }
  getMakeLiveFormValue(){
    return this.makeLiveForm.value
  }

}
