/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/api/booking.service';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { SchoolData } from 'src/app/models/schoolUser';
import { CommonService } from 'src/app/services/api/common.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../../services/api/message.service';
import { environment } from 'src/environments/environment';
import { MixpanelService } from 'src/app/mixpanel.service';
let autocomplete: google.maps.places.Autocomplete;
// declare var myExternalJs: any;
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic' ;
// import { Editor ,Toolbar} from 'ngx-editor';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.scss'],
  standalone: false
})
export class PostJobComponent implements OnInit {
  public schoolProps!: SchoolData;
  @Input() adressType!: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput!: string;
  queryWait!: boolean;

  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  schoolTimings = [];
  job_type = [];
  teacher: Array<any> = [];
  bsValue: any;
  selectedDate: any;
  public submitted: boolean = false;
  public sSubmitted: boolean = false;

  public JobBasicForm: any
  public jobSummaryForm: any
  public jobReviewForm: any

  

  saveTye: any;
  saveType: any;
  externalLink: boolean = false;
  showBasicInfo: boolean = true;
  showReview: boolean = false;
  showJobSummary: boolean = false
  showLocation: boolean = false;
  is_boost: boolean = false;
  role_type: any;
  jobSummaryReview: any;
  jobTitleReview: any;
  state: any;
  formJoin: any;
  jobCloseDate: any;
  jobSubjectReview: any;
  jobLevelReview: any;
  jobTypeReview: any;
  schoolLoc: any;
  jobLocationReview: any;
  is_subscribed: boolean = false;
  showSubscription: boolean = false;
  edit_job_id: any;
  editJobDetails: any;
  // minDate: Date | undefined;
  maxDate: Date | undefined;
  jobRemote: any;
  jobBoost: boolean = false;
  minDate: Date | undefined;
  action_type: any;
  disable: boolean = false;
  hideBtnOnReopen: boolean = false
  isReadOnly: boolean = true
  postCallOut: boolean = false
  profilePercent: any;
  others_job: any;
  is_free_boostCount: any;
  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private mixpanelService: MixpanelService) {
    this.bsValue = moment(new Date()).format('DD/MM/YYYY');
  }
  

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '250px',
      minHeight: 'auto',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      enableToolbar: false,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator:'p',
      toolbarHiddenButtons: [
        [
          'undo',
          'redo',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'fontName'
        ],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
      ],
};

  // public Editor = ClassicEditor;
  // public config = {
  //   toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'NumberedList', 'BulletedList'],
  //   plugins: [
  //     Bold, Italic, Heading, Link, List
  //   ],
  // }
  // public Editor = ClassicEditor;
  // content = "";
  // public config = {
  //   toolbar: ['heading', '|',  'bold', 'italic' ,'|', 'link','|', 'NumberedList','BulletedList'],
  //   format_tags : 'h3;p'
  // }
  // config = { toolbar: [ 'Cut', 'Copy', 'PasteText', '|', 'Undo', 'Redo', '|', 'Bold', 'Italic', 'Underline', 'Strike' , 'superscript', 'subscript', '|', 'link' ] }

  ngOnInit(): void {
    this.sharedService.navData.next(true)
    this.sharedService.header.next(false)
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate = new Date()
    this.maxDate.setDate(this.maxDate.getDate() + 31);
    this.bsValue = this.maxDate

    this.route.queryParams.subscribe(params => {
      this.action_type = params['type'];
    });
    if (this.action_type == 're_open') {
      this.disable = true
    }
    if (this.action_type == 'postCallOut') {
      this.postCallOut = true
    }
    this.route.queryParams.subscribe(params => {
      this.edit_job_id = params['job_id'];
    });
    if (this.edit_job_id != undefined && this.action_type == 'edit') {
      this.showBasicInfo = false
      this.showJobSummary = true
      this.disable = true
      this.is_boost = false
    }
    if (this.edit_job_id != undefined && this.action_type == 'draftEdit') {
      this.showBasicInfo = true
      this.showJobSummary = false
      this.disable = false
      this.is_boost = false
    }
    this.ngxService.start();
    if (this.edit_job_id != undefined) {
      this.ngxService.start();
      this.bookingService.editJobsAPi(this.edit_job_id).subscribe({
        next: (res: any) => {
          this.editJobDetails = res
          if (this.editJobDetails.job.job_status.job_status == "L") {
            this.showBasicInfo = false
            this.showJobSummary = true
            this.disable = true
            this.is_boost = false
          }
          this.JobBasicForm.patchValue({
            job_title: this.editJobDetails.job.job_title,
            job_type: this.editJobDetails.job.job_type.job_type,
            school_level: this.editJobDetails.job.school_year_level.school_year_level,
            job_role_type: this.editJobDetails.job.job_role_type,
            subject: this.editJobDetails.job.subject_taught,
            is_remote_job: this.editJobDetails.job.is_remote_job,
            school_suburb: this.editJobDetails.job.suburb,
            school_postcode: this.editJobDetails.job.post_code,
            school_state: this.editJobDetails.job.state,
          });
          if (this.action_type == 'edit') {
            this.bsValue = this.editJobDetails.job.job_close_date
          }
          if (this.action_type == 'draftEdit') {
            this.bsValue = this.editJobDetails.job.job_close_date
          }
          this.jobSummaryForm.patchValue({
            job_summary: this.editJobDetails.job.job_description,
            apply_through: this.editJobDetails.job.apply_through.apply_through,
            apply_through_site: this.editJobDetails.job.apply_through_site,
          });

          if (this.action_type == 're_open') {
            this.hideBtnOnReopen = true
          }
          if (this.editJobDetails.job.job_location != '') {
            this.schoolLoc = this.editJobDetails.job.job_location
          } else {
            this.schoolLoc = this.schoolProps.mixpanel_data.city + ', ' + this.schoolProps.mixpanel_data.state
          }
          this.JobBasicForm.patchValue({
            job_location: this.schoolLoc,
          });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error(err.message)
          this.ngxService.stop();
        }
      })
    }
    this.is_subscribed = false
    this.showSubscription = false
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.getFiltersData();
    this.formInit();
    this.schoolLoc = this.schoolProps.mixpanel_data.city + ', ' + this.schoolProps.mixpanel_data.state
    if (this.JobBasicForm.controls['school_suburb'].value != '' && this.JobBasicForm.controls['school_postcode'].value != '' && this.JobBasicForm.controls['school_state'].value != '') {
      this.schoolLoc = this.JobBasicForm.controls['school_suburb'].value + ', ' + this.JobBasicForm.controls['school_state'].value
    } else {
      this.jobLocationReview = this.JobBasicForm.controls['job_location'].value
    }
    this.JobBasicForm.patchValue({
      job_location: this.schoolLoc,
    });
    // if()
    this.ngxService.stop();
  }


  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'AU' },
        types: ['geocode'],  // 'establishment' / 'address' / 'geocode'
        fields: ["address_components", "geometry"],
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    // Get the place details from the autocomplete object.
    //   const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    let locality = "";
    let administrative_area_level_1 = "";
    let country = "";
    let political = ""

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${component.long_name}`;
          break;
        }

        case "locality":
          political = `${component.long_name}`;
          break;

        case "administrative_area_level_1": {
          administrative_area_level_1 = `${component.long_name}`;
          break;
        }
        case "political": {
          political = `${component.long_name}`;
          break;
        }

        case "colloquial_area": {
          political = `${component.long_name}`;
          break;
        }

        case "country":
          country = `${component.long_name}`;
          break;
      }
    }

    this.JobBasicForm.patchValue({
      school_suburb: locality,
      school_postcode: postcode,
      school_state: administrative_area_level_1,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      searchLocation: political + ', ' + administrative_area_level_1 + ', ' + country,
      // auto_job_location: political + ', ' + administrative_area_level_1 + ', ' + country
    });
  }


  formInit() {
    this.JobBasicForm = this.fb.group({
      job_title: ['', [Validators.required, this.noWhitespaceValidator]],
      job_type: ['', [Validators.required]],
      school_level: [[]],
      job_role_type: ['', [Validators.required]],
      job_close_date: ['', [Validators.required]],
      job_location: [''],
      lat: [''],
      lng: [''],
      subject: [''],
      draft: [''],
      is_remote_job: [false],
      school_suburb: [''],
      school_postcode: [''],
      school_state: [''],
      is_boost_job: [false],
      open_boost_subscription: [false],
      action_type: [this.action_type],
      auto_job_location: [''],
      searchLocation: ['']
    });

    this.jobSummaryForm = this.fb.group({
      job_summary: ['', [Validators.required, this.noWhitespaceValidator]],
      apply_through: ['', [Validators.required]],
      apply_through_site: [''],
    });

    this.JobBasicForm.patchValue({
      job_close_date: this.bsValue,
    });

    if (this.postCallOut == true) {
      this.minDate = new Date();
      this.maxDate = new Date()
      this.maxDate.setDate(this.minDate.getDate() + 31);
      this.bsValue = this.maxDate

      this.JobBasicForm.patchValue({
        job_title: 'Casual Relief Teacher Call Out',
        job_type: 'C',
        // school_level: 'PS',
        job_role_type: 1,
        job_close_date: this.bsValue,
      });
      this.jobSummaryForm = this.fb.group({
        job_summary: ['We are currently accepting applications to join our casual relief teaching list on ClassCover. Successful applicants will be added to our regular booking list. You can ‘quick apply' + ' with your ClassCover profile so we can view your information, attachments, and current availability. Please remember, that you must have approval to teach in the department of education that you are applying to work within. We look forward to receiving your application.'],
        apply_through: ['CCW'],
      });
    }
  }
  checkVal(event: any) {
    if (event == undefined || event == 'Invalid Date') {
      this.JobBasicForm.get('job_close_date').setValidators([Validators.required]); // 5.Set Required Validator
      this.JobBasicForm.get('job_close_date').updateValueAndValidity();
      this.bsValue = ''
    } else {
      this.JobBasicForm.get('job_close_date').clearValidators(); // 6. Clear All Validators
      this.JobBasicForm.get('job_close_date').updateValueAndValidity();
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('job').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.is_free_boostCount = res.data.free_boost_count
        this.subjectsAndTags = res.data.tags;
        this.levelFilter = res.data.school_level;
        this.job_type = res.data.job_type;
        this.role_type = res.data.role_type;
        this.state = res.data.state
        this.profilePercent = res.data.profile_percentage
        this.others_job = res.data.others_job
        // this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  getFormControlValues() {
    this.formJoin = { ...this.JobBasicForm.value, ...this.jobSummaryForm.value }
    return this.formJoin;
  }
  setBoostValue(event: any) {
    this.is_boost = event.target.checked
    this.jobBoost = this.is_boost
  }

  postJobs(saveType: any) {
    if (moment(this.bsValue, 'mm/dd/YYYY', true).isValid()) {
      this.selectedDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
      this.JobBasicForm.patchValue({
        job_close_date: this.selectedDate,
      });
    } else {
      this.JobBasicForm.patchValue({
        job_close_date: this.bsValue,
      });
    }
    this.JobBasicForm.patchValue({
      is_boost_job: this.is_boost,
    });
    if (saveType == 'post') {
      this.JobBasicForm.patchValue({
        draft: 0,
      });
    } else if (saveType == 'draft') {
      this.JobBasicForm.patchValue({
        draft: 1,
      });
    }

    if (this.showBasicInfo == true) {
      this.submitted = true;
      if (this.JobBasicForm.invalid) {
        return;
      }
    }
    if (this.showJobSummary == true) {
      this.sSubmitted = true;
      if (this.jobSummaryForm.invalid) {
        return;
      }
    }
    // if (this.filtersArray.job_limit == true && this.is_boost == true) {
    this.JobBasicForm.patchValue({
      open_boost_subscription: this.is_boost,
    });
    // }

    this.ngxService.start();
    this.bookingService.postJobsAPi(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          if (res.host_url != undefined) {
            localStorage.setItem('job_id', res.job_id);
            window.location.href = res.host_url
          } else {
            if (saveType == 'draft') {
              this.toastr.success(res.message);
              this._router.navigate(['/school/job-details'], { queryParams: { job_id: res.job_id } });
            } else if (res.is_subscription == false) {
              this.toastr.success(res.message);
              this._router.navigate(['/school/job-subscription'], { queryParams: { job_id: res.job_id, boost_job: this.is_boost } });
              this.ngxService.stop();
            } else {
              this.toastr.success(res.message);
              this.mixpanelService.postJobAction(res.is_remote_job, res.is_boost_job, res.status);
              // myExternalJs.postJobAction(res.job_id);
              this._router.navigate(['/school/job-details'], { queryParams: { job_id: res.job_id } });
            }
          }
        } else {
          this.toastr.error(res.message);
          this.mixpanelService.postJobAction(res.is_remote_job, res.is_boost_job, res.status);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.message);
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  UpdateJobs(saveType: any) {
    if (moment(this.bsValue, 'mm/dd/YYYY', true).isValid()) {
      this.selectedDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
      this.JobBasicForm.patchValue({
        job_close_date: this.selectedDate,
      });
    } else {
      this.JobBasicForm.patchValue({
        job_close_date: this.bsValue,
      });
    }
    this.JobBasicForm.patchValue({
      is_boost_job: this.is_boost,
    });

    if (saveType == 'post') {
      this.JobBasicForm.patchValue({
        draft: 0,
      });
    } else if (saveType == 'draft') {
      this.JobBasicForm.patchValue({
        draft: 1,
      });
    }

    if (this.showBasicInfo == true) {
      this.submitted = true;
      if (this.JobBasicForm.invalid) {
        return;
      }
    }
    if (this.showJobSummary == true) {
      this.sSubmitted = true;
      if (this.jobSummaryForm.invalid) {
        return;
      }
    }
    // if (this.filtersArray.job_limit == true && this.is_boost == true) {
    this.JobBasicForm.patchValue({
      open_boost_subscription: this.is_boost,
    });
    // }

    this.ngxService.start();
    this.bookingService.updateJobsAPi(this.getFormControlValues(), this.edit_job_id).subscribe({
      next: (res: any) => {
        if (res.status) {
          if (res.host_url != undefined) {
            localStorage.setItem('job_id', res.job_id);
            window.location.href = res.host_url
          } else {
            if (saveType == 'draft') {
              this.toastr.success(res.message);
              this._router.navigate(['/school/job-details'], { queryParams: { job_id: res.job_id } });
            } else if (res.is_subscription == false) {
              this.toastr.success(res.message);
              this._router.navigate(['/school/job-subscription'], { queryParams: { job_id: res.job_id, boost_job: this.is_boost } });
              this.ngxService.stop();
            } else {
              this.toastr.success(res.message);
              // myExternalJs.UpdateJobAction(res.job_id);
              this._router.navigate(['/school/job-details'], { queryParams: { job_id: res.job_id } });
            }
          }
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        alert("error")
        this.ngxService.stop();
      }
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.JobBasicForm.controls;
  }
  get s(): { [key: string]: AbstractControl } {
    return this.jobSummaryForm.controls;
  }

  showExternalLink() {
    let apply_through = this.jobSummaryForm.controls['apply_through'].value;
    if (apply_through == 'EW') {
      this.externalLink = true
    } else {
      this.externalLink = false
    }
  }
  checkLengthSubrub() {
    if (this.JobBasicForm.controls['school_suburb'].value != '') {
      if (this.JobBasicForm.controls['school_postcode'].value == '') {
        this.JobBasicForm.get('school_postcode').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_postcode').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      }

      if (this.JobBasicForm.controls['school_state'].value == '') {
        this.JobBasicForm.get('school_state').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_state').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_state').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_state').updateValueAndValidity();
      }
    } else if (this.JobBasicForm.controls['school_suburb'].value == '') {
      if (this.JobBasicForm.controls['school_postcode'].value != '') {
        this.JobBasicForm.get('school_suburb').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();
      } else if (this.JobBasicForm.controls['school_state'].value != '') {
        this.JobBasicForm.get('school_suburb').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_suburb').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();

        this.JobBasicForm.get('school_state').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_state').updateValueAndValidity();

        this.JobBasicForm.get('school_postcode').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      }
    }
  }
  checkLengthPostCode() {
    if (this.JobBasicForm.controls['school_postcode'].value != '') {
      if (this.JobBasicForm.controls['school_suburb'].value == '') {
        this.JobBasicForm.get('school_suburb').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_suburb').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();
      }

      if (this.JobBasicForm.controls['school_state'].value == '') {
        this.JobBasicForm.get('school_state').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_state').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_state').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_state').updateValueAndValidity();
      }
    } else if (this.JobBasicForm.controls['school_postcode'].value == '') {
      if (this.JobBasicForm.controls['school_suburb'].value != '') {
        this.JobBasicForm.get('school_postcode').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      } else if (this.JobBasicForm.controls['school_state'].value != '') {
        this.JobBasicForm.get('school_postcode').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_suburb').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();

        this.JobBasicForm.get('school_state').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_state').updateValueAndValidity();

        this.JobBasicForm.get('school_postcode').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      }
    }
  }

  checkLengthState() {
    if (this.JobBasicForm.controls['school_state'].value != '') {
      if (this.JobBasicForm.controls['school_postcode'].value == '') {
        this.JobBasicForm.get('school_postcode').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_postcode').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      }

      if (this.JobBasicForm.controls['school_suburb'].value == '') {
        this.JobBasicForm.get('school_suburb').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_suburb').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();
      }
    } else if (this.JobBasicForm.controls['school_state'].value == '') {
      if (this.JobBasicForm.controls['school_suburb'].value != '') {
        this.JobBasicForm.get('school_state').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_state').updateValueAndValidity();
      } else if (this.JobBasicForm.controls['school_postcode'].value != '') {
        this.JobBasicForm.get('school_state').setValidators([Validators.required]); // 5.Set Required Validator
        this.JobBasicForm.get('school_state').updateValueAndValidity();
      } else {
        this.JobBasicForm.get('school_suburb').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_suburb').updateValueAndValidity();

        this.JobBasicForm.get('school_state').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_state').updateValueAndValidity();

        this.JobBasicForm.get('school_postcode').clearValidators(); // 6. Clear All Validators
        this.JobBasicForm.get('school_postcode').updateValueAndValidity();
      }
    }
  }
  checkLengthWebsite() {
    if (this.jobSummaryForm.controls['apply_through'].value == 'EW') {
      this.jobSummaryForm.get('apply_through_site').setValidators([Validators.required]); // 5.Set Required Validator
      this.jobSummaryForm.get('apply_through_site').updateValueAndValidity();
    } else {
      this.jobSummaryForm.get('apply_through_site').clearValidators(); // 6. Clear All Validators
      this.jobSummaryForm.get('apply_through_site').updateValueAndValidity();
    }
  }

  jobSummary() {


    if (moment(this.bsValue, 'mm/dd/YYYY', true).isValid()) {
      this.selectedDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
      this.JobBasicForm.patchValue({
        job_close_date: this.selectedDate,
      });
    } else {
      this.JobBasicForm.patchValue({
        job_close_date: this.bsValue,
      });
    }

    this.submitted = true;


    if (this.JobBasicForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.showJobSummary = true;
      this.showBasicInfo = false
      this.showReview = false
      window.scroll(0, 0)
      setTimeout(() => this.callBackEditor(), 1000)
      if (this.jobSummaryForm.controls['apply_through'].value == 'EW') {
        this.externalLink = true
      } else {
        this.externalLink = false
      }
    }
  }

  callBackEditor(){
    const toolbarButtons = document.querySelectorAll('.angular-editor-button i');
    toolbarButtons.forEach((button: Element) => {
      console.log(button.classList, "button.classList")
      if (button.classList.contains('fa-bold')) {
        button.className = 'bi bi-type-bold'; // Add Font Awesome classes
        button.setAttribute('ngbTooltip', 'Bold Text'); // Add a tooltip
      }
      if (button.classList.contains('fa-italic')) {
        button.className = 'bi bi-type-italic'; // Add Font Awesome classes
      }
      if (button.classList.contains('fa-list-ul')) {
        button.className = 'bi bi-list-ul'; // Add Font Awesome classes
      }
      if (button.classList.contains('fa-list-ol')) {
        button.className = 'bi bi-list-ol'; // Add Font Awesome classes
      }   
      if (button.classList.contains('fa-link')) {
        button.className = 'bi bi-link-45deg'; // Add Font Awesome classes
      }  
    });
    this.ngxService.stop();
  }
  review() {
    this.showSubscription = false
    this.sSubmitted = true;
    if (this.jobSummaryForm.invalid) {
      return;
    }

    this.ngxService.start();
    this.jobSummaryReview = this.jobSummaryForm.controls['job_summary'].value
    this.jobTitleReview = this.JobBasicForm.controls['job_title'].value
    this.jobSubjectReview = this.JobBasicForm.controls['subject'].value
    if (this.JobBasicForm.controls['auto_job_location'].value != '') {
      this.jobLocationReview = this.JobBasicForm.controls['searchLocation'].value
    } else {
      this.jobLocationReview = this.JobBasicForm.controls['job_location'].value
    }
    this.jobRemote = this.JobBasicForm.controls['is_remote_job'].value
    this.jobBoost = this.is_boost
    this.jobCloseDate = moment(this.bsValue , 'DD/MM/YYYY').format('DD MMMM, YYYY')
    // if (this.action_type == 'draftEdit') {
    //   alert(this.jobCloseDate)
    // } else if (this.action_type == 'edit') {
    //   this.jobCloseDate = moment(this.bsValue).format('MM/DD/YYYY')
    // } else {
    //   this.jobCloseDate = moment(this.bsValue , 'HHmmss').format('DD MMMM, YYYY')
    // }

if(this.JobBasicForm.controls['school_level'].value != ''){
    let newArr: any[] = []
    this.filtersArray.school_level.forEach((x: any) => {
      this.JobBasicForm.controls['school_level'].value.forEach((y: any) => {
        if (x.value == y) {
          newArr.push(x.title)
        }
      })
    })
    this.jobLevelReview = newArr
  }


    this.filtersArray.job_type.forEach((x: any) => {
      if (x.value == this.JobBasicForm.controls['job_type'].value) {
        this.jobTypeReview = x.title
      }
    });
    this.showReview = true
    this.showBasicInfo = false
    this.showJobSummary = false
    window.scroll(0, 0)
    this.ngxService.stop();
  }

  hideJobInfo() {
    this.ngxService.start();
    this.showBasicInfo = true
    this.showJobSummary = false
    this.showReview = false
    window.scroll(0, 0)
    this.ngxService.stop();
  }
  hideJobAndBasicInfo() {


    this.ngxService.start();
    this.showJobSummary = true
    this.showBasicInfo = false
    this.showReview = false
    window.scroll(0, 0)

    this.ngxService.stop();
  }

  showLocationChange() {
    this.showLocation = !this.showLocation
  }

  showUpgradePopUp() {

  }
  redirectToPublicPage() {
    window.open(environment.apiUrl + "jobs", "_blank");
  }
  openPublicJobs(id: any) {
    window.open(environment.apiUrl + "jobs/view-job/" + id, "_blank");
  }

  ngAfterViewInit() {
    console.log("test")
    // Access the toolbar DOM and change the icons
    const toolbarButtons = document.querySelectorAll('.angular-editor-button i');
    toolbarButtons.forEach((button: Element) => {
      console.log(button.classList, "button.classList")
      if (button.classList.contains('fa-bold')) {
        button.className = 'bi bi-type-bold'; // Add Font Awesome classes
        button.setAttribute('ngbTooltip', 'Bold Text'); // Add a tooltip
      }
      if (button.classList.contains('fa-italic')) {
        button.className = 'bi bi-type-italic'; // Add Font Awesome classes
      }
      if (button.classList.contains('fa-list-ul')) {
        button.className = 'bi bi-list-ul'; // Add Font Awesome classes
      }
      if (button.classList.contains('fa-list-ol')) {
        button.className = 'bi bi-list-ol'; // Add Font Awesome classes
      }   
      if (button.classList.contains('fa-link')) {
        button.className = 'bi bi-link-45deg'; // Add Font Awesome classes
      }  
    });
  }
}
