import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-profile-settings',
  templateUrl: './edit-profile-settings.component.html',
  styleUrls: ['./edit-profile-settings.component.scss'],
  standalone: false
})
export class EditProfileSettingsComponent implements OnInit {
  editType?: string;
  editPageChanges: any;
  editData: any;
  editDataPageTwo: any;

  public updatePassword: any;
  public bookingNotiSettings: any;
  public defaultSearchSettings: any;
  public jobApllicantNotification: any;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.route.params.subscribe(params => {
      this.editType = params['editType'];
    });
    // alert( this.editType)
    this.formInit();

    this.bookingService.profilePageDetails(this.editPageChanges).subscribe({
      next: (res: any) => {
        this.editData = res?.data;
        this.editDataPageTwo;

        this.bookingNotiSettings = this.fb.group({
          action: ['booking_notification', [Validators.required]],
          summary_mail_every_morning: [this.editData?.add_user_info?.email_notifications.summary_mail_every_morning, [Validators.required]],
          confirmation_every_booking: [this.editData?.add_user_info?.email_notifications.confirmation_every_booking, [Validators.required]],
          confirmation_of_booking_made_by_staff: [this.editData?.add_user_info?.email_notifications.confirmation_of_booking_made_by_staff, [Validators.required]],
          booking_setting: [this.editData?.add_user_info?.booking_options.booking_setting, [Validators.required]],
          setting_blanket: [this.editData?.info?.default_SMS_type.blanket, [Validators.required]],
          form_manual: [this.editData?.add_user_info.default_booking_form.form_manual, [Validators.required]],
        });
        this.defaultSearchSettings = this.fb.group({
          action: ['default_search', [Validators.required]],
          teacher_request_summary: [this.editData?.info.teacher_request_summary, [Validators.required]],
          show_school: [this.editData?.info?.show_school, [Validators.required]],

          search_setting: [this.editData?.add_user_info?.default_search_settings?.search_setting, [Validators.required]],
          show_incomplete_profile: [this.editData?.info?.default_search_settings?.show_incomplete_profile, [Validators.required]],
          list_setting_star: [this.editData?.add_user_info?.default_search_settings?.list_setting_star, [Validators.required]],
        });
        this.jobApllicantNotification = this.fb.group({
          action: ['job_applicant_notification', [Validators.required]],
          enable_notification: [this.editData?.add_user_info.job_summary_settings?.job_summary, [Validators.required]],
          job_alert_notify: [this.editData?.add_user_info.job_summary_settings?.send_every, [Validators.required]],
        });
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    })
    this.ngxService.stop();

  }

  formInit() {
    this.editType
    if (this.editType == 'update-password') {
      this.updatePassword = this.fb.group({
        action: 'password_change',
        current_password: ['', [Validators.required]],
        new_password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]],
      });
    } else if (this.editType == 'booking-and-notification-settings') {
      this.bookingNotiSettings = this.fb.group({
        action: ['booking_notification', [Validators.required]],
        summary_mail_every_morning: ['', [Validators.required]],
        confirmation_every_booking: ['', [Validators.required]],
        confirmation_of_booking_made_by_staff: ['', [Validators.required]],
        booking_setting: ['', [Validators.required]],
        setting_blanket: ['', [Validators.required]],
        setting_priority: ['', [Validators.required]],
        booking_request: ['', [Validators.required]],
      });
    } else if (this.editType == 'default-search-settings') {
      this.defaultSearchSettings = this.fb.group({
        teacher_request_summary: ['', [Validators.required]],
        show_school: ['', [Validators.required]],
        search_setting: ['', [Validators.required]],
        show_incomplete_profile: ['', [Validators.required]],
        list_setting_star: ['', [Validators.required]],
      });
    } else if (this.editType == 'manage-job-applicant-notifications') {
      this.jobApllicantNotification = this.fb.group({
        enable_notification: ['', [Validators.required]],
        job_alert_notify: ['', [Validators.required]],
      });
    }
  }

  getFormControlValues() {
    if (this.editType == 'update-password') {
      return this.updatePassword.value;
    } else if (this.editType == 'booking-and-notification-settings') {
      return this.bookingNotiSettings.value;
    } else if (this.editType == 'default-search-settings') {
      return this.defaultSearchSettings.value;
    } else if (this.editType == 'manage-job-applicant-notifications') {
      return this.jobApllicantNotification.value;
    }
  }
  SubmitForm(formFrom: any) {
    this.ngxService.start();
    this.bookingService.profileSaveChanges(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.modalService.hide()
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          // this.modalService.hide()
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  setJobAlert() {
    this.jobApllicantNotification.controls['enable_notification'].value == 0 ? this.jobApllicantNotification.patchValue({
      job_alert_notify: '',
    }) : this.jobApllicantNotification.patchValue({
      job_alert_notify: 'weekly',
    });
  }

}
